import { ShapeColor } from "@biggeo/bg-server-lib/datascape-ai";
import {
    Box,
    CellItem,
    IconButton,
    OverflowingTypography,
    cellItemClasses,
} from "@biggeo/bg-ui/lab";
import {
    DeleteOutline,
    DragIndicatorOutline,
    MoreVertOutline,
} from "@biggeo/bg-ui/lab/icons";
import { useState } from "react";
import { ShapeIcon } from "../common/components/ShapeIcon";

export type DrawShapeLayerProps<T> = {
    readonly layerId: T;
    readonly name: string;
    readonly selected?: boolean;
    readonly shapeIcon: JSX.Element;
    readonly color: ShapeColor;
    readonly borderColor: ShapeColor;
    readonly onSelect?: (id: T) => void;
    readonly onDelete: (id: T) => void;
    readonly onMore: (id: T) => void;
    readonly onHover?: (isHovered: boolean) => void;
};

export const DrawShapeLayer = <T,>({
    layerId,
    name,
    shapeIcon,
    onDelete,
    onMore,
    onSelect,
    color,
    borderColor,
    selected = false,
    onHover,
}: DrawShapeLayerProps<T>) => {
    const [hover, setHover] = useState(false);

    return (
        <Box
            onMouseEnter={() => {
                setHover(true);
                onHover?.(true);
            }}
            onMouseLeave={() => {
                setHover(false);
                onHover?.(false);
            }}
            onClick={(e) => {
                e.preventDefault();
                onSelect?.(layerId);
            }}
            sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: (theme) => theme.radius.default,
            }}
        >
            {hover && (
                <IconButton
                    as="div"
                    density="none"
                    variant="minimal"
                    sx={{
                        borderRadius: (theme) => theme.radius.full,
                    }}
                >
                    <DragIndicatorOutline size="sm" />
                </IconButton>
            )}
            <CellItem
                data-handler-id={layerId}
                density="none"
                disableHoverEffect
                disableActiveEffect
                disableFocusEffect
                startNode={
                    <Box
                        sx={{
                            display: "flex",
                            gap: 0.5,
                            alignItems: "center",
                        }}
                    >
                        {shapeIcon}
                        <ShapeIcon oval fill={color} stroke={borderColor} />
                    </Box>
                }
                endNode={
                    <Box
                        sx={{
                            display: "flex",
                            gap: 0.5,
                            alignItems: "center",
                        }}
                    >
                        {hover && (
                            <IconButton
                                density="none"
                                variant="minimal"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onDelete(layerId);
                                }}
                                sx={{
                                    borderRadius: (theme) => theme.radius.full,
                                }}
                            >
                                <DeleteOutline size="sm" />
                            </IconButton>
                        )}
                        <IconButton
                            density="none"
                            variant="minimal"
                            onClick={(e) => {
                                e.preventDefault();
                                onMore(layerId);
                            }}
                            sx={{
                                borderRadius: (theme) => theme.radius.full,
                            }}
                        >
                            <MoreVertOutline size="sm" />
                        </IconButton>
                    </Box>
                }
                title={
                    <OverflowingTypography variant="body3">
                        {name}
                    </OverflowingTypography>
                }
                sx={{
                    padding: 1,
                    ...(selected && {
                        [`&.${cellItemClasses.root}`]: {
                            backgroundColor: (theme) =>
                                theme.palette.stroke[100],
                        },
                    }),
                }}
            />
        </Box>
    );
};
