import {
    DatabaseType,
    DatasetStyleType,
    DatasetStyles,
    LevelSet,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import flatten from "lodash/flatten";
import isEqual from "lodash/isEqual";
import mapValues from "lodash/mapValues";
import { GeoJSONSource } from "mapbox-gl";
import {
    getHeatmapFormattedValue,
    getLayerHeatmapStyle,
} from "../../utils/style-utils";
import { DEFAULT_SHAPE_COLOR } from "../hooks/style-hooks";
import {
    DatasetFillLayer,
    DatasetSources,
    DatasetSourcesData,
    getDatasetSources,
    isLayerPresent,
} from "./data-layers-utils";

export const PREVIEW_DATASET_LAYER_SUFFIX = "preview-dataset";

export const getPreviewDatasetLayers = (input: {
    prefix: string;
    suffix?: string;
    sources: Pick<DatasetSources, "levelSets">;
    styles?: DatasetStyles;
    data?: Partial<{
        type: DatabaseType;
    }>;
}): Record<
    DatasetStyleType.fill,
    { [DatasetFillLayer.levelSets]: mapboxgl.AnyLayer[] }
> => {
    const {
        prefix,
        suffix = PREVIEW_DATASET_LAYER_SUFFIX,
        sources,
        styles,
        data,
    } = input;

    const color = styles?.fill?.color || DEFAULT_SHAPE_COLOR;
    const opacity =
        data?.type === DatabaseType.polygon
            ? 0.3
            : styles?.fill?.opacity || 0.15;

    const heatmap = getHeatmapFormattedValue(
        styles?.dataAggregation?.heatmap || undefined
    );

    const { colorMap } = getLayerHeatmapStyle(heatmap, color);

    return {
        [DatasetStyleType.fill]: {
            [DatasetFillLayer.levelSets]: pipe(
                sources.levelSets,
                A.mapWithIndex((index, source) => ({
                    id: `${prefix}-levelset-${index}-${suffix}`,
                    type: "fill",
                    source,
                    paint: {
                        "fill-color": [
                            "match",
                            ["get", "idCadence"],
                            ...colorMap,
                            color,
                        ],
                        "fill-opacity": [
                            "match",
                            ["get", "line"],
                            "true",
                            0,
                            opacity,
                        ],
                        "fill-antialias": false,
                    },
                }))
            ),
        },
    };
};

export const handlePreviewDatasetLayers = (input: {
    action: "add" | "remove";
    map: mapboxgl.Map;
    isLoaded: boolean;
    prefix: string;
    suffix?: string;
    levelSets?: LevelSet[] | number[];
    sources?: Pick<DatasetSources, "levelSets">;
    sourcesData?: Pick<DatasetSourcesData, "levelSets">;
    styles?: DatasetStyles;
    data?: Partial<{
        type: DatabaseType;
    }>;
}) => {
    const {
        action,
        map,
        isLoaded,
        prefix,
        levelSets,
        sources: _sources,
        sourcesData,
        styles,
        data,
        suffix,
    } = input;

    if (isLoaded) {
        const sources =
            _sources ??
            getDatasetSources({
                prefix,
                map,
                isLoaded,
                levelSets: levelSets || [],
            });

        const layers = getPreviewDatasetLayers({
            prefix,
            styles,
            data,
            sources,
            suffix,
        });

        if (isEqual(action, "add")) {
            const isDatasetLayerPresent = (layerId: string): boolean =>
                isLayerPresent({
                    layerId,
                    map,
                    isLoaded,
                });

            if (sourcesData) {
                mapValues(sources, (source: string[]) => {
                    pipe(
                        source,
                        A.mapWithIndex((index, s) => {
                            const geojsonSource = map.getSource(
                                s
                            ) as GeoJSONSource;

                            if (geojsonSource) {
                                geojsonSource.setData({
                                    type: "FeatureCollection",
                                    features: sourcesData.levelSets[index],
                                });
                            } else {
                                map.addSource(s, {
                                    type: "geojson",
                                    data: {
                                        type: "FeatureCollection",
                                        features: sourcesData.levelSets[index],
                                    },
                                });
                            }
                        })
                    );
                });
            }

            mapValues(layers, (value) => {
                mapValues(value.levelSets, (v) => {
                    if (!isDatasetLayerPresent(v.id)) {
                        map.addLayer(v);
                    }
                });
            });

            // Show the following layers on top of the dataset's layers
            if (isDatasetLayerPresent("gl-map-shapes-fill.cold")) {
                map.moveLayer("gl-map-shapes-fill.cold");
            }

            if (isDatasetLayerPresent("gl-map-shapes-line.cold")) {
                map.moveLayer("gl-map-shapes-line.cold");
            }

            if (isDatasetLayerPresent("gl-map-shapes-fill.hot")) {
                map.moveLayer("gl-map-shapes-fill.hot");
            }

            if (isDatasetLayerPresent("gl-map-shapes-line.hot")) {
                map.moveLayer("gl-map-shapes-line.hot");
            }

            if (isDatasetLayerPresent("settlement-minor-label")) {
                map.moveLayer("settlement-minor-label");
            }

            if (isDatasetLayerPresent("settlement-major-label")) {
                map.moveLayer("settlement-major-label");
            }

            if (isDatasetLayerPresent("state-label")) {
                map.moveLayer("state-label");
            }

            if (isDatasetLayerPresent("country-label")) {
                map.moveLayer("country-label");
            }
        }

        if (isEqual(action, "remove")) {
            mapValues(sources, (source: string[]) => {
                pipe(
                    source,
                    A.map((s) => {
                        const geojsonSource = map.getSource(s) as GeoJSONSource;

                        if (geojsonSource) {
                            geojsonSource.setData({
                                type: "FeatureCollection",
                                features: [],
                            });
                        }
                    })
                );
            });

            mapValues(layers, (value) => {
                mapValues(value.levelSets, (v) => {
                    const levelSetLayer = map.getLayer(v.id);

                    if (levelSetLayer) {
                        map.removeLayer(v.id);
                    }
                });
            });
        }
    }
};

export const getPreviewDatasetLayerIds = (input: {
    prefix?: string;
    suffix?: string;
    map: mapboxgl.Map;
    isLoaded: boolean;
}): string[] => {
    const {
        prefix,
        map,
        suffix = PREVIEW_DATASET_LAYER_SUFFIX,
        isLoaded,
    } = input;

    if (!prefix) {
        const layers = map
            .getStyle()
            .layers.filter((layer) => layer.id.includes(suffix));

        return pipe(
            layers,
            A.map((l) => l.id)
        );
    }

    const { levelSets } = getDatasetSources({
        prefix,
        map,
        isLoaded,
        levelSets: [0, 30, 60, 90],
    });

    const layers = getPreviewDatasetLayers({
        prefix,
        sources: { levelSets },
    });

    return pipe(
        mapValues(layers, (value) => {
            return flatten([value.levelSets.map((l) => l.id)]);
        }),
        Object.values,
        flatten
    );
};

export const getPreviewDatasetLayerPrefixes = (input: {
    layerIds?: string[];
    map: mapboxgl.Map;
    isLoaded: boolean;
}): string[] => {
    const { map, isLoaded } = input;

    const layersIds =
        input.layerIds ?? getPreviewDatasetLayerIds({ map, isLoaded });

    return pipe(
        layersIds,
        A.map((id) => {
            const a = id.split("-");
            return `${a[0]}-${a[1]}`;
        })
    );
};
