import {
    FilterObject,
    GeometryId,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import uniq from "lodash/uniq";
import { useEffect } from "react";
import { MapFilterCriteriaStyle } from "../../filter-criteria/utils/utils";

import { bgLongToString } from "@biggeo/bg-utils";
import { point } from "@turf/turf";
import { MapContextDataset, MapContextFilter } from "../context";
import { setDatasetVisibility } from "../utils/data-layers-utils";
import { handleFilterLayers } from "../utils/filtered-data-layers-utils";
import { CustomShapeSource } from "./style-hooks";

export type FilteredDataHookProps = {
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly filters: MapContextFilter[];
    readonly multiFilters: FilterObject[];
    readonly recentResponse?: SubscriptionResponse;
    readonly style?: mapboxgl.Style;
    readonly isLoaded: boolean;
    readonly isFilterCriteriaOpen: boolean;
    readonly selectedDatasets: MapContextDataset[];
};

export const useFilteredData = ({
    map,
    filters,
    recentResponse,
    style,
    isLoaded,
    isFilterCriteriaOpen,
    multiFilters,
    selectedDatasets,
}: FilteredDataHookProps) => {
    const isFiltering = multiFilters.some((d) => d.filters.length > 0);

    const datasetsWithFilters = pipe(
        multiFilters,
        A.filter((m) => !isEmpty(m.filters)),
        A.map((m) => m.databaseId),
        A.concat(
            pipe(
                selectedDatasets,
                A.map((d) => d.dataSource.id)
            )
        ),
        uniq
    );

    const visibleFilters = pipe(
        filters,
        A.filter((f) => isEqual(f.visible, true) && !isEmpty(f.filterCriteria))
    );

    const selectedFilters = pipe(
        filters,
        A.filter((f) => isEqual(f.selected, true))
    );

    const setFiltersFromResponse = (input: {
        suffix: string;
        response: SubscriptionResponse;
        map: mapboxgl.Map;
        sources: {
            points: string;
            aggregate: string;
        };
        currentStyles?: Partial<MapFilterCriteriaStyle>;
        addedStyles?: Partial<MapFilterCriteriaStyle>;
        isLoaded: boolean;
        hierarchy?: Partial<{
            previousFilterId: string;
            nextFilterId: string;
        }>;
    }) => {
        const {
            suffix,
            response,
            map,
            sources,
            addedStyles,
            currentStyles,
            isLoaded,
            hierarchy,
        } = input;

        const data = JSON.parse(response.data);
        const datasetId = response.databaseId;
        const dataset = selectedDatasets.find((d) =>
            isEqual(d.dataSource.id, datasetId)
        );

        const levelSets = response.geometry?.levelSets || [];

        datasetsWithFilters.map((dataSourceId) =>
            setDatasetVisibility({
                map,
                prefix: dataSourceId,
                levelSets,
                visibility: "none",
                isLoaded,
                points: {
                    shape: undefined,
                    customMarker: undefined,
                },
            })
        );

        if (isNil(data.filtered) && isNil(data.filteredPoints)) {
            setDatasetVisibility({
                map,
                prefix: datasetId,
                levelSets,
                visibility: "visible",
                isLoaded,
                points: {
                    shape: dataset?.mapTemplateDataset?.styles?.customMarker
                        ? undefined
                        : dataset?.mapTemplateDataset?.styles?.shape ||
                          undefined,
                    customMarker:
                        dataset?.mapTemplateDataset?.styles?.customMarker ||
                        undefined,
                },
            });
        } else {
            const filtered = data.filtered || [];
            const filteredPoints = data.filteredPoints || [];

            const filteredAvgPoints = filtered.map(
                (d: { LON: number; LAT: number; COUNT: number }) =>
                    point([d.LON, d.LAT], {
                        count: d.COUNT,
                        databaseId: response.databaseId,
                        label: "Filtered Aggregate",
                    })
            );

            const filteredAggPoints = filteredPoints.map(
                (d: { LON: number; LAT: number; COUNT: number }) =>
                    point([d.LON, d.LAT], {
                        count: d.COUNT,
                        databaseId: response.databaseId,
                        label: "Filtered Aggregate",
                    })
            );

            const filteredDataPoints = filteredPoints.map(
                (d: { LON: number; LAT: number; ID: GeometryId }) => {
                    const id = d.ID.uint64
                        ? bgLongToString(d.ID.uint64)
                        : d.ID.string;

                    return point([d.LON, d.LAT], {
                        id: JSON.stringify(d.ID),
                        databaseId: response.databaseId,
                        label: `Filtered Point ${id}`,
                        count: 1,
                    });
                }
            );

            handleFilterLayers({
                action: "add",
                map: { current: map },
                isLoaded,
                suffix,
                dataset,
                addedStyles,
                currentStyles,
                hierarchy,
                sources,
                sourcesData: {
                    aggregate: [...filteredAvgPoints, ...filteredAggPoints],
                    points: [...filteredDataPoints],
                },
            });
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            recentResponse?.data &&
            !isEmpty(filters) &&
            map.current &&
            isLoaded &&
            !isEmpty(selectedDatasets)
        ) {
            const dataset = selectedDatasets.find(
                (i) => i.dataSource?.id === recentResponse.databaseId
            );

            for (const [index, filter] of filters.entries()) {
                const suffix = filter.id;
                const isVisible =
                    isEqual(filter.visible, true) &&
                    !isEmpty(filter.filterCriteria);
                const isSelected = isEqual(filter.selected, true);

                if (isVisible || isSelected) {
                    const isFirst = isEqual(index, 0);
                    const isLast = isEqual(index, filters.length - 1);

                    setFiltersFromResponse({
                        suffix,
                        response: recentResponse,
                        map: map.current,
                        sources: {
                            points: `${CustomShapeSource.filtering}-points-${suffix}`,
                            aggregate: `${CustomShapeSource.filtering}-aggregate-${suffix}`,
                        },
                        addedStyles: filter.styles,
                        currentStyles: filter.styles,
                        isLoaded,
                        hierarchy: {
                            previousFilterId: isFirst
                                ? undefined
                                : filters[index - 1].id,
                            nextFilterId: isLast
                                ? undefined
                                : filters[index + 1].id,
                        },
                    });
                } else {
                    handleFilterLayers({
                        action: "remove",
                        map,
                        isLoaded,
                        suffix,
                        dataset,
                    });
                }
            }
        }
    }, [
        filters,
        map.current,
        isLoaded,
        recentResponse?.uniqueId,
        isFilterCriteriaOpen,
        multiFilters,
        visibleFilters,
        selectedDatasets,
    ]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (
            isEmpty(visibleFilters) &&
            map.current &&
            isLoaded &&
            isEqual(isFiltering, false)
        ) {
            for (const filter of filters) {
                handleFilterLayers({
                    map,
                    action: "remove",
                    isLoaded,
                    suffix: filter.id,
                });
            }
        }
    }, [
        recentResponse?.uniqueId,
        style,
        filters,
        isFilterCriteriaOpen,
        multiFilters,
        visibleFilters,
        isFiltering,
        isLoaded,
        selectedFilters,
        selectedDatasets,
    ]);
};
