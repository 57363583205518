import { InputPolygon, InputViewBox } from "@biggeo/bg-server-lib/datascape-ai";
import {
    FlexScrollArea,
    FlexScrollAreaContainer,
    MapLayoutHeader,
} from "@biggeo/bg-ui/lab";

import { MapContextDataset } from "../../mapbox/context";
import DatasetTableContainer from "./DatasetTableContainer";

interface IMainDatasetTableContainer {
    readonly dataset: MapContextDataset;
    readonly channelId: string;
    readonly onClose: () => void;
    readonly geospatialSelection:
        | {
              viewport: InputViewBox;
          }
        | {
              multipolygon: InputPolygon[];
          };
    readonly isRunningOnSF: boolean;
}

export const MainDatasetTableContainer = ({
    dataset,
    channelId,
    onClose,
    geospatialSelection,
    isRunningOnSF,
}: IMainDatasetTableContainer) => {
    return (
        <FlexScrollAreaContainer>
            <MapLayoutHeader
                vertical={false}
                header={"Table"}
                onClick={() => {
                    onClose();
                }}
            />
            <FlexScrollArea
                flexDirection="column"
                gap={4}
                width={"100%"}
                sx={{
                    padding: 4,
                }}
            >
                <DatasetTableContainer
                    data={dataset}
                    channelId={channelId}
                    geospatialSelection={geospatialSelection}
                    type="data"
                    isRunningOnSF={isRunningOnSF}
                />
            </FlexScrollArea>
        </FlexScrollAreaContainer>
    );
};
