import { ReqOptions } from "@biggeo/bg-server-lib/datascape-ai";
import {
    Box,
    BreadcrumbsButton,
    BreadcrumbsGroup,
    Divider,
    Grid,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import { ShapeIcon } from "../../common/components/ShapeIcon";
import { getLinearGradient } from "../../common/utils/gradient";
import { DatasetConfigurationType } from "../containers/DatasetConfigurationContainer";
import { MapContextDataset } from "../mapbox/context";
import { getDatasetShapeColor } from "../utils/style-utils";
import DatasetConfigDetailsView from "./DatasetConfigDetailsView";

export interface IDatasetConfigurationView {
    readonly onBack: () => void;
    readonly handleVisibility: (
        category: "levelSets" | "points",
        show: boolean
    ) => void;
    readonly params: Record<"points" | "polygons", DatasetConfigurationType> & {
        maxAdditionalRefines: { readonly value: number };
    };
    readonly handleConfiguration: (input: Partial<ReqOptions>) => void;
    readonly dataset: MapContextDataset;
}

const DatasetConfigurationView = ({
    onBack,
    handleVisibility,
    params,
    handleConfiguration,
    dataset,
}: IDatasetConfigurationView) => {
    const { fill, stroke, heatmap } = getDatasetShapeColor(dataset);

    const gradient = heatmap ? getLinearGradient(heatmap) : undefined;

    return (
        <Stack gap={4}>
            <BreadcrumbsGroup value="configuration">
                <BreadcrumbsButton
                    variant="minimal"
                    value="datasets"
                    onClick={onBack}
                >
                    Datasets
                </BreadcrumbsButton>
                <BreadcrumbsButton
                    variant="minimal"
                    value="configuration"
                    hideSeparator
                >
                    Configuration
                </BreadcrumbsButton>
            </BreadcrumbsGroup>
            <Stack
                gap={4}
                width="100%"
                height="100%"
                sx={{
                    border: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                    borderTopLeftRadius: (theme) => theme.radius.xs5,
                    borderTopRightRadius: (theme) => theme.radius.xs5,
                }}
            >
                <Box
                    sx={{
                        borderTopLeftRadius: "inherit",
                        borderTopRightRadius: "inherit",
                        height: 2,
                        width: "100%",
                        background: gradient
                            ? `${gradient}`
                            : `linear-gradient(90deg, #EBEFFE 0%, ${fill.color} 100%)`,
                    }}
                />
                <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap={2}
                    sx={{
                        paddingX: 2,
                    }}
                >
                    <ShapeIcon oval fill={fill} stroke={stroke} />
                    <Typography variant="body3" fontWeight="semibold">
                        {dataset.dataSource.label ||
                            dataset.dataSource.tableName}
                    </Typography>
                </Stack>
                <Grid
                    container
                    gap={2}
                    sx={{
                        paddingX: 2,
                    }}
                >
                    <Divider orientation="vertical" />
                    <Grid item xs minWidth={0} sx={{ paddingBottom: 2 }}>
                        <DatasetConfigDetailsView
                            params={params}
                            handleConfiguration={handleConfiguration}
                            handleVisibility={handleVisibility}
                            showLevelSets={dataset.configuration.showLevelSets}
                            showPoints={dataset.configuration.showPoints}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </Stack>
    );
};

export default DatasetConfigurationView;
