import { Pricing } from "@biggeo/bg-server-lib/datascape-ai";
import {
    CompanyAvatar,
    Grid,
    IconAvatar,
    Stack,
    Typography,
    splitCamelCase,
} from "@biggeo/bg-ui/lab";
import { CheckCircle } from "@biggeo/bg-ui/lab/icons";
import { formatNumberWithCommas } from "../../utils/utils";

export const MarketplaceDatasetCardHeader = ({
    datasetId,
    bgIndex,
    datasetName,
    datasetPrice,
    url,
    hover,
}: {
    readonly datasetId: string;
    readonly bgIndex: boolean;
    readonly datasetName: string;
    readonly datasetPrice: readonly Pricing[];
    readonly url?: string;
    readonly hover: boolean;
}) => {
    return (
        <Grid container gap={2} alignItems="center">
            <IconAvatar size="sm" color="surface" square>
                <CompanyAvatar
                    square
                    size="xs"
                    src={url}
                    alt={datasetId.toString()}
                />
            </IconAvatar>

            <Grid item xs minWidth={0}>
                <Typography
                    variant="body3"
                    fontWeight="semibold"
                    sx={{
                        color: (theme) => theme.palette.background.onMain,
                    }}
                    align="left"
                    truncate
                >
                    {datasetName}
                </Typography>
            </Grid>
            <Grid item>
                <Stack gap={1}>
                    {datasetPrice.map((price) => {
                        const formattedPrice = formatNumberWithCommas(
                            price.price
                        );
                        const formattedPriceOption = splitCamelCase(
                            price.priceOption
                        );
                        return (
                            <Stack
                                key={`${price.price} + ${price.priceOption}`}
                                flexDirection="row"
                                alignItems="center"
                                gap={1}
                            >
                                <Typography variant="body3" fontWeight="bold">
                                    ${`${formattedPrice}`}
                                </Typography>
                                <Typography
                                    variant="body4"
                                    textColor="disabled.onContainer"
                                >
                                    /{formattedPriceOption}
                                </Typography>
                            </Stack>
                        );
                    })}

                    {bgIndex && hover && (
                        <Grid container alignItems="center">
                            <Grid item>
                                {/* * @UiDiscrepancy */}
                                <Typography
                                    variant="body4"
                                    fontWeight="regular"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.disabled.onContainer,
                                    }}
                                >
                                    BigGeo indexed
                                </Typography>
                            </Grid>
                            <Grid item>
                                <CheckCircle size="xxs" color="success" />
                            </Grid>
                        </Grid>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
};
