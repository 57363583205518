import {
    InputDatasetStatisticsFilters,
    MarketplaceDataset,
    useDownloadStatisticsParquetMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Button,
    CompanyAvatar,
    Divider,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Hidden,
    Image,
    MapLayoutHeader,
    OverflowingTypography,
    Stack,
} from "@biggeo/bg-ui/lab";
import { MapOutline } from "@biggeo/bg-ui/lab/icons";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import React from "react";
import { MapContextPreviewDataset, useMap } from "../../map/mapbox/context";
import { AreaQualityTabs } from "../components/AreaQualityTabs";
import MarketplaceDatasetPreviewInfo from "./MarketplaceDatasetPreviewInfo";

interface IMarketplaceDatasetPreview {
    readonly marketplaceDataset: MarketplaceDataset;
    readonly onClickPreviewDataset: (marketplaceDatasetId: string) => void;
    readonly onClose: () => void;
    readonly isPreviewBtnDisabled: boolean;
    readonly filters: InputDatasetStatisticsFilters;
}

const MarketplaceDatasetPreview = ({
    marketplaceDataset,
    onClickPreviewDataset,
    onClose,
    isPreviewBtnDisabled,
    filters,
}: IMarketplaceDatasetPreview) => {
    const { dispatch } = useMap();
    const { executeMutation: downloadStatistics } =
        useDownloadStatisticsParquetMutation();

    const setPreviewDataset = (values?: MapContextPreviewDataset) => {
        dispatch?.({
            type: "SET_PREVIEW_DATASET",
            values,
        });
    };

    const handleClose = () => {
        onClose();
        setPreviewDataset(undefined);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        downloadStatistics({
            variables: {
                input: {
                    marketplaceDatasetId: marketplaceDataset.id,
                    path: marketplaceDataset.path,
                    collectionName: marketplaceDataset.collectionName,
                    tableName: marketplaceDataset.tableName,
                },
            },
        });
    }, [marketplaceDataset.id]);

    return (
        <Stack height={"100%"}>
            <Hidden breakpoint="cmd" variant="up">
                <MapLayoutHeader
                    vertical
                    header="Dataset information"
                    onClick={handleClose}
                />
            </Hidden>
            <Hidden breakpoint="cmd" variant="down">
                <MapLayoutHeader
                    inverted
                    header="Dataset information"
                    onClick={handleClose}
                />
            </Hidden>
            <Divider orientation="horizontal" color={100} />
            <FlexScrollAreaContainer>
                <FlexScrollArea
                    gap={4}
                    flexDirection="column"
                    sx={{ padding: 4 }}
                >
                    <Image
                        src={
                            "https://biggeo.blob.core.windows.net/media/Basemap-image.png"
                        }
                        sx={{
                            width: "100%",
                            objectFit: "contain",
                            objectPosition: "top left",
                        }}
                    />
                    <Button
                        variant="filled"
                        color="background"
                        startNode={<MapOutline size="xs" />}
                        sx={{
                            position: "absolute",
                            right: "26px",
                            top: "72px",
                            borderColor: (theme) => theme.palette.outline,
                            border: "1px solid",
                            borderRadius: (theme) => theme.radius.default,
                            ...(isPreviewBtnDisabled && {
                                color: (theme) => theme.palette.disabled.main,
                            }),
                        }}
                        readonly={isPreviewBtnDisabled}
                        onClick={() => {
                            if (isEqual(isPreviewBtnDisabled, false)) {
                                onClickPreviewDataset(marketplaceDataset.id);
                            }
                        }}
                    >
                        Preview
                    </Button>
                    <Stack flexDirection="row" alignItems="center" gap={2}>
                        <CompanyAvatar
                            square
                            src={marketplaceDataset.img || undefined}
                            alt={marketplaceDataset.name}
                        />
                        <Stack spacing={0.5}>
                            <OverflowingTypography
                                variant="body3"
                                fontWeight="bold"
                            >
                                {marketplaceDataset.name}
                            </OverflowingTypography>
                            <OverflowingTypography
                                variant="body4"
                                textColor="disabled.onContainer"
                            >
                                By Dataplor
                            </OverflowingTypography>
                        </Stack>
                    </Stack>
                    <Divider color={100} />
                    <AreaQualityTabs
                        tableName={marketplaceDataset.tableName}
                        columns={marketplaceDataset.columns || []}
                        filters={filters}
                        marketplaceDatasetId={marketplaceDataset.id}
                        type={marketplaceDataset.type}
                        setPreviewDataset={setPreviewDataset}
                    />
                    <Divider color={100} />
                    <MarketplaceDatasetPreviewInfo
                        price={marketplaceDataset.price}
                        size={marketplaceDataset.size}
                    />
                </FlexScrollArea>
            </FlexScrollAreaContainer>
        </Stack>
    );
};

export default React.memo(MarketplaceDatasetPreview);
