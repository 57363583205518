import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { parse } from "wkt";
import { MapContextPreviewDataset, useMap } from "../context";
import { getDatasetSources } from "../utils/data-layers-utils";
import {
    getPreviewDatasetLayerPrefixes,
    handlePreviewDatasetLayers,
} from "../utils/preview-data-layers-utils";

export type PreviewGeometry = { contour_sets: string }[];

export const usePreviewData = () => {
    const { map, isLoaded, preview } = useMap();

    const setDatasetPreviewFromResponse = (input: {
        dataset?: MapContextPreviewDataset;
        map: mapboxgl.Map;
        isLoaded: boolean;
    }) => {
        const { dataset, map, isLoaded } = input;

        if (dataset) {
            const { tableName, column, geometry, type } = dataset;

            if (!isEmpty(geometry)) {
                const prefix = `${tableName}-${column}`;
                const geojsonGeometry: GeoJSON.Feature<
                    GeoJSON.Geometry,
                    GeoJSON.GeoJsonProperties
                >[][] = pipe(
                    geometry,
                    A.map((wktGeometry) => parse(wktGeometry)),
                    A.map((geojsonGeometry: GeoJSON.Geometry) =>
                        geojsonGeometry.type === "MultiPolygon"
                            ? geojsonGeometry.coordinates.flatMap(
                                  (c: GeoJSON.Position[][]) => ({
                                      type: "Feature",
                                      properties: {
                                          id: prefix,
                                          tableName,
                                          column,
                                          label: "Preview Level Set",
                                      },
                                      geometry: {
                                          type: "Polygon",
                                          coordinates: c,
                                      },
                                  })
                              )
                            : [
                                  {
                                      type: "Feature",
                                      properties: {
                                          id: prefix,
                                          tableName,
                                          column,
                                          label: "Preview Level Set",
                                      },
                                      geometry: geojsonGeometry,
                                  },
                              ]
                    )
                );
                const { levelSets } = getDatasetSources({
                    prefix,
                    map,
                    isLoaded,
                    levelSets: [0, 30, 60, 90],
                });

                handlePreviewDatasetLayers({
                    action: "add",
                    map,
                    isLoaded,
                    prefix,
                    sources: { levelSets },
                    data: { type },
                    sourcesData: { levelSets: geojsonGeometry },
                });
            }
        }

        if (!dataset) {
            const prefixes = getPreviewDatasetLayerPrefixes({
                map,
                isLoaded,
            });

            prefixes.map((prefix) => {
                const { levelSets } = getDatasetSources({
                    prefix,
                    map,
                    isLoaded,
                    levelSets: [0, 30, 60, 90],
                });

                handlePreviewDatasetLayers({
                    action: "remove",
                    map,
                    isLoaded,
                    prefix,
                    sources: { levelSets },
                });
            });
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (map.current && isLoaded) {
            setDatasetPreviewFromResponse({
                map: map.current,
                isLoaded,
                dataset: preview,
            });
        }
    }, [preview, map.current, isLoaded]);
};
