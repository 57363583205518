import { useLazyQuery } from "@apollo/client";
import {
    FetchMapUpdatesDocument,
    type FilterObject,
    type InputPolygon,
    type InputRadius,
    type InputViewBox,
    MultiPolygon,
    type NewFilterObjectConnection,
    type SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import uuid from "react-uuid";
import { v4 as uuidV4 } from "uuid";
import { commonActions } from "../../common/redux/model.ts";
import { getMultiFilters } from "../mapbox/context/context-utils";
import { useMap } from "../mapbox/context/map.ts";
import { SavedPolygonType } from "../mapbox/hooks/saved-polygon-hooks";
import { removeCustomLayers } from "../utils/style-utils.ts";
import {
    useLatestMapChangesV2,
    useServerWebsocket,
} from "../utils/subscription";
import { FunctionType, SavedPolygonSource, updateArray } from "../utils/utils";

const channelId = uuid();

export type InputPolygonWithId = InputPolygon & {
    readonly id: string;
};

export type InputRadiusWithId = InputRadius & {
    readonly id: string;
};

export type PureDataStringHookProps = {
    readonly functionType: FunctionType;
    readonly setFunctionType: React.Dispatch<
        React.SetStateAction<FunctionType>
    >;
    readonly polygons: InputPolygonWithId[];
    readonly setPolygons: React.Dispatch<
        React.SetStateAction<InputPolygonWithId[]>
    >;
};

export type PureDataStringHookReturnType = {
    polygons: InputPolygonWithId[];
    handleMultiPolygons: ({
        polygon,
    }: {
        polygon: InputPolygonWithId | InputPolygonWithId[];
    }) => void;
    clearData: () => void;
    responses: Partial<Record<string, SubscriptionResponse | null>>;
    channelId: string;
    multiFilters: FilterObject[];
    handlePolygonsOnZoom: (p: InputPolygon[]) => void;
    handleViewportChange: ({
        viewport,
    }: {
        viewport: InputViewBox;
    }) => void;
    viewport: InputViewBox;
    recentResponse?: SubscriptionResponse;
    savedPolygons: SavedPolygonType;
    setSavedPolygons: (i: Partial<SavedPolygonType>) => void;
    functionType: FunctionType;
    setFunctionType: React.Dispatch<React.SetStateAction<FunctionType>>;
    deleteShape: (id: string) => void;
    clearShapes: () => void;
    handleSavedPolygons: (p: InputPolygonWithId[]) => void;
};

export const usePureDataString = (): PureDataStringHookReturnType => {
    const socket = useServerWebsocket();
    const dispatch = useDispatch();

    const [savedPolygons, setSavedPolygons] = useState<SavedPolygonType>({
        source: SavedPolygonSource.savedArea,
        polygons: [],
        isConflict: false,
    });

    const [responses, setResponses] = useState<
        Partial<Record<string, SubscriptionResponse | null>>
    >({});

    const [recentResponse, setRecentResponse] =
        useState<SubscriptionResponse>();

    const [viewport, setViewport] = useState<InputViewBox>({
        latBounds: {
            min: 44.41032116923924,
            max: 56.77085035366497,
        },
        lngBounds: {
            min: -125.46972656249946,
            max: -102.53027343749929,
        },
    });
    const [polygons, setPolygons] = useState<InputPolygonWithId[]>([]);

    const { dispatch: mapDispatch, draw, map, isLoaded } = useMap();

    const multiFilters = getMultiFilters();

    const [_loading, setLoading] = useState(false);

    const [functionType, setFunctionType] = useState<FunctionType>(
        FunctionType.viewport
    );

    const setFiltersLoading = (loading: boolean) => {
        mapDispatch?.({
            type: "SET_DATA_LOADING",
            values: { state: loading },
        });
    };

    const clearShapes = () => {
        setPolygons([]);
    };

    const clearData = () => {
        setResponses({});
    };

    useLatestMapChangesV2(channelId, (response) => {
        setResponses({ ...responses, [response.databaseId]: response });
        setRecentResponse(response);
    });

    const [execute, { loading: filteredDataLoading }] = useLazyQuery<
        { fetchMapUpdates: boolean },
        { input: NewFilterObjectConnection }
    >(FetchMapUpdatesDocument);

    const sendMultiPolygons = (
        polygons: (InputPolygonWithId | InputPolygon)[]
    ) => {
        pipe(
            multiFilters,
            A.map((item) => {
                dispatch(
                    commonActions.setSearchStartTime({
                        datasetId: item.databaseId,
                        startTime: Date.now(),
                    })
                );
            })
        );

        const multipolygon: MultiPolygon = {
            polygons: polygons.map((p) => ({
                inners: p.inners,
                outer: p.outer,
            })),
        };

        setLoading(true);
        socket.emit("updateViewPortV2", {
            dateTime: Date.now().toString(),
            channel: channelId,
            multiFilters,
            requestId: uuidV4(),
            isStreaming: false,
            multipolygon,
        });

        if (multiFilters.some((d) => d.filters.length > 0)) {
            setLoading(true);
            setFiltersLoading(true);

            execute({
                variables: {
                    input: {
                        multipolygon: multipolygon.polygons,
                        dateTime: Date.now().toString(),
                        channelId: channelId,
                        filters: multiFilters,
                    },
                },
                onCompleted: () => {
                    setFiltersLoading(false);
                },
                onError: () => {
                    setFiltersLoading(false);
                },
            });
        }
    };

    socket.on("message-back", () => {
        pipe(
            multiFilters,
            A.map((item) => {
                dispatch(
                    commonActions.setSearchEndTime({
                        datasetId: item.databaseId,
                        endTime: Date.now(),
                    })
                );
            })
        );

        setLoading(false);
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setFiltersLoading(filteredDataLoading);
    }, [filteredDataLoading]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        isEmpty(polygons)
            ? handleViewportChange({ viewport })
            : sendMultiPolygons(polygons);
    }, [polygons]);

    const handleMultiPolygons = ({
        polygon,
    }: {
        polygon: InputPolygonWithId | InputPolygonWithId[];
    }) => {
        setPolygons((prev) => updateArray(polygon, prev));
    };

    const deleteShape = (id: string) => {
        setPolygons((prev) => prev.filter((p) => p.id !== id));

        if (map.current && draw.current && isLoaded) {
            const feature = draw.current.get(id);
            draw.current.delete(id);

            if (feature) {
                mapDispatch?.({
                    type: "SET_PREVIOUS_STATE",
                    values: {
                        type: "draw.delete",
                        feature,
                    },
                });
                mapDispatch?.({
                    type: "SET_SELECTED_SHAPES",
                    values: feature,
                });
            }

            removeCustomLayers(map.current);
        }
    };

    const handleSavedPolygons = (p: InputPolygonWithId[]) => {
        setPolygons(p);
    };

    const handlePolygonsOnZoom = (p: InputPolygon[]) => {
        /* Shouldn't set the polygons state because it relies on it to calculate the
         * union polygon and intersections.
         */
        sendMultiPolygons(p);
    };

    const handleViewportChange = ({ viewport }: { viewport: InputViewBox }) => {
        if (!isEmpty(polygons)) return;
        setViewport(viewport);
        setLoading(true);
        pipe(
            multiFilters,
            A.map((item) => {
                dispatch(
                    commonActions.setSearchStartTime({
                        datasetId: item.databaseId,
                        startTime: Date.now(),
                    })
                );
            })
        );
        socket.emit("updateViewPortV2", {
            viewport,
            dateTime: Date.now().toString(),
            channel: channelId,
            multiFilters,
            requestId: uuidV4(),
            isStreaming: false,
        });
        if (multiFilters.some((d) => d.filters.length > 0)) {
            setFiltersLoading(true);
            execute({
                variables: {
                    input: {
                        viewport,
                        dateTime: Date.now().toString(),
                        channelId: channelId,
                        filters: multiFilters,
                    },
                },
                onCompleted: () => {
                    setFiltersLoading(false);
                },
                onError: () => {
                    setFiltersLoading(false);
                },
            });
        }
    };

    return {
        polygons,
        handleMultiPolygons,
        clearData,
        responses,
        channelId,
        multiFilters,
        handleViewportChange,
        viewport,
        recentResponse,
        savedPolygons,
        setSavedPolygons: (value) => {
            setSavedPolygons((p) => ({ ...p, ...value }));
        },
        setFunctionType,
        functionType,
        deleteShape,
        clearShapes,
        handleSavedPolygons,
        handlePolygonsOnZoom,
    };
};
