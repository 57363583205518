import bytes from "bytes";
import { BasicExportDataType } from "../components/DatasetCardList";
import { ExportDatasetCard } from "../components/ExportDatasetCard";

export const ExportDatasetCardContainer = ({
    data,
    onSelect,
    selectedIds,
    tableName,
}: {
    readonly data: BasicExportDataType;
    readonly tableName?: string;
    readonly onSelect: (i: string) => void;
    readonly selectedIds: readonly string[];
}) => {
    const progress = Math.round((data.progress / (data.size || 1)) * 100);

    const formatBytes = (inputBytes: number) => {
        return bytes(inputBytes, { unitSeparator: " " });
    };

    const convertedProgress = formatBytes(data.progress);
    const convertedSize = formatBytes(data.size || 0);
    return (
        <ExportDatasetCard
            dataId={data.id}
            dataName={data.name}
            dataSelectStatus={selectedIds.includes(data.id)}
            dataStatus={data.status}
            convertedProgress={convertedProgress || ""}
            convertedSize={convertedSize || ""}
            dataImage={data.image || ""}
            results={data.results}
            rows={data.rows}
            dataFrom={data.from}
            dataType={data.type}
            progress={progress}
            onSelect={onSelect}
            ddlCommand={data.ddlCommand}
            dmlCommand={data.dmlCommand}
            tableName={tableName}
        />
    );
};
