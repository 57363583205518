import {
    FilterCriteriaDatasetItem,
    InputViewBox,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    FlexScrollArea,
    FlexScrollAreaContainer,
    MapLayoutHeader,
} from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import { match } from "ts-pattern";
import { InputPolygonWithId } from "../../hooks/pure-data-string-hook";
import { MapContextFilter, useMap } from "../../mapbox/context";
import { FunctionType, pickGeospatialSelection } from "../../utils/utils";
import { MapFilterCriteriaDataset } from "../utils/utils";
import DatasetTableContainer from "./DatasetTableContainer";

interface IMapFilterCriteriaDatasets {
    readonly filters: MapContextFilter[];
    readonly handleClose: (id: string) => void;
    readonly channelId: string;
    readonly functionType: FunctionType;
    readonly viewport: InputViewBox;
    readonly polygons: InputPolygonWithId[];
    readonly isRunningOnSF: boolean;
}

const MapFilterCriteriaDatasets = ({
    filters,
    handleClose,
    channelId,
    functionType,
    viewport,
    polygons,
    isRunningOnSF,
}: IMapFilterCriteriaDatasets) => {
    const { dispatch } = useMap();
    const filter = pipe(
        filters,
        A.findFirst((f) => isEqual(f.viewed, true)),
        O.fold(
            () => undefined,
            (f) => f
        )
    );
    const geospatialSelection = pickGeospatialSelection(functionType, {
        viewport,
        multipolygon: polygons.map((c) => ({
            inners: c.inners,
            outer: c.outer,
        })),
    });

    const updateFilter = (i: {
        index: number;
        items: MapFilterCriteriaDataset;
    }) => {
        if (filter) {
            dispatch?.({
                type: "UPDATE_FILTER",
                values: {
                    id: filter.id,
                    filterCriteria: pipe(
                        filter.filterCriteria,
                        A.mapWithIndex((idx, f) =>
                            isEqual(idx, i.index) ? i.items : f
                        )
                    ),
                },
            });
        }
    };

    return (
        <FlexScrollAreaContainer>
            <MapLayoutHeader
                vertical={false}
                header={"Table"}
                onClick={() => {
                    if (filter) {
                        handleClose(filter.id);
                    }
                }}
            />
            {filter && (
                <FlexScrollArea
                    flexDirection="column"
                    gap={4}
                    width={"100%"}
                    sx={{
                        padding: 4,
                    }}
                >
                    {pipe(
                        filter.filterCriteria,
                        A.mapWithIndex((index, filterCriteria) => (
                            <DatasetTableContainer
                                key={index}
                                data={filterCriteria}
                                channelId={channelId}
                                geospatialSelection={geospatialSelection}
                                type="filteredData"
                                isRunningOnSF={isRunningOnSF}
                                filterId={filter.id}
                                updateFilter={(filters) =>
                                    updateFilter({
                                        index,
                                        items: { ...filterCriteria, filters },
                                    })
                                }
                            />
                        ))
                    )}
                </FlexScrollArea>
            )}
        </FlexScrollAreaContainer>
    );
};

export default MapFilterCriteriaDatasets;
