import { Pricing } from "@biggeo/bg-server-lib/datascape-ai";
import { Button, Grid, Stack, Typography } from "@biggeo/bg-ui/lab";
import { useState } from "react";
import { formatNumberWithCommas } from "../../utils/utils";
import { MarketplaceDatasetCardHeader } from "../components/MarketplaceDatasetCardHeader";

export type MarketplaceDatasetCardProps = {
    readonly price: readonly Pricing[];
    readonly name: string;
    readonly onClickPreviewDataset: (marketplaceDatasetId: string) => void;
    readonly marketplaceDatasetId: string;
    readonly image?: string;
    readonly size: number;
    readonly progress?: number;
    readonly processing?: boolean;
    readonly isDisabled: boolean;
    readonly previewInfo: (marketplaceDatasetId: string) => void;
    readonly readonly?: boolean;
};

const MarketplaceDatasetPreviewCard = ({
    price,
    name,
    onClickPreviewDataset,
    image,
    size,
    marketplaceDatasetId,
    isDisabled,
    previewInfo,
    readonly = false,
}: MarketplaceDatasetCardProps) => {
    const [hover, setHover] = useState(false);

    return (
        <Stack
            gap={3}
            onMouseEnter={(e) => {
                e.stopPropagation();
                setHover(true);
            }}
            onMouseLeave={(e) => {
                e.stopPropagation();
                setHover(false);
            }}
            sx={{
                background: (theme) => theme.palette.background.main,
                width: "100%",
                padding: (theme) => theme.spacing(2),
                border: (theme) => `1px solid ${theme.palette.stroke[100]}`,
                borderRadius: (theme) => theme.radius.xs3,
                cursor: readonly ? undefined : "pointer",
                boxShadow: hover ? (theme) => theme.shadows.allAround : "none",
                justifyContent: "space-between",
            }}
            onClick={() => previewInfo(marketplaceDatasetId)}
        >
            <MarketplaceDatasetCardHeader
                datasetId={marketplaceDatasetId}
                bgIndex={true}
                hover={hover}
                url={image}
                datasetName={name}
                datasetPrice={price}
            />

            <Grid container justifyContent="space-between" gap={4}>
                <Grid container xs minWidth={0} gap={1} alignItems="end">
                    <Typography
                        variant="body3"
                        fontWeight="regular"
                        color="disabled"
                        colorSet="container"
                        invertColors
                        truncate
                    >
                        Size:
                    </Typography>
                    <Typography
                        variant="body3"
                        fontWeight="semibold"
                        color="background"
                        colorSet="main"
                        invertColors
                        truncate
                    >
                        {`${formatNumberWithCommas(size)} rows`}
                    </Typography>
                </Grid>

                <Grid item>
                    <Button
                        density="dense"
                        variant="outlined"
                        color="surface"
                        disabled={isDisabled}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClickPreviewDataset(marketplaceDatasetId);
                        }}
                        sx={{
                            alignSelf: "end",
                            cursor: "pointer",
                        }}
                    >
                        Preview
                    </Button>
                </Grid>
            </Grid>
        </Stack>
    );
};

export default MarketplaceDatasetPreviewCard;
