import {
    AccessKeyStatus,
    DataSource,
    EntitlementDataSourceDataProvider,
    useFetchAllEntitlementDatasetsExtendsQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { IFetchInput } from "@biggeo/bg-ui/lab";
import { Success, match } from "@vividtheory/remotedata";
import { useEffect } from "react";

/**
 * UI/UX Handoff (Delete this block after completion)
 * Internal Requirements (search the following TODOs to see the internal requirements for completion):
 * - TODO: Active Datasets Fetch
 * - TODO: Implement callbacks and input
 */
export type ActiveDataset = {
    id: string;
    key: string;
    status: AccessKeyStatus;
    dataset: DataSource;
    velocity: boolean;
    progress?: number;
    provider: { name: string; img: string };
};

export type ActiveDatasetsProviderProps = {
    readonly children: React.FC<{
        readonly activeDatasets: readonly ActiveDataset[];
        readonly total: number;
        readonly loading: boolean;
    }>;
    readonly onRefetch?: {
        isRefetch: boolean;
        setIsRefetch: (v: boolean) => void;
    };
    readonly dataGridFetchInputProps: IFetchInput;
};

const convert = (input: EntitlementDataSourceDataProvider): ActiveDataset => {
    const { dataProvider, dataSource, entitlement } = input;
    return {
        id: dataSource.id,
        key: entitlement.apiKey || "",
        status: entitlement.status,
        velocity: false,
        dataset: dataSource,
        progress: dataSource.progress || undefined,
        provider: { name: dataProvider.name, img: dataProvider.img || "" },
    };
};

export const ActiveDatasetsProvider = ({
    children,
    onRefetch,
    dataGridFetchInputProps,
}: ActiveDatasetsProviderProps) => {
    const {
        remote,
        setRemote,
        queryReturn: { refetch },
    } = useFetchAllEntitlementDatasetsExtendsQuery({
        variables: { input: dataGridFetchInputProps },
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (onRefetch?.isRefetch) {
            refetch().then((data) => setRemote(new Success(data.data)));
            onRefetch.setIsRefetch(false);
        }
    }, [onRefetch?.isRefetch]);

    return match(remote, {
        _: () =>
            children({
                activeDatasets: [],
                total: 0,
                loading: true,
            }),
        Success: (data) => {
            const result = data.fetchAllEntitlementDatasetsExtends;

            return children({
                activeDatasets: result.dataSourceExtends.map(convert),
                total: result.total,
                loading: false,
            });
        },
        Failure: () =>
            children({
                activeDatasets: [],
                total: 0,
                loading: false,
            }),
    });
};
