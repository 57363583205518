import {
    DataSource,
    InputSavedViewDataset,
    MapStyleType,
    SavedView,
    SavedViewDataset,
    SavedViewFilters,
    useCreateSavedViewMutation,
    useUpdateSavedViewMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Box,
    Button,
    MultilineTextField,
    Severity,
    Stack,
    SubmittingButton,
    TextField,
    Typography,
} from "@biggeo/bg-ui/lab";
import { Formik, FormikHelpers } from "formik";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/function";
import html2canvas from "html2canvas";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import isString from "lodash/isString";
import uniqWith from "lodash/uniqWith";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Zod from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { Routes } from "../../../navigation/redux/model.ts";
import { toasterActions } from "../../../toaster/containers/redux/model.ts";
import { InputPolygonWithId } from "../../hooks/pure-data-string-hook.ts";
import { ISaveView } from "../views/SaveView.tsx";

export const getDataSource = (input: SavedViewDataset): DataSource => {
    return {
        id: input.id,
        collectionName: input.collectionName,
        color: input.color,
        compute: input.compute,
        description: input.description,
        geographyColumn: input.geographyColumn,
        icon: input.icon,
        isConnected: input.isConnected,
        isMipmapped: input.isMipmapped,
        label: input.label,
        mapCost: input.mapCost,
        opacity: input.opacity,
        progress: input.progress,
        size: input.size,
        src: input.src,
        tableId: input.tableId,
        tableName: input.tableName,
        type: input.type,
        sfAlias: input.sfAlias,
        isLoading: input.isLoading,
        isPreview: input.isPreview,
        fkMarketplaceDatasetId: input.fkMarketplaceDatasetId,
        createdAt: input.createdAt,
        deletedAt: input.deletedAt,
    };
};

interface ISaveViewForm
    extends Pick<
        ISaveView,
        "bounds" | "viewport" | "setOpenSaveViewPopper" | "datasets"
    > {
    readonly mapTemplateId?: string;
    readonly savedView?: Partial<SavedView> | null;
    readonly polygons: InputPolygonWithId[];
    readonly filters: SavedViewFilters[];
    readonly style?: MapStyleType;
}

const SaveViewForm = ({
    bounds,
    viewport,
    setOpenSaveViewPopper,
    polygons,
    mapTemplateId,
    savedView,
    filters,
    style,
    datasets,
}: ISaveViewForm) => {
    const dispatch = useDispatch();
    const {
        executeMutation: createSavedView,
        mutationReturn: [_c, { loading: cloading }],
    } = useCreateSavedViewMutation();

    const {
        executeMutation: updateSavedView,
        mutationReturn: [_u, { loading: uloading }],
    } = useUpdateSavedViewMutation();

    const [loading, setLoading] = useState<boolean>(cloading || uloading);

    const openNewTab = (url: string) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const getScreenshot = async (htmlid: string) => {
        const element = document.getElementById(htmlid);
        if (element) {
            setLoading(true);

            return await html2canvas(element, {
                useCORS: true,
                scale: 0.25,
                logging: true,
            })
                .then((canvas) => {
                    setLoading(false);
                    return canvas.toDataURL("image/png");
                })
                .catch(() => {
                    setLoading(false);
                    dispatch(
                        toasterActions.openToast({
                            open: true,
                            severity: Severity.error,
                            title: "Error saving Saved View screenshot.",
                            autoHideDuration: 5000,
                        })
                    );
                });
        }
    };

    const selectedDatasets = pipe(
        datasets,
        A.filter((d) => isEqual(d.isSelected, true)),
        A.map((d) => d.dataSource.id)
    );

    const savedViewDatasets: InputSavedViewDataset[] = useMemo(
        () =>
            pipe(
                datasets,
                A.filter(
                    (d) =>
                        !!d.dataSource.progress &&
                        d.dataSource.progress >= 100 &&
                        isEqual(d.dataSource.isPreview, false)
                ),
                A.map((d) => {
                    return {
                        id: d.dataSource.id,
                        progress: d.dataSource.progress,
                        size: d.dataSource.size,
                        isMipmapped: d.dataSource.isMipmapped,
                        tableName: d.dataSource.tableName,
                        collectionName: d.dataSource.collectionName,
                        geographyColumn: d.dataSource.geographyColumn,
                        isConnected: d.dataSource.isConnected,
                        mapCost: d.dataSource.mapCost,
                        description: d.dataSource.description,
                        label: d.dataSource.label,
                        color: d.dataSource.color,
                        heatmapColor: d.dataSource.heatmapColor,
                        compute: d.dataSource.compute,
                        icon: d.dataSource.icon,
                        sfAlias: d.dataSource.sfAlias,
                        isLoading: d.dataSource.isLoading,
                        tableId: d.dataSource.tableId,
                        opacity: d.dataSource.opacity,
                        type: d.dataSource.type,
                        src: d.dataSource.src,
                        isPreview: d.dataSource.isPreview,
                        createdAt: d.dataSource.createdAt,
                        deletedAt: d.dataSource.deletedAt,
                        selected: selectedDatasets.includes(d.dataSource.id),
                        mapTemplateDatasetId: d.mapTemplateDataset?.id,
                        styles: d.mapTemplateDataset
                            ? d.mapTemplateDataset.styles
                            : undefined,
                        configuration: {
                            showLevelSets: d.configuration.showLevelSets,
                            showPoints: d.configuration.showPoints,
                            options: d.configuration.options,
                        },
                    };
                })
            ),
        [datasets, selectedDatasets]
    );

    const savedViewport = bounds
        ? {
              latBounds: {
                  min: bounds._sw.lat,
                  max: bounds._ne.lat,
              },
              lngBounds: {
                  min: bounds._sw.lng,
                  max: bounds._ne.lng,
              },
          }
        : viewport;

    const polygonsToSave = pipe(
        uniqWith(polygons, (x, y) => isEqual(x.outer.points, y.outer.points)),
        A.map((p) => ({
            inners: p.inners,
            outer: p.outer,
            properties: p.properties,
        }))
    );

    const handleSaveView = async (
        values: Partial<SavedView>,
        actions: FormikHelpers<Partial<SavedView>>
    ) => {
        const screenshot = await getScreenshot("map");

        if (isString(screenshot)) {
            if (values.id) {
                updateSavedView({
                    variables: {
                        input: {
                            id: values.id,
                            name: values.name,
                            description: values.description,
                            viewport: savedViewport,
                            filters,
                            image: screenshot,
                            datasets: savedViewDatasets,
                            inputSavedArea: {
                                polygons: polygonsToSave,
                                name: values.name,
                                isEnabled: true,
                                isSavedViewArea: true,
                                fkMapTemplateId: Number(mapTemplateId),
                                id: isNil(savedView?.fkSavedAreaId)
                                    ? undefined
                                    : Number(savedView?.fkSavedAreaId),
                            },
                            mapSetting: {
                                mapStyle: style,
                            },
                        },
                    },
                    onCompleted: (_data) => {
                        dispatch(
                            toasterActions.openToast({
                                open: true,
                                title: "Saved view updated successfully",
                                autoHideDuration: 5000,
                            })
                        );
                        setOpenSaveViewPopper?.(false);
                    },
                    onError: (e) => {
                        dispatch(
                            toasterActions.openToast({
                                open: true,
                                severity: Severity.error,
                                title: e.message || "Error updating view",
                                autoHideDuration: 5000,
                            })
                        );
                    },
                });
            } else {
                createSavedView({
                    variables: {
                        input: {
                            name: values.name || "",
                            description: values.description,
                            viewport: savedViewport,
                            fkMapTemplateId: Number(mapTemplateId),
                            inputSavedArea:
                                polygonsToSave.length > 0
                                    ? {
                                          polygons: polygonsToSave,
                                          name: values.name,
                                          isEnabled: true,
                                          isSavedViewArea: true,
                                          fkMapTemplateId:
                                              Number(mapTemplateId),
                                          mapUse: true,
                                      }
                                    : undefined,
                            datasets: savedViewDatasets,
                            filters,
                            image: screenshot,
                            mapSetting: {
                                mapStyle: style,
                            },
                        },
                    },
                    onError: (err) =>
                        dispatch(
                            toasterActions.openToast({
                                open: true,
                                title: err.message,
                                autoHideDuration: 5000,
                                severity: Severity.error,
                            })
                        ),
                    onCompleted: (data) => {
                        actions.resetForm();
                        setOpenSaveViewPopper?.(false);

                        dispatch(
                            toasterActions.openToast({
                                open: true,
                                title: "New view saved successfully.",
                                autoHideDuration: 6000,
                                additionalAction: {
                                    ctaText: "Open",
                                    onClick: () =>
                                        openNewTab(
                                            `${Routes.mapView}/${mapTemplateId}/map?savedViewId=${data.createSavedView.id}`
                                        ),
                                },
                            })
                        );
                    },
                });
            }
        }
    };

    const initialValues = {
        id: savedView?.id,
        name: savedView?.name || "",
        description: savedView?.description || "",
    };
    return (
        <Formik<Partial<SavedView>>
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                handleSaveView(values, actions);
            }}
            validationSchema={toFormikValidationSchema(
                Zod.object({
                    id: Zod.number().nullable().optional(),
                    name: Zod.string(),
                    description: Zod.string().optional(),
                })
            )}
            validateOnMount
        >
            {({ values, setValues, resetForm, handleSubmit, isValid }) => {
                const onChange = (i: Partial<SavedView>) => {
                    setValues((p) => ({ ...p, ...i }));
                };
                return (
                    <Stack
                        gap={2}
                        sx={{
                            padding: 4,
                            backgroundColor: (theme) =>
                                theme.palette.background.main,
                            width: 80,
                            borderRadius: (theme) => theme.spacing(1),
                        }}
                    >
                        <Stack>
                            <Typography variant="body2" fontWeight="bold">
                                {`Save ${savedView?.id ? "Changes" : "View"}`}
                            </Typography>
                            <Typography
                                variant="body3"
                                sx={{
                                    color: (theme) =>
                                        theme.palette.disabled.onContainer,
                                }}
                            >
                                {`${savedView?.id ? "Update" : "Enter"} details
                            below`}
                            </Typography>
                        </Stack>
                        <TextField
                            label="Name"
                            fullWidth
                            required
                            value={values.name || ""}
                            onChange={(_, v) =>
                                onChange({
                                    name: v === "" ? undefined : v,
                                })
                            }
                        />
                        <MultilineTextField
                            label="Description"
                            subLabel="(Optional)"
                            fullWidth
                            value={values.description || ""}
                            onChange={(_, v) =>
                                onChange({
                                    description: v === "" ? undefined : v,
                                })
                            }
                        />
                        <Box
                            sx={{
                                display: "flex",
                                gap: 2,
                                alignSelf: "end",
                            }}
                        >
                            <Button
                                density="dense"
                                variant="outlined"
                                onClick={() => {
                                    setOpenSaveViewPopper?.(false);
                                    resetForm();
                                }}
                                disabled={loading}
                                sx={{
                                    display: loading ? "none" : "flex",
                                }}
                            >
                                Cancel
                            </Button>
                            <SubmittingButton
                                loading={loading || cloading || uloading}
                                disabled={!isValid}
                                density="dense"
                                type="saving"
                                onClick={() => handleSubmit()}
                            >
                                {`Save ${savedView?.id ? "Changes" : "View"}`}
                            </SubmittingButton>
                        </Box>
                    </Stack>
                );
            }}
        </Formik>
    );
};

export default SaveViewForm;
