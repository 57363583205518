import { SubscriptionResponse } from "@biggeo/bg-server-lib/datascape-ai";
import { themeSpacingToNumber, useTheme } from "@biggeo/bg-ui";
import { ComparisonLinearLineChart } from "@biggeo/bg-ui/charts";
import { InfoOutline } from "@biggeo/bg-ui/icons";
import { Box, Grid, Stack, Tooltip, Typography } from "@biggeo/bg-ui/lab";
import { DoubleColumnLayout } from "@biggeo/bg-ui/lab/layouts";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import { sumCounts } from "../../map/utils/utils.ts";
import { bgsearch, pinot, redis } from "../../utils/ConstantData.ts";
import {
    CSVToArray,
    bgComparisonColors,
    createTimeArray,
    formatNumberWithCommas,
    generateBigGeoComparisonChartData,
    generateXAxisLabels,
    getClosestDatapoint,
    thousandsSeparator,
} from "../../utils/utils.ts";

export const ResultsContainer = ({
    recentResponse,
}: {
    readonly recentResponse: SubscriptionResponse | undefined;
}) => {
    type ValueType = number | string | Array<number | string>;
    type NameType = number | string;
    const sumOfAllPoints = recentResponse?.geometry?.nonPoints.length
        ? recentResponse?.geometry?.nonPoints.length || 0
        : recentResponse?.geometry?.pointCount.low || 0;

    const sumOfAllAggregatedPoints =
        sumOfAllPoints +
        (recentResponse?.geometry?.nonPointCount
            ? recentResponse?.geometry?.nonPointCount.low
            : recentResponse?.geometry?.aggregation
              ? sumCounts(recentResponse.geometry.aggregation)
              : 0);

    const time = `${
        recentResponse?.geometry?.timeMs
            ? formatNumberWithCommas(recentResponse?.geometry?.timeMs || 0)
            : "-"
    }ms`;

    const rendered = recentResponse?.geometry?.nonPoints.length
        ? `${formatNumberWithCommas(
              recentResponse?.geometry?.nonPoints.length || 0
          )}`
        : `${formatNumberWithCommas(recentResponse?.geometry?.pointCount.low || 0)}`;

    const found = `${formatNumberWithCommas(sumOfAllAggregatedPoints)}`;

    const infoElementProps = [
        {
            propertyName: "Rendered",
            propertyValue: rendered,
            tooltipTitle: "Rendered",
            tooltipContent:
                "Rendered represents the amount of shapes drawn on the screen. Shapes can hold a different amounts of datapoints, since some of the results are aggregagted and not shown as individual points. The bigger the shape, the more datapoints are in that shape.",
        },
        {
            propertyName: "Found",
            propertyValue: found,
            tooltipTitle: "Found",
            tooltipContent:
                "Found represents the amount of datapoints that are retrieved by the current search parameters.",
        },
        {
            propertyName: "Time",
            propertyValue: time,
            tooltipTitle: "Time",
            tooltipContent:
                "Time represents the duration it took for the BGSearch algorithm to find all the datapoints associated with the search parameters. (Please Note: This time does not include the time it takes to retrieve the data over the network and render the results onto the screen",
        },
    ];

    const bgSearchArray = CSVToArray(bgsearch, ",");
    const pinotArray = CSVToArray(pinot, ",");
    const redisArray = CSVToArray(redis, ",");

    const bgSearchTimeArray = createTimeArray(bgSearchArray);
    const pinotTimeArray = createTimeArray(pinotArray);
    const xAxisLabels = generateXAxisLabels(redisArray);

    const lineChartData = generateBigGeoComparisonChartData({
        xAxisLabels,
        bgSearchTimeArray,
        pinotTimeArray,
    });

    const closestPointToFound = getClosestDatapoint(
        lineChartData.map((data) => data.name),
        sumOfAllAggregatedPoints
    );

    const theme = useTheme();

    const colors = Object.values(bgComparisonColors);

    const bigGeoIndex = colors.length - 1;

    const InfoElement = ({
        propertyName,
        propertyValue,
        tooltipTitle,
        tooltipContent,
    }: {
        readonly propertyValue: string;
        readonly propertyName: string;
        readonly tooltipTitle: string;
        readonly tooltipContent: string;
    }) => {
        return (
            <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Grid item>
                    <Typography variant="body4" fontWeight="semibold">
                        {propertyName}
                    </Typography>
                </Grid>
                <Grid item>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                        }}
                    >
                        <Typography variant="body3" fontWeight="bold">
                            {propertyValue}
                        </Typography>
                        <Tooltip title={tooltipTitle} subtitle={tooltipContent}>
                            <span>
                                <InfoOutline size="small" />
                            </span>
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>
        );
    };

    const BigGeoSearchComparisonTooltip = ({
        xAxisLabel,
        values,
        names,
        bigGeoValue,
        colors,
        indexToNotShow,
    }: {
        readonly xAxisLabel: string;
        readonly values: (ValueType | undefined)[];
        readonly names: (NameType | undefined)[];
        readonly bigGeoValue?: ValueType | undefined;
        readonly colors: string[];

        readonly indexToNotShow?: number;
    }) => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    alignItems: "start",
                    backgroundColor: theme.palette.background.onMain,
                    padding: 2,
                    gap: 1,
                    borderRadius: 4,
                }}
            >
                {values.map(
                    (value, index) =>
                        index !== indexToNotShow &&
                        bigGeoValue &&
                        !Number.isNaN(Number(value)) && (
                            <Box sx={{ pt: 1 }}>
                                <Typography
                                    variant="body2"
                                    color="background"
                                    textTransform={"capitalize"}
                                >
                                    {`Big Geo is ${(
                                        Number(value) / Number(bigGeoValue)
                                    ).toFixed(
                                        2
                                    )} times faster than ${names[index]}`}
                                </Typography>
                            </Box>
                        )
                )}
                {values.map((value, index) => (
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    <Box sx={{ color: colors?.[index] }} key={index}>
                        <Typography
                            variant="body2"
                            color="inherit"
                            textTransform={"capitalize"}
                        >
                            {`${names[index]} : ${
                                Number.isNaN(Number(value))
                                    ? "-"
                                    : Number(value)
                            } seconds`}
                        </Typography>
                    </Box>
                ))}
                <Typography
                    variant="body1"
                    color="background"
                    textTransform={"capitalize"}
                >
                    {`Number of Results: ${xAxisLabel}`}
                </Typography>
            </Box>
        );
    };
    return (
        <Stack width={"100%"}>
            <DoubleColumnLayout>
                <Stack gap={4}>
                    {pipe(
                        infoElementProps,
                        A.mapWithIndex((index, infoElementProp) => (
                            <InfoElement key={index} {...infoElementProp} />
                        ))
                    )}
                </Stack>
                <Stack
                    sx={{
                        padding: 4,
                    }}
                >
                    <ComparisonLinearLineChart
                        title="BigGeo Search"
                        subtitle="Performance Comparison"
                        data={lineChartData}
                        colors={colors}
                        reverseLegendDirection
                        lineChartProps={{
                            responsiveContainerProps: {
                                width: "100%",
                                height: themeSpacingToNumber(
                                    theme.spacing(105)
                                ),
                            },
                            xAxisProps: {
                                label: {
                                    value: "Number of Results",
                                    position: "insideBottom",
                                },
                                height: 60,
                                tick: ({ payload, index, ...props }) =>
                                    index % 4 === 0 ? (
                                        <text
                                            style={{
                                                ...theme.typography.body3,
                                                textTransform: "uppercase",
                                                fill: theme.palette.surface
                                                    .main,
                                            }}
                                            {...props}
                                        >
                                            {`${(payload.value / 1000000).toFixed(1)}M`}
                                        </text>
                                    ) : (
                                        <></>
                                    ),
                            },
                            yAxisProps: {
                                label: {
                                    value: "Time In Seconds",
                                    position: "insideLeft",
                                    angle: -90,
                                },
                            },
                            tooltipProps: {
                                isAnimationActive: false,
                                cursor: false,
                                wrapperStyle: {
                                    visibility: "visible",
                                },
                                position: { x: 0, y: 0 },
                                content: ({ payload }) => {
                                    const lineData = lineChartData.find(
                                        (el) =>
                                            el.name === `${closestPointToFound}`
                                    );

                                    const values = lineData
                                        ? Object.values(lineData).filter(
                                              (data) => typeof data === "number"
                                          )
                                        : [];

                                    const names = lineData
                                        ? Object.keys(lineData).filter(
                                              (data) => data !== "name"
                                          )
                                        : [];

                                    const xAxisLabel = lineData
                                        ? thousandsSeparator(lineData.name)
                                        : "";

                                    const bigGeoValue =
                                        bigGeoIndex && values[bigGeoIndex];

                                    return (
                                        <BigGeoSearchComparisonTooltip
                                            xAxisLabel={xAxisLabel}
                                            bigGeoValue={bigGeoValue}
                                            values={values}
                                            names={names}
                                            colors={colors}
                                            indexToNotShow={bigGeoIndex}
                                        />
                                    );
                                },
                            },
                        }}
                        referenceLineProps={{
                            stroke: theme.palette.primary.main,
                            label: "BGSearch",
                            strokeDasharray: "7 7",
                            x: `${closestPointToFound}`,
                        }}
                    />
                </Stack>
            </DoubleColumnLayout>
        </Stack>
    );
};
