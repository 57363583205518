import { ShapeColor } from "@biggeo/bg-server-lib/datascape-ai";
import { Box, useTheme } from "@biggeo/bg-ui/lab";
import { convertToHex8String } from "../../utils/color";

export type ShapeIconProps = {
    readonly oval?: boolean;
    readonly fill?: ShapeColor;
    readonly stroke?: ShapeColor;
};

export const ShapeIcon = ({ oval = false, fill, stroke }: ShapeIconProps) => {
    const theme = useTheme();
    const defaultOpacity = 0.9;
    const defaultColor = theme.palette.primary.main;

    return (
        <Box
            sx={{
                borderRadius: oval ? (theme) => theme.radius.full : undefined,
                height: 2,
                width: 2,
                backgroundColor: convertToHex8String(
                    fill?.color || defaultColor,
                    fill?.opacity || defaultOpacity
                ),
                border: stroke ? 0.5 : undefined,
                borderColor: stroke
                    ? convertToHex8String(
                          stroke.color || defaultColor,
                          stroke.opacity || defaultOpacity
                      )
                    : undefined,
            }}
        />
    );
};
