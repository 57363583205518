import {
    AccessKeyStatus,
    ApiKeyExtends,
    useFetchAllApiKeyAndExtendsQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { IFetchInput } from "@biggeo/bg-ui/lab";
import { Success, match } from "@vividtheory/remotedata";
import { maxBy } from "lodash";
import { useEffect } from "react";
import { formatDateToTimePeriod } from "../../utils/time-helpers";

export type AccessKey = {
    id: string;
    key: string;
    status: AccessKeyStatus;
    datasets: { name: string; img: string }[];
    provider: { name: string; img: string };
    lastUpdated?: string;
};

export type AccessKeysProviderProps = {
    readonly children: React.FC<{
        readonly accessKeys: readonly AccessKey[];
        readonly total: number;
        readonly loading: boolean;
    }>;
    readonly onRefetch?: {
        isRefetch: boolean;
        setIsRefetch: (v: boolean) => void;
    };
    readonly dataGridFetchInputProps: IFetchInput;
};

const convert = (apiKeyExtends: ApiKeyExtends): AccessKey => {
    const { apiKey, dataProvider, dataSourceAndEntitlementList } =
        apiKeyExtends;

    const isActiveKey = dataSourceAndEntitlementList.some(
        (data) => data.entitlement.status === AccessKeyStatus.Active
    );

    const datasets = dataSourceAndEntitlementList.map((data) => ({
        name: data.dataSource.label || data.dataSource.collectionName,
        img: data.dataSource.src || "",
    }));

    const lastUpdated = maxBy(
        dataSourceAndEntitlementList,
        (data) => data.entitlement.updatedAt
    );
    return {
        id: apiKey,
        key: apiKey,
        status: isActiveKey ? AccessKeyStatus.Active : AccessKeyStatus.Revoked,
        datasets,
        provider: { name: dataProvider.name, img: dataProvider.img || "" },
        lastUpdated: formatDateToTimePeriod(
            lastUpdated?.entitlement.updatedAt || new Date(),
            1
        ),
    };
};

export const AccessKeysProvider = ({
    children,
    onRefetch,
    dataGridFetchInputProps,
}: AccessKeysProviderProps) => {
    const {
        remote,
        setRemote,
        queryReturn: { refetch },
    } = useFetchAllApiKeyAndExtendsQuery({
        variables: { input: dataGridFetchInputProps },
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (onRefetch?.isRefetch) {
            refetch().then((data) => setRemote(new Success(data.data)));
            onRefetch.setIsRefetch(false);
        }
    }, [onRefetch?.isRefetch]);

    return match(remote, {
        _: () =>
            children({
                accessKeys: [],
                total: 0,
                loading: true,
            }),
        Success: (data) => {
            const result = data.fetchAllApiKeyAndExtends;

            return children({
                accessKeys: result.apiKeyExtends.map(convert),
                total: result.total,
                loading: false,
            });
        },
        Failure: () =>
            children({
                accessKeys: [],
                total: 0,
                loading: false,
            }),
    });
};
