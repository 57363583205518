import { ShapeColor, ShapeStyle } from "@biggeo/bg-server-lib/datascape-ai";
import { Checkbox, InfoCell, Stack } from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import { match } from "ts-pattern";
import { ShapeIcon } from "../../../common/components/ShapeIcon";

interface IFilterCriteriaStylesShape {
    readonly currentShape: ShapeStyle;
    readonly currentCustomMarker?: string;
    readonly fill?: ShapeColor;
    readonly stroke?: ShapeColor;
    readonly onChange: (s: ShapeStyle) => void;
}

const FilterCriteriaStylesShape = ({
    currentShape,
    currentCustomMarker,
    fill,
    stroke,
    onChange,
}: IFilterCriteriaStylesShape) => {
    const isChecked = (shape: ShapeStyle): boolean =>
        isEqual(currentShape, shape) && !currentCustomMarker;

    return (
        <Stack width={"100%"}>
            {pipe(
                Object.values(ShapeStyle),
                A.map((shape) => (
                    <InfoCell
                        key={shape}
                        slotProps={{
                            title: {
                                fontWeight: "regular",
                            },
                        }}
                        title={startCase(shape)}
                        density="dense"
                        startNode={
                            <Checkbox
                                checked={isChecked(shape)}
                                onChange={() => {
                                    onChange(shape);
                                }}
                            />
                        }
                        endNode={match(shape)
                            .with(ShapeStyle.oval, () => (
                                <ShapeIcon oval fill={fill} stroke={stroke} />
                            ))
                            .with(ShapeStyle.square, () => (
                                <ShapeIcon fill={fill} stroke={stroke} />
                            ))
                            .exhaustive()}
                    />
                ))
            )}
        </Stack>
    );
};

export default FilterCriteriaStylesShape;
