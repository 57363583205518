import { GridColDef } from "@biggeo/bg-ui";
import {
    AvatarGroup,
    Button,
    ColFilterType,
    CompanyAvatar,
    DataGridContainer,
    IFilterSearchPaginate,
    InfoCell,
    MemoizedField,
    Tag,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CheckCircle } from "@biggeo/bg-ui/lab/icons";

import { AccessKeyStatus } from "@biggeo/bg-server-lib/datascape-ai";
import { AccessKey } from "../providers/AccessKeysProvider";

export type AccessKeysTableProps = {
    readonly accessKeys: readonly AccessKey[];
    readonly loading: boolean;
    readonly filterSearchPaginateProps: IFilterSearchPaginate;
    readonly rowCount: number;
};

/**
 * UI/UX Handoff (Delete this block after completion)
 * Internal Requirements (search the following TODOs to see the internal requirements for completion):
 * - TODO: View Button
 */
export const AccessKeysTable = ({
    accessKeys,
    loading,
    filterSearchPaginateProps,
    rowCount,
}: AccessKeysTableProps) => {
    const columns: GridColDef<AccessKey>[] = [
        {
            field: "apiKey",
            headerName: "Key",
            flex: 1,
            minWidth: 340,
            filterable: true,
            renderCell: (params) => (
                <Typography variant="body3" fontWeight="semibold" truncate>
                    {params.row.key}
                </Typography>
            ),
        },
        {
            field: "lastUpdated",
            headerName: "Last update",
            minWidth: 120,
            renderCell: (params) => (
                <Typography variant="body3" fontWeight="semibold" truncate>
                    {params.row.lastUpdated}
                </Typography>
            ),
        },
        {
            field: "status",
            headerName: "Key status",
            minWidth: 130,
            sortable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Tag
                    variant="tonal"
                    color={
                        params.row.status === AccessKeyStatus.Active
                            ? "success"
                            : "error"
                    }
                    rounded
                    density="dense"
                    endNode={<CheckCircle size="xxs" />}
                >
                    {params.row.status === AccessKeyStatus.Active
                        ? "Active key"
                        : params.row.status}
                </Tag>
            ),
        },
        {
            field: "dataset",
            headerName: "Dataset",
            minWidth: 160,
            sortable: false,
            headerAlign: "center",
            align: "center",
            type: ColFilterType.string,
            renderCell: (params) => (
                <MemoizedField>
                    <AvatarGroup max={4}>
                        {params.row.datasets.map((dataset) => (
                            <CompanyAvatar
                                key={dataset.name}
                                size="xxs"
                                src={dataset.img}
                                alt={dataset.name}
                            />
                        ))}
                    </AvatarGroup>
                </MemoizedField>
            ),
        },
        {
            field: "provider",
            headerName: "Provider",
            minWidth: 160,
            sortable: false,
            headerAlign: "center",
            type: ColFilterType.string,
            align: "center",
            filterable: true,
            renderCell: (params) => (
                <MemoizedField>
                    <InfoCell
                        startNode={
                            <CompanyAvatar
                                size="xxs"
                                src={params.row.provider.img}
                                alt={params.row.provider.name}
                            />
                        }
                        title={params.row.provider.name}
                    />
                </MemoizedField>
            ),
        },
        {
            field: "view",
            sortable: false,
            headerName: "",
            minWidth: 120,
            renderCell: () => {
                return (
                    <Button
                        fullWidth
                        variant="outlined"
                        density="dense"
                        // TODO: View Button - Implement the manage button functionality and disabled state
                        disabled={false}
                        onClick={() => null}
                    >
                        View
                    </Button>
                );
            },
        },
    ];

    return (
        <DataGridContainer
            loading={loading}
            title="Dataset Access Keys"
            rows={accessKeys}
            filterSearchPaginateProps={filterSearchPaginateProps}
            sx={{
                "&.MuiDataGrid-root": {
                    minHeight: 350,
                },
            }}
            initialState={{
                pinnedColumns: { right: ["view"] },
            }}
            columns={columns}
            rowCount={rowCount}
        />
    );
};
