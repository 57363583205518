import {
    DatabaseType,
    FilterCriteriaDatasetItem,
    LogicOperator,
    WhereOperator,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Theme, useMediaQuery } from "@biggeo/bg-ui";
import {
    Button,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Grid,
    MapLayoutHeader,
    Stack,
    Tag,
    Typography,
} from "@biggeo/bg-ui/lab";
import { MagicButtonOutline } from "@biggeo/bg-ui/lab/icons";
import {
    WithPartialValues,
    WithRequiredProperty,
    isAllDefinedExceptOne,
    isObjectNotEmpty,
    updateSelected,
} from "@biggeo/bg-utils";
import { Formik, FormikProps } from "formik";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { match } from "ts-pattern";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { Routes } from "../../../navigation/redux/model";
import { toasterActions } from "../../../toaster/containers/redux/model";
import { MapContextDataset, MapContextFilter } from "../../mapbox/context";
import { setDatasetVisibility } from "../../mapbox/utils/data-layers-utils";
import { setFiltersLayers } from "../../mapbox/utils/filtered-data-layers-utils";
import {
    MapFilterCriteriaDataset,
    MapFilterCriteriaForm,
    MapFilterCriteriaSection,
    MapFilterCriteriaStyle,
    mapFilterCriteriaInitialValues,
    mapFilterCriteriaSchema,
} from "../utils/utils";
import FilterCriteriaAccordion from "../views/FilterCriteriaAccordion";
import FilterCriteriaDatasets from "../views/FilterCriteriaDatasets";
import FilterCriteriaDetails from "../views/FilterCriteriaDetails";
import FilterCriteriaStyles from "../views/FilterCriteriaStyles";

interface IMapFilterCriteria {
    readonly mapTemplateId: number;
    readonly handleSidebar: (reset?: boolean) => void;
    readonly addFilter: (i: {
        filter: MapFilterCriteriaForm;
        isPreview: boolean;
    }) => void;
    readonly updateFilter: (
        filter: WithRequiredProperty<Partial<MapContextFilter>, "id">
    ) => void;
    readonly currentFilter?: MapContextFilter;
    readonly saveFilter: (id: string) => void;
    readonly isLoaded: boolean;
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly isOpen: boolean;
    readonly resizeMap: () => void;
    readonly selectedDatasets: MapContextDataset[];
    readonly updatePreviewFilter: (f: Partial<MapContextFilter>) => void;
}

const MapFilterCriteria = ({
    mapTemplateId,
    handleSidebar,
    addFilter,
    currentFilter,
    updateFilter,
    saveFilter,
    isLoaded,
    map,
    isOpen,
    resizeMap,
    selectedDatasets,
    updatePreviewFilter,
}: IMapFilterCriteria) => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );

    const initialValues = mapFilterCriteriaInitialValues(currentFilter);

    const isPreview =
        currentFilter &&
        isEqual(currentFilter.preview, true) &&
        isEqual(currentFilter.selected, false);

    const isEdit =
        currentFilter &&
        isEqual(currentFilter.preview, true) &&
        isEqual(currentFilter.selected, true);

    const submit = (values: MapFilterCriteriaForm) => {
        if (isPreview) {
            addFilter({ filter: values, isPreview: false });
            handleSidebar(false);
            dispatch(
                toasterActions.openToast({
                    open: true,
                    title: "Filter criteria created successfully.",
                    autoHideDuration: 5000,
                })
            );
        }

        if (isEdit) {
            updateFilter({
                id: currentFilter.id,
                preview: false,
                ...values,
            });
            saveFilter(currentFilter.id);
            dispatch(
                toasterActions.openToast({
                    open: true,
                    title: "Filter criteria updated successfully.",
                    autoHideDuration: 5000,
                })
            );
        }
    };

    const getFilteredData = (filter: MapFilterCriteriaForm) => {
        updatePreviewFilter(filter);
    };

    const updateDatasetFilterItem = (i: {
        mapTemplateDatasetId: number;
        index: number;
        logicOperator?: LogicOperator;
        item: Partial<FilterCriteriaDatasetItem>;
        values: WithPartialValues<MapFilterCriteriaForm>;
        setValues: FormikProps<
            WithPartialValues<MapFilterCriteriaForm>
        >["setValues"];
    }) => {
        const {
            mapTemplateDatasetId,
            index,
            item,
            logicOperator,
            setValues,
            values,
        } = i;

        setValues({
            ...values,
            filterCriteria: pipe(
                values.filterCriteria,
                compact,
                A.map((f) =>
                    f.mapTemplateDatasetId === mapTemplateDatasetId
                        ? {
                              ...f,
                              logicOperator: logicOperator ?? f.logicOperator,
                              filters: pipe(
                                  f.filters,
                                  A.mapWithIndex((idx, i) =>
                                      isEqual(idx, index)
                                          ? {
                                                ...i,
                                                ...item,
                                            }
                                          : i
                                  )
                              ),
                          }
                        : f
                )
            ),
        });

        const filter = values.filterCriteria.find(
            (f) => f?.mapTemplateDatasetId === mapTemplateDatasetId
        );

        const element = { ...filter?.filters[index], ...item };

        const isNoValueOperator =
            isEqual(element.operator, WhereOperator.isEmpty) ||
            isEqual(element.operator, WhereOperator.isNotEmpty);

        const hasAllValues = isObjectNotEmpty(element);
        const hasNoValue =
            isNoValueOperator && isAllDefinedExceptOne(element, "value");

        const condition = hasAllValues || hasNoValue;

        if (element && isObjectNotEmpty(values) && condition) {
            const previewedFilterId = currentFilter?.id;

            if (previewedFilterId) {
                updateFilter({
                    id: previewedFilterId,
                    visible: true,
                });
                toggleSelectedDatasetsVisibility({
                    isSelected: true,
                    visibility: "none",
                });
            }

            getFilteredData({
                ...values,
                filterCriteria: pipe(
                    values.filterCriteria,
                    A.map((f) =>
                        f.mapTemplateDatasetId === mapTemplateDatasetId
                            ? {
                                  ...f,
                                  filters: pipe(
                                      f.filters,
                                      A.mapWithIndex((idx, i) =>
                                          isEqual(idx, index)
                                              ? {
                                                    ...i,
                                                    ...element,
                                                }
                                              : i
                                      )
                                  ),
                              }
                            : f
                    )
                ),
            });
        }
    };

    const styleFilteredData = (
        styles: Partial<MapFilterCriteriaStyle>,
        values: WithPartialValues<MapFilterCriteriaForm>
    ) => {
        const id = currentFilter ? currentFilter.id : undefined;

        if (id) {
            if (isPreview) {
                updateFilter({
                    id,
                    styles: { ...values.styles, ...styles },
                });
            }

            setFiltersLayers({
                addedStyles: styles,
                currentStyles: values.styles,
                map,
                isLoaded,
                suffix: id,
            });
        }
    };

    const toggleSelectedDatasetsVisibility = (i: {
        isSelected: boolean;
        visibility: "visible" | "none";
        datasetId?: string;
    }) => {
        const { isSelected, visibility, datasetId } = i;
        const datasets = isSelected
            ? selectedDatasets
            : pipe(
                  selectedDatasets,
                  A.filter((s) => !isEqual(s.dataSource?.id, datasetId))
              );

        datasets.map((d) => {
            if (map.current)
                setDatasetVisibility({
                    map: map.current,
                    prefix: d.dataSource.id,
                    levelSets: [],
                    visibility,
                    isLoaded,
                    points: {
                        shape: d.mapTemplateDataset?.styles?.customMarker
                            ? undefined
                            : d.mapTemplateDataset?.styles?.shape || undefined,
                        customMarker:
                            d.mapTemplateDataset?.styles?.customMarker ||
                            undefined,
                    },
                });
        });
    };

    return (
        <FlexScrollAreaContainer sx={{ overflow: "hidden" }}>
            <MapLayoutHeader
                customHeader={
                    <Grid item xs minWidth={0}>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Typography
                                            variant="title3"
                                            fontWeight="bold"
                                            truncate
                                        >
                                            Filter Criteria
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Tag
                                            startNode={
                                                <MagicButtonOutline
                                                    size={"xxs"}
                                                />
                                            }
                                            density="dense"
                                            variant="filled"
                                            color="primary"
                                            rounded
                                        >
                                            Beta
                                        </Tag>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Link to={Routes.feedback} target="_blank">
                                <Button variant="outlined">
                                    Give Feedback
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                }
                inverted={!isMobile}
                header={"Filter Criteria"}
                onClick={() => {
                    handleSidebar(true);
                    resizeMap();
                }}
            />
            <Formik<WithPartialValues<MapFilterCriteriaForm>>
                validateOnMount
                initialValues={initialValues}
                enableReinitialize={isEdit ? true : undefined}
                onSubmit={(values, actions) => {
                    submit(values as MapFilterCriteriaForm);
                    actions.setSubmitting(false);
                    actions.resetForm({
                        values: mapFilterCriteriaInitialValues(),
                    });
                }}
                validationSchema={toFormikValidationSchema(
                    mapFilterCriteriaSchema
                )}
            >
                {({
                    values,
                    setValues,
                    handleSubmit,
                    isValid,
                    dirty,
                    errors,
                }) => {
                    const onChange = (
                        i: Partial<WithPartialValues<MapFilterCriteriaForm>>
                    ) => {
                        setValues((p) => ({ ...p, ...omitBy(i, isNil) }));
                    };

                    const onDatasetClick = (i: MapFilterCriteriaDataset) => {
                        onChange({
                            filterCriteria: updateSelected(
                                i,
                                values.filterCriteria
                            ),
                        });

                        // Hide the non selected datasets
                        toggleSelectedDatasetsVisibility({
                            isSelected: false,
                            datasetId: i.dataSourceId,
                            visibility: "none",
                        });
                    };

                    const onAddDatasetFilter = (
                        mapTemplateDatasetId: number,
                        filter: Partial<FilterCriteriaDatasetItem>
                    ) => {
                        onChange({
                            filterCriteria: pipe(
                                values.filterCriteria,
                                compact,
                                A.map((f) =>
                                    f.mapTemplateDatasetId ===
                                    mapTemplateDatasetId
                                        ? {
                                              ...f,
                                              filters: [...f.filters, filter],
                                          }
                                        : f
                                )
                            ),
                        });
                    };

                    const deleteDatasetFilterItem = ({
                        mapTemplateDatasetId,
                        index,
                    }: { mapTemplateDatasetId: number; index: number }) => {
                        onChange({
                            filterCriteria: pipe(
                                values.filterCriteria,
                                compact,
                                A.map((f) =>
                                    f.mapTemplateDatasetId ===
                                    mapTemplateDatasetId
                                        ? {
                                              ...f,
                                              filters: pipe(
                                                  f.filters,
                                                  A.filterWithIndex(
                                                      (idx) => idx !== index
                                                  )
                                              ),
                                          }
                                        : f
                                )
                            ),
                        });
                    };

                    const clearAll = (mapTemplateDatasetId: number) => {
                        const clearedFilterCriteria = pipe(
                            values.filterCriteria,
                            compact,
                            A.map((f) =>
                                f.mapTemplateDatasetId === mapTemplateDatasetId
                                    ? {
                                          ...f,
                                          filters: pipe(
                                              f.filters,
                                              A.filterWithIndex(
                                                  (index) => index === 0
                                              ),
                                              A.map((i) => ({
                                                  ...i,
                                                  column: undefined,
                                                  operator: undefined,
                                                  type: undefined,
                                                  value: undefined,
                                              }))
                                          ),
                                      }
                                    : f
                            )
                        );

                        onChange({
                            filterCriteria: clearedFilterCriteria,
                        });

                        if (isObjectNotEmpty(values)) {
                            getFilteredData({
                                ...values,
                                filterCriteria: clearedFilterCriteria,
                            });
                        }

                        if (currentFilter) {
                            const previewedFilterId = currentFilter.id;

                            updateFilter({
                                id: previewedFilterId,
                                visible: false,
                            });
                        }
                    };

                    const removeDataset = (mapTemplateDatasetId: number) => {
                        onChange({
                            filterCriteria: pipe(
                                values.filterCriteria,
                                compact,
                                A.filter(
                                    (f) =>
                                        f.mapTemplateDatasetId !==
                                        mapTemplateDatasetId
                                )
                            ),
                        });

                        // Show all the selected datasets
                        toggleSelectedDatasetsVisibility({
                            isSelected: true,
                            visibility: "visible",
                        });
                    };

                    return (
                        <Stack height={"100%"} width={"100%"}>
                            <FlexScrollArea
                                flexDirection="column"
                                gap={4}
                                sx={{
                                    padding: 4,
                                }}
                            >
                                {pipe(
                                    Object.values(MapFilterCriteriaSection),
                                    A.map((section) => {
                                        const isDetailsFilled =
                                            isEqual(
                                                section,
                                                MapFilterCriteriaSection.details
                                            ) &&
                                            !errors[
                                                MapFilterCriteriaSection.details
                                            ];

                                        const isFilterCriteriaFilled =
                                            isEqual(
                                                section,
                                                MapFilterCriteriaSection.filterCriteria
                                            ) &&
                                            !errors[
                                                MapFilterCriteriaSection
                                                    .filterCriteria
                                            ];

                                        return (
                                            <FilterCriteriaAccordion
                                                key={section}
                                                label={section}
                                                filled={
                                                    isDetailsFilled ||
                                                    isFilterCriteriaFilled
                                                }
                                                hideDot={isEqual(
                                                    section,
                                                    MapFilterCriteriaSection.styles
                                                )}
                                            >
                                                {match(section)
                                                    .with(
                                                        MapFilterCriteriaSection.details,
                                                        () => (
                                                            <FilterCriteriaDetails
                                                                name={
                                                                    values
                                                                        .details
                                                                        .name
                                                                }
                                                                description={
                                                                    values
                                                                        .details
                                                                        .description
                                                                }
                                                                onChange={(
                                                                    details
                                                                ) =>
                                                                    onChange({
                                                                        details,
                                                                    })
                                                                }
                                                            />
                                                        )
                                                    )
                                                    .with(
                                                        MapFilterCriteriaSection.filterCriteria,
                                                        () => (
                                                            <FilterCriteriaDatasets
                                                                mapTemplateId={
                                                                    mapTemplateId
                                                                }
                                                                filters={pipe(
                                                                    values.filterCriteria,
                                                                    compact
                                                                )}
                                                                onDatasetClick={
                                                                    onDatasetClick
                                                                }
                                                                onAddDatasetFilter={
                                                                    onAddDatasetFilter
                                                                }
                                                                deleteDatasetFilterItem={
                                                                    deleteDatasetFilterItem
                                                                }
                                                                clearAll={
                                                                    clearAll
                                                                }
                                                                removeDataset={
                                                                    removeDataset
                                                                }
                                                                updateDatasetFilterItem={(
                                                                    i
                                                                ) =>
                                                                    updateDatasetFilterItem(
                                                                        {
                                                                            ...i,
                                                                            values,
                                                                            setValues,
                                                                        }
                                                                    )
                                                                }
                                                                selectedDatasets={pipe(
                                                                    selectedDatasets,
                                                                    A.filter(
                                                                        (d) =>
                                                                            d
                                                                                .dataSource
                                                                                .type ===
                                                                            DatabaseType.point
                                                                    ),
                                                                    A.map(
                                                                        (s) =>
                                                                            s
                                                                                .dataSource
                                                                                .id
                                                                    )
                                                                )}
                                                            />
                                                        )
                                                    )
                                                    .with(
                                                        MapFilterCriteriaSection.styles,
                                                        () => (
                                                            <FilterCriteriaStyles
                                                                styles={
                                                                    values.styles
                                                                }
                                                                onChange={(
                                                                    styles
                                                                ) =>
                                                                    onChange({
                                                                        styles,
                                                                    })
                                                                }
                                                                styleFilteredData={(
                                                                    styles
                                                                ) =>
                                                                    styleFilteredData(
                                                                        styles,
                                                                        values
                                                                    )
                                                                }
                                                                isOpen={!isOpen}
                                                            />
                                                        )
                                                    )
                                                    .exhaustive()}
                                            </FilterCriteriaAccordion>
                                        );
                                    })
                                )}
                            </FlexScrollArea>
                            <Stack
                                flexDirection="row"
                                justifyContent="flex-end"
                                sx={{
                                    padding: 4,
                                }}
                            >
                                <Button
                                    color="primary"
                                    disabled={!isValid || !dirty}
                                    onClick={() => handleSubmit()}
                                >
                                    Save Filter Criteria
                                </Button>
                            </Stack>
                        </Stack>
                    );
                }}
            </Formik>
        </FlexScrollAreaContainer>
    );
};

export default MapFilterCriteria;
