import {
    DataProvider,
    DataSource,
    VerifiedAccessKeyResponse,
    VerifiedAccessKeyStatus,
    useCreateDataSourcesByApiKeyMutation,
    useFetchOneApiKeyExtendsLazyQuery,
    useStartCollectionDownloadMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Button,
    Divider,
    Grid,
    Stack,
    SubmittingButton,
    TextField,
    ThumbnailAvatar,
    Typography,
    textInputBaseClasses,
} from "@biggeo/bg-ui/lab";
import {
    CancelFilled,
    CheckCircle,
    CompareArrowsOutline,
} from "@biggeo/bg-ui/lab/icons";
import { Formik } from "formik";
import isNil from "lodash/isNil";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Zod from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { BigGeoIndexedCard } from "../../marketplace/components/BigGeoIndexedCard";
import { Routes } from "../../navigation/redux/model";

type CheckAccessKey = {
    apiKey: string;
};

const initialValues = {
    apiKey: "",
};

export const UnlockDatasetViaAccessKeyForm = ({
    handleClose,
}: {
    handleClose: () => void;
}) => {
    const navigate = useNavigate();

    const [dataProvider, setDataProvider] = useState<DataProvider>();
    const [datasets, setDatasets] = useState<DataSource[] | undefined>();
    const {
        executeMutation: createDataSourcesByApiKey,
        mutationReturn: [_, { loading: checkApiKeyLoading }],
    } = useCreateDataSourcesByApiKeyMutation();

    const { executeQuery: fetchOneApiKeyExtends } =
        useFetchOneApiKeyExtendsLazyQuery();

    const { executeMutation: startCollectionDownload } =
        useStartCollectionDownloadMutation();

    const [response, setResponse] = useState<VerifiedAccessKeyResponse>();

    const handleCheckKey = (apiKey: string) => {
        setDataProvider(undefined);
        setDatasets(undefined);

        createDataSourcesByApiKey({
            variables: { apiKey },
            onCompleted: (data) => {
                setResponse(data.createDataSourcesByApiKey.response);
                if (data.createDataSourcesByApiKey.dataProvider) {
                    setDataProvider(
                        data.createDataSourcesByApiKey.dataProvider
                    );
                }
                fetchOneApiKeyExtends({
                    variables: { apiKey },
                    onCompleted: (data) => {
                        const dataSources: DataSource[] =
                            data.fetchOneApiKeyExtends.dataSourceAndEntitlementList.map(
                                (de) => de.dataSource
                            );
                        setDatasets(dataSources);
                    },
                });
            },
            onError: () => {
                setResponse({
                    status: VerifiedAccessKeyStatus.Failed,
                    message: "An error occur while adding dataset.",
                });
            },
        });
    };

    const handleDownload = (dataSource: DataSource) => {
        startCollectionDownload({
            variables: {
                dataSourceId: dataSource.id,
            },
        });
        setDatasets(
            [...(datasets || [])].map((d) =>
                d.id === dataSource.id
                    ? {
                          ...d,
                          progress: 0,
                      }
                    : d
            )
        );
    };

    return (
        <Formik<CheckAccessKey>
            initialValues={initialValues}
            validateOnMount
            onSubmit={(values, _actions) => {
                handleCheckKey(values.apiKey);
            }}
            validationSchema={toFormikValidationSchema(
                Zod.object({
                    apiKey: Zod.string().regex(
                        /^(?:[A-Z0-9]{4}-){7}[A-Z0-9]{4}$/i
                    ),
                })
            )}
        >
            {({
                values,
                handleSubmit,
                resetForm,
                setFieldValue,
                isValid,
                dirty,
            }) => {
                return (
                    <Stack>
                        <Stack
                            width={"100%"}
                            gap={4}
                            sx={{ paddingX: 4, paddingBottom: 4 }}
                        >
                            <Stack gap={3} width={"100%"}>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    gap={1}
                                >
                                    <ThumbnailAvatar
                                        src={dataProvider?.img || undefined}
                                        square
                                        size="md"
                                        sx={{
                                            borderColor: (theme) =>
                                                theme.palette.stroke[200],
                                            borderRadius: (theme) =>
                                                theme.radius.xs3,
                                        }}
                                    />
                                    <CompareArrowsOutline
                                        size="xs"
                                        color="disabled"
                                    />
                                    <ThumbnailAvatar
                                        src={
                                            "https://biggeo.blob.core.windows.net/media/bg-background.png"
                                        }
                                        square
                                        size="md"
                                        sx={{
                                            borderRadius: (theme) =>
                                                theme.radius.xs3,
                                        }}
                                    />
                                </Grid>

                                <Grid item>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        align="center"
                                    >
                                        Unlock A{" "}
                                        <Typography
                                            as="span"
                                            variant="h6"
                                            fontWeight="bold"
                                            color="primary"
                                        >
                                            BigGeo Indexed
                                        </Typography>{" "}
                                        Dataset
                                    </Typography>
                                </Grid>

                                <Grid item sx={{ textAlign: "center" }}>
                                    <Typography
                                        variant="body3"
                                        sx={{
                                            display: "inline",
                                        }}
                                    >
                                        A dataset access key grants access to a
                                        dataset within your Datascape account.
                                        These access keys are issued by BigGeo’s{" "}
                                    </Typography>
                                    <Link
                                        to={`${Routes.bigGeoProductsDatalab}`}
                                        target="_blank"
                                    >
                                        <Typography
                                            variant="body3"
                                            color="primary"
                                            sx={{
                                                display: "inline",
                                            }}
                                        >
                                            data provider partners.
                                        </Typography>
                                    </Link>
                                </Grid>
                            </Stack>

                            <TextField
                                value={values.apiKey}
                                placeholder="BG12-CD34-EF56-GH78-IJ90-KL12-MN34-OP56"
                                helperText={values.apiKey && response?.message}
                                sx={{
                                    [`& .${textInputBaseClasses["input-root"]}`]:
                                        {
                                            paddingX: 4,
                                            paddingY:
                                                response?.status ===
                                                VerifiedAccessKeyStatus.Success
                                                    ? 4
                                                    : 2,
                                            alignItems: "center",
                                        },
                                    [`& .${textInputBaseClasses.input}`]: {
                                        minHeight: "23px",
                                        maxHeight: "23px",
                                        textTransform: "uppercase",
                                    },
                                }}
                                slots={{
                                    helperText: (props) => (
                                        <Grid
                                            sx={{ display: "flex" }}
                                            alignItems="center"
                                            gap={1}
                                        >
                                            {response?.status ===
                                            VerifiedAccessKeyStatus.Failed ? (
                                                <CancelFilled
                                                    size="xxs"
                                                    color={"error"}
                                                />
                                            ) : (
                                                <CheckCircle
                                                    size="xxs"
                                                    color={"success"}
                                                />
                                            )}

                                            <Typography
                                                {...props}
                                                variant="body4"
                                                sx={{
                                                    color: (theme) =>
                                                        theme.palette.disabled
                                                            .onContainer,
                                                }}
                                            />
                                        </Grid>
                                    ),
                                }}
                                fullWidth
                                onChange={(_, v) => {
                                    setResponse(undefined);
                                    setFieldValue("apiKey", v);
                                }}
                                endNode={
                                    <Grid container gap={2} height="100%">
                                        <Divider
                                            orientation="vertical"
                                            color={300}
                                        />
                                        {response?.message ? (
                                            <Button
                                                variant="ghost"
                                                color="primary"
                                                type="reset"
                                                onClick={() => {
                                                    resetForm();
                                                    setDatasets(undefined);
                                                    setDataProvider(undefined);
                                                }}
                                            >
                                                Try New Key
                                            </Button>
                                        ) : (
                                            <SubmittingButton
                                                type="checking"
                                                _type="button"
                                                loading={checkApiKeyLoading}
                                                variant="ghost"
                                                color="primary"
                                                onClick={() => handleSubmit()}
                                                disabled={!isValid || !dirty}
                                            >
                                                Check Key
                                            </SubmittingButton>
                                        )}
                                    </Grid>
                                }
                            />
                            <Grid
                                container
                                gap={1}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Link
                                    to="https://datascape.featurebase.app/help/articles/5156192-dataset-access-key#6q5me7btlqe"
                                    target="_blank"
                                >
                                    <Button variant="minimal" tabIndex={-1}>
                                        Forgot Access key?
                                    </Button>
                                </Link>
                                {/* Jose says this is not currently in scope
                                TODO - Uncomment this when needed */}
                                {/* <Circle
                                    height={1}
                                    width={1}
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.disabled.onContainer,
                                    }}
                                />
                                <Link
                                    target="_blank"
                                >
                                    <Button variant="minimal" tabIndex={-1}>
                                        Forgot Access key?
                                    </Button>
                                </Link> */}
                            </Grid>
                        </Stack>
                        {datasets && (
                            <Stack
                                gap={4}
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.surface.container,
                                    border: 1,
                                    borderColor: (theme) =>
                                        theme.palette.stroke[100],
                                    borderRadius: (theme) => theme.radius.xs3,
                                    padding: 4,
                                    maxHeight: 45,
                                    overflow: "auto",
                                }}
                            >
                                <Stack>
                                    <Typography
                                        variant="body2"
                                        fontWeight="semibold"
                                    >
                                        Ready to download ({datasets?.length})
                                    </Typography>
                                    <Typography
                                        variant="body3"
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.disabled
                                                    .onContainer,
                                        }}
                                    >
                                        You have been give access to these
                                        datasets
                                    </Typography>
                                </Stack>
                                <Divider color={200} />
                                {datasets.map((dataSource) => {
                                    return (
                                        <BigGeoIndexedCard
                                            key={dataSource.id}
                                            image={dataSource.src || ""}
                                            id={dataSource.id}
                                            name={
                                                dataSource.label ||
                                                dataSource.collectionName
                                            }
                                            subTitle={dataProvider?.name || ""}
                                            downloadStatus={
                                                !isNil(dataSource.progress) &&
                                                dataSource.progress === 100
                                                    ? "downloaded"
                                                    : !isNil(
                                                            dataSource.progress
                                                        )
                                                      ? "downloading"
                                                      : "download"
                                            }
                                            onClickDownload={() => {
                                                dataSource.progress === 100
                                                    ? navigate(
                                                          Routes.accessKeys
                                                      )
                                                    : !isNil(
                                                            dataSource.progress
                                                        )
                                                      ? null
                                                      : handleDownload(
                                                            dataSource
                                                        );
                                            }}
                                        />
                                    );
                                })}
                            </Stack>
                        )}
                    </Stack>
                );
            }}
        </Formik>
    );
};
