import { ComputeMetrics } from "@biggeo/bg-server-lib/datascape-ai";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import isUndefined from "lodash/isUndefined";
import { ActionType as ActionT, action } from "typesafe-actions";

export enum Consumers {
    mapbox = "mapbox",
    openLayers = "openLayers",
}

export type MapEngineSelectType = {
    readonly mode: boolean;
    readonly selectedShapes: string[];
};

export type MapEngineSelectInput = Partial<{
    readonly mode: boolean;
    readonly selectedShapeId: string;
}>;

export type MapEngine = {
    readonly id: string;
    readonly name: string;
    readonly img?: string;
    readonly isActive: boolean;
    readonly selectMode?: MapEngineSelectType;
    readonly consumer: Consumers;
};

export type ResponseParseTime = {
    readonly databaseId: string;
    readonly startTime?: number;
    readonly endTime?: number;
};

export type SearchTime = {
    readonly datasetId: string;
    readonly startTime?: number;
    readonly endTime?: number;
};

export type RenderingTime = {
    readonly datasetId: string;
    readonly layerRenderingTime: number;
};

export type ClosableCallToActions = {
    readonly onlyAvailableOnSnowflakeBanner: boolean;
    readonly dataAccessKeyFormPanel: boolean;
};

type CommonModel = {
    readonly isRunningOnSF: boolean;
    readonly hasBgVelocity: boolean;
    readonly mapEngines: readonly MapEngine[];
    readonly isSnp: boolean;
    readonly responseParseTimes: ResponseParseTime[];
    readonly searchTimestamps: SearchTime[];
    readonly renderingTimes: RenderingTime[];
    readonly computeMetrics: readonly ComputeMetrics[];
    readonly reservedMemory: number;
    readonly closableCallToActions: ClosableCallToActions;
};

export type DatasetColor = {
    readonly databaseId: string;
    readonly label: string;
    readonly color: string;
};

const initialState: CommonModel = {
    isSnp: false,
    isRunningOnSF: false,
    hasBgVelocity: false,
    mapEngines: [
        {
            id: "1",
            name: "MapBox",
            consumer: Consumers.mapbox,
            isActive: true,
            selectMode: {
                mode: false,
                selectedShapes: [],
            },
        },
        {
            id: "2",
            name: "OpenLayers",
            consumer: Consumers.openLayers,
            isActive: true,
        },
    ],
    responseParseTimes: [],
    searchTimestamps: [],
    renderingTimes: [],
    computeMetrics: [],
    reservedMemory: 0,
    closableCallToActions: {
        onlyAvailableOnSnowflakeBanner: true,
        dataAccessKeyFormPanel: true,
    },
};

export const commonActions = {
    setIsRunningOnSF: (p: { readonly isRunningOnSF?: boolean }) =>
        action("SET_IS_RUNNING_ON_SF", p),
    setIsSnp: (p: { readonly isSnp: boolean }) => action("SET_IS_SNP", p),
    setHasBgVelocity: (p: { readonly hasBgVelocity: boolean }) =>
        action("SET_HAS_BG_VELOCITY", p),
    setMapEngines: (p: {
        readonly engineId: string;
        readonly isActive: boolean;
    }) => action("SET_MAP_ENGINES", p),
    setSelectMode: (p: {
        readonly engineId: string;
        readonly selectMode: MapEngineSelectInput;
    }) => action("SET_SELECT_MODE", p),
    setResponseParseStartTime: (p: { databaseId: string; startTime: number }) =>
        action("SET_PARSE_START_TIME", p),

    setResponseParseEndTime: (p: { databaseId: string; endTime: number }) =>
        action("SET_PARSE_END_TIME", p),
    setSearchStartTime: (p: {
        datasetId: string;
        startTime: number;
    }) => action("SET_SEARCH_START_TIME", p),
    setSearchEndTime: (p: {
        datasetId: string;
        endTime: number;
    }) => action("SET_SEARCH_END_TIME", p),
    setRenderingTime: (p: {
        datasetId: string;
        layerRenderingTime: number;
        action?: "add";
    }) => action("SET_RENDERING_TIME", p),
    resetRenderingTime: (p: {
        datasetId: string;
    }) => action("RESET_RENDERING_TIME", p),
    setComputeMetrics: (p: { readonly computeMetrics: ComputeMetrics[] }) =>
        action("SET_COMPUTE_METRICS", p),
    setReservedMemory: (p: { readonly reservedMemory: number }) =>
        action("SET_RESERVED_MEMORY", p),

    closeCallToAction: (p: keyof ClosableCallToActions) =>
        action("CLOSE_CALL_TO_ACTION", p),
};

export type CombinedActions = ActionT<typeof commonActions>;

const updateParseTime = ({
    state,
    startTime,
    endTime,
    databaseId,
}: {
    state: CommonModel;
    startTime?: number;
    endTime?: number;
    databaseId: string;
}) => {
    const parseTimeToUpdate = pipe(
        state.responseParseTimes,
        A.findFirst((pt) => isEqual(pt.databaseId, databaseId))
    );

    // Add or update
    return pipe(
        parseTimeToUpdate,
        O.fold(
            () =>
                pipe([
                    ...state.responseParseTimes,
                    {
                        databaseId: databaseId,
                        ...(startTime && { startTime }),
                        ...(endTime && { endTime }),
                    },
                ]),
            (data) =>
                pipe(
                    [...state.responseParseTimes],
                    A.map((item) =>
                        isEqual(item.databaseId, data.databaseId)
                            ? {
                                  ...item,
                                  ...(startTime && { startTime }),
                                  ...(endTime && { endTime }),
                              }
                            : item
                    )
                )
        )
    );
};

const updateSearchTime = ({
    state,
    startTime,
    endTime,
    datasetId,
}: {
    state: CommonModel;
    startTime?: number;
    endTime?: number;
    datasetId: string;
}) => {
    const searchTimeToUpdate = pipe(
        state.searchTimestamps,
        A.findFirst((st) => isEqual(st.datasetId, datasetId))
    );

    // Add or update
    return pipe(
        searchTimeToUpdate,
        O.fold(
            () =>
                pipe([
                    ...state.searchTimestamps,
                    {
                        datasetId,
                        ...(startTime && { startTime }),
                        ...(endTime && { endTime }),
                    },
                ]),
            (data) =>
                pipe(
                    [...state.searchTimestamps],
                    A.map((item) =>
                        isEqual(item.datasetId, data.datasetId)
                            ? {
                                  ...item,
                                  ...(startTime && { startTime }),
                                  ...(endTime && { endTime }),
                              }
                            : item
                    )
                )
        )
    );
};

const updateRenderingTime = ({
    state,
    layerRenderingTime,
    datasetId,
}: {
    state: CommonModel;
    layerRenderingTime: number;
    datasetId: string;
}) => {
    const renderTimeToUpdate = pipe(
        state.renderingTimes,
        A.findFirst((rt) => isEqual(rt.datasetId, datasetId))
    );

    return pipe(
        renderTimeToUpdate,
        O.fold(
            () =>
                pipe([
                    ...state.renderingTimes,
                    {
                        datasetId,
                        layerRenderingTime,
                    },
                ]),
            (data) =>
                pipe(
                    [...state.renderingTimes],
                    A.map((item) =>
                        isEqual(item.datasetId, data.datasetId)
                            ? {
                                  ...item,
                                  layerRenderingTime:
                                      item.layerRenderingTime +
                                      layerRenderingTime,
                              }
                            : item
                    )
                )
        )
    );
};

const resetRenderingTime = ({
    state,
    datasetId,
}: {
    state: CommonModel;
    datasetId: string;
}) => {
    const renderTimeToUpdate = pipe(
        state.renderingTimes,
        A.findFirst((rt) => isEqual(rt.datasetId, datasetId))
    );
    return pipe(
        renderTimeToUpdate,
        O.foldW(
            () => [...state.renderingTimes],
            (data) =>
                pipe(
                    [...state.renderingTimes],
                    A.map((item) =>
                        isEqual(item.datasetId, data.datasetId)
                            ? {
                                  ...item,
                                  layerRenderingTime: 0,
                              }
                            : item
                    )
                )
        )
    );
};

export const commonReducer = (
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
    state: CommonModel = initialState,
    action: CombinedActions
): CommonModel => {
    if (!action) return state;
    switch (action.type) {
        case "SET_IS_SNP":
            return {
                ...state,
                ...action.payload,
            };

        case "SET_HAS_BG_VELOCITY":
            return {
                ...state,
                ...action.payload,
            };

        case "SET_IS_RUNNING_ON_SF":
            return {
                ...state,
                ...action.payload,
            };
        case "SET_MAP_ENGINES": {
            return {
                ...state,
                mapEngines: pipe(
                    [...state.mapEngines],
                    A.map((item) =>
                        isEqual(item.id, action.payload.engineId)
                            ? { ...item, isActive: action.payload.isActive }
                            : item
                    )
                ),
            };
        }
        case "SET_SELECT_MODE": {
            const mode = action.payload.selectMode.mode;
            const selectedShapeId = action.payload.selectMode.selectedShapeId;

            return {
                ...state,
                mapEngines: pipe(
                    [...state.mapEngines],
                    A.map((item) => {
                        const found = item.selectMode?.selectedShapes.find(
                            (id) => id === selectedShapeId
                        );

                        const selectedShapeIds =
                            item.selectMode?.selectedShapes || [];

                        return isEqual(item.id, action.payload.engineId)
                            ? {
                                  ...item,
                                  selectMode: {
                                      mode: isUndefined(mode)
                                          ? item.selectMode?.mode || false
                                          : mode,
                                      selectedShapes: selectedShapeId
                                          ? found && item.selectMode
                                              ? pipe(
                                                    selectedShapeIds,
                                                    A.filter((f) => f !== found)
                                                )
                                              : pipe(
                                                    selectedShapeIds,
                                                    A.concat([selectedShapeId])
                                                )
                                          : isEqual(mode, false)
                                            ? []
                                            : selectedShapeIds,
                                  },
                              }
                            : item;
                    })
                ),
            };
        }

        case "SET_PARSE_START_TIME": {
            return {
                ...state,
                responseParseTimes: updateParseTime({
                    state,
                    startTime: action.payload.startTime,
                    databaseId: action.payload.databaseId,
                }),
            };
        }

        case "SET_PARSE_END_TIME": {
            return {
                ...state,
                responseParseTimes: updateParseTime({
                    state,
                    endTime: action.payload.endTime,
                    databaseId: action.payload.databaseId,
                }),
            };
        }
        case "SET_SEARCH_START_TIME":
            return {
                ...state,
                searchTimestamps: updateSearchTime({
                    state,
                    startTime: action.payload.startTime,
                    datasetId: action.payload.datasetId,
                }),
            };

        case "SET_SEARCH_END_TIME":
            return {
                ...state,
                searchTimestamps: updateSearchTime({
                    state,
                    endTime: action.payload.endTime,
                    datasetId: action.payload.datasetId,
                }),
            };

        case "SET_RENDERING_TIME":
            return {
                ...state,
                renderingTimes: updateRenderingTime({
                    state,
                    layerRenderingTime: action.payload.layerRenderingTime,
                    datasetId: action.payload.datasetId,
                }),
            };
        case "RESET_RENDERING_TIME":
            return {
                ...state,
                renderingTimes: resetRenderingTime({
                    state,
                    datasetId: action.payload.datasetId,
                }),
            };

        case "SET_COMPUTE_METRICS":
            return {
                ...state,
                computeMetrics: action.payload.computeMetrics,
            };
        case "SET_RESERVED_MEMORY":
            return {
                ...state,
                reservedMemory: action.payload.reservedMemory,
            };

        case "CLOSE_CALL_TO_ACTION":
            return {
                ...state,
                closableCallToActions: {
                    ...state.closableCallToActions,
                    [action.payload]: false,
                },
            };
        default:
            return state;
    }
};
