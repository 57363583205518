import { gql, useSubscription } from "@apollo/client";
import {
    AsyncError,
    ComputeMetrics,
    useIsAppRunningOnSfQuery,
    useIsSnpQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Severity } from "@biggeo/bg-ui/lab";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";
import { toasterActions } from "../../toaster/containers/redux/model";
import {
    ClosableCallToActions,
    MapEngine,
    RenderingTime,
    ResponseParseTime,
    SearchTime,
    commonActions,
} from "./model";

export const isSnp = () =>
    useSelector((state: RootState) => state.common.isSnp);

export const useIsSnp = () => {
    const dispatch = useDispatch();
    const { queryReturn } = useIsSnpQuery();
    const isSnp = queryReturn.data?.isSnp || false;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isSnp) {
            dispatch(
                commonActions.setIsSnp({
                    isSnp,
                })
            );
        }
    }, [isSnp]);
};

export const hasBgVelocity = () =>
    useSelector((state: RootState) => state.common.hasBgVelocity);

export const useHasBgVelocity = () => {
    const dispatch = useDispatch();

    // const { queryReturn } = useVerifyHasBgVelocityQuery();
    // const hasBgVelocity =
    //     isSnp() ||
    //     queryReturn.data?.verifyHasBgVelocity.status ===
    //         VerifiedAccessKeyStatus.Failed; //return Failed meant velocity has already been added

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // if (hasBgVelocity) {
        //     dispatch(
        //         commonActions.setHasBgVelocity({
        //             hasBgVelocity,
        //         })
        //     );
        // }

        dispatch(
            commonActions.setHasBgVelocity({
                hasBgVelocity: true,
            })
        );
    }, [hasBgVelocity]);
};

export const isAppRunningOnSF = (): boolean =>
    useSelector((state: RootState) => state.common.isRunningOnSF);

export const useMapEngines = (): readonly MapEngine[] =>
    useSelector((state: RootState) => state.common.mapEngines);

export const useAppRunningOnSF = () => {
    const dispatch = useDispatch();
    const { queryReturn } = useIsAppRunningOnSfQuery();
    const isRunningOnSF = queryReturn.data?.isAppRunningOnSF;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isRunningOnSF) {
            dispatch(
                commonActions.setIsRunningOnSF({
                    isRunningOnSF,
                })
            );
        }
    }, [isRunningOnSF]);
};

export const useResponseParseTimes = (): readonly ResponseParseTime[] =>
    useSelector((state: RootState) => state.common.responseParseTimes);

export const useSearchTimestamps = (): readonly SearchTime[] =>
    useSelector((state: RootState) => state.common.searchTimestamps);

export const useRenderingTimes = (): readonly RenderingTime[] =>
    useSelector((state: RootState) => state.common.renderingTimes);

export const useComputeMetrics = (): readonly ComputeMetrics[] =>
    useSelector((state: RootState) => state.common.computeMetrics);

export const useReservedMemory = (): number =>
    useSelector((state: RootState) => state.common.reservedMemory);

const ASYNC_ERRORS = gql`
    subscription listenToAsyncErrors($channel: String!) {
        listenToAsyncErrors(channel: $channel) {
            type
            code
            message
        }
    }
`;

export const useAsyncErrors = (channel: string) => {
    const dispatch = useDispatch();

    const { data } = useSubscription<
        {
            listenToAsyncErrors: AsyncError;
        },
        { channel: string }
    >(ASYNC_ERRORS, {
        variables: {
            channel: channel,
        },
        context: { clientName: "node" },
        fetchPolicy: "no-cache",
    });
    useEffect(() => {
        if (data?.listenToAsyncErrors) {
            dispatch(
                toasterActions.openToast({
                    open: true,
                    severity: Severity.error,
                    title: data.listenToAsyncErrors.message,
                    message: data.listenToAsyncErrors.message,
                    autoHideDuration: 5000,
                })
            );
        }
    }, [data?.listenToAsyncErrors, dispatch]);

    return { data: data?.listenToAsyncErrors };
};

export const useClosableCallToActions = (c: keyof ClosableCallToActions) =>
    useSelector((state: RootState) => state.common.closableCallToActions[c]);
