import {
    CollectionProgressStatus,
    DatabaseType,
    InputDatasetStatisticsFilters,
    MarketplaceDatasetColumn,
    useFetchDatasetStatisticsLazyQuery,
    useListenToProcessCollectionSubscription,
} from "@biggeo/bg-server-lib/datascape-ai";
import { themeSpacingToNumber } from "@biggeo/bg-ui";
import { HorizontalFilledBarChart } from "@biggeo/bg-ui/charts";
import { MenuItem } from "@biggeo/bg-ui/lab";
import { theme } from "@biggeo/bg-ui/theme";
import { Kinds, match } from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import React from "react";
import { MapContextPreviewDataset, useMap } from "../../map/mapbox/context";

export interface IMarketplaceDatasetStatisticsContainer {
    readonly marketplaceDatasetId: string;
    readonly tableName: string;
    readonly columns: MarketplaceDatasetColumn[];
    readonly filters: InputDatasetStatisticsFilters;
    readonly type: DatabaseType;
    readonly setPreviewDataset: (values?: MapContextPreviewDataset) => void;
}

const MarketplaceDatasetStatisticsContainer = ({
    marketplaceDatasetId,
    tableName,
    filters,
    columns,
    type,
    setPreviewDataset,
}: IMarketplaceDatasetStatisticsContainer) => {
    const firstColumn = columns.map((c) => c.name)[0];
    const [column, setColumn] = useState<string | undefined>(firstColumn);

    const { data } = useListenToProcessCollectionSubscription({
        variables: {
            channel: marketplaceDatasetId,
        },
        context: { clientName: "node" },
        fetchPolicy: "no-cache",
        shouldResubscribe: true,
    });

    const {
        executeQuery: getDatasetStatistics,
        remote,
        queryReturn: [_, { loading }],
    } = useFetchDatasetStatisticsLazyQuery();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            column &&
            data &&
            data?.listenToProcessCollection.status ===
                CollectionProgressStatus.complete
        ) {
            getDatasetStatistics({
                variables: {
                    input: {
                        tableName,
                        column,
                        filters: {
                            ...filters,
                            multipolygon:
                                !filters.multipolygon ||
                                isEmpty(filters.multipolygon)
                                    ? undefined
                                    : filters.multipolygon,
                        },
                    },
                },
                onCompleted: (data) => {
                    setPreviewDataset({
                        type,
                        tableName: data.fetchDatasetStatistics.tableName,
                        column: data.fetchDatasetStatistics.column,
                        geometry: data.fetchDatasetStatistics.geometry,
                    });
                },
                onError: () => {
                    setPreviewDataset(undefined);
                },
            });
        }
    }, [
        data?.listenToProcessCollection.status,
        column,
        filters.polygon,
        filters.multipolygon?.length,
        tableName,
    ]);

    return (
        <HorizontalFilledBarChart
            title="Area data quality"
            loading={loading || remote.kind === Kinds.Initialized}
            selectChipProps={{
                label: column ? column : "Column",
                variant: "outlined",
                closeOnSelect: true,
                options: pipe(
                    columns,
                    A.map((c) => (
                        <MenuItem
                            key={c.name}
                            density="dense"
                            label={c.name}
                            onClick={() => setColumn(c.name)}
                        />
                    ))
                ),
            }}
            data={match(remote, {
                _: () => [
                    {
                        name: "Estimate",
                        amount: 0,
                    },
                ],
                Initialized: () => [
                    {
                        name: "Estimate",
                        amount: 0,
                    },
                ],
                Success: (data) => [
                    {
                        name: "Estimate",
                        amount: data.fetchDatasetStatistics.total,
                    },
                ],
            })}
            barChartProps={{
                responsiveContainerProps: {
                    height: themeSpacingToNumber(theme.spacing(12)),
                },
            }}
            color="chartNegative"
            endColor="background"
            disablePadding
            slotProps={{
                mainContent: {
                    sx: {
                        gap: 1,
                    },
                },
                topDivider: {
                    sx: {
                        display: "none",
                    },
                },
                root: {
                    sx: {
                        border: "none",
                    },
                },
            }}
        />
    );
};

export default React.memo(MarketplaceDatasetStatisticsContainer);
