import {
    AccessKeyStatus,
    CollectionProgressStatus,
    useStartCollectionDownloadMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { GridColDef } from "@biggeo/bg-ui";
import {
    Button,
    ColFilterType,
    CompanyAvatar,
    DataGridContainer,
    IFilterSearchPaginate,
    InfoCell,
    LoadingBar,
    MemoizedField,
    ProgressBar,
    Stack,
    Tag,
    Tooltip,
    Typography,
    useDataGridOptions,
} from "@biggeo/bg-ui/lab";
import {
    CancelFilled,
    CheckCircle,
    DownloadOutline,
    Info,
    PublicOutline,
} from "@biggeo/bg-ui/lab/icons";
import startCase from "lodash/startCase";
import { Link } from "react-router-dom";
import { DatasourceDownloadProgressWrapper } from "../../common/components/DatasourceDownloadProgressWrapper";
import { VelocityButton } from "../../common/components/VelocityButton";
import { Routes } from "../../navigation/redux/model";
import { ActiveDataset } from "../providers/ActiveDatasetsProvider";

export type ActiveDatasetsTableProps = {
    readonly activeDatasets: readonly ActiveDataset[];
    readonly loading: boolean;
    readonly navigate: (route: string) => void;
    readonly filterSearchPaginateProps: IFilterSearchPaginate;
    readonly rowCount: number;
};

/**
 * UI/UX Handoff (Delete this block after completion)
 * Internal Requirements (search the following TODOs to see the internal requirements for completion):
 * - TODO: Use Dataset Tooltip
 * - TODO: Manage Button
 */
export const ActiveDatasetsTable = ({
    activeDatasets,
    loading,
    navigate,
    filterSearchPaginateProps,
    rowCount,
}: ActiveDatasetsTableProps) => {
    useDataGridOptions();

    const { executeMutation: startCollectionDownload } =
        useStartCollectionDownloadMutation();

    const columns: GridColDef<ActiveDataset>[] = [
        {
            field: "label",
            headerName: "Key",
            flex: 1,
            minWidth: 340,
            filterable: true,
            renderCell: (params) => (
                <InfoCell
                    startNode={
                        <CompanyAvatar
                            size="xxs"
                            src={params.row.dataset.src || undefined}
                            alt={
                                params.row.dataset.label ||
                                params.row.dataset.collectionName
                            }
                        />
                    }
                    subtitle={params.row.key}
                    title={
                        params.row.dataset.label ||
                        params.row.dataset.collectionName
                    }
                />
            ),
        },
        {
            field: "download",
            headerName: "Use dataset",
            minWidth: 200,
            sortable: false,
            filterable: false,
            align: "center",
            headerAlign: "center",
            renderHeader: () => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        Use dataset
                    </Typography>
                    {/* TODO: Use Dataset Tooltip - Implement the tooltip for the dataset*/}

                    <Tooltip
                        title=""
                        sx={{
                            maxWidth: 45,
                        }}
                    >
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => {
                const { progress, id } = params.row.dataset;

                return (
                    <DatasourceDownloadProgressWrapper
                        datasourceId={id}
                        key={id}
                        updateDataset={() => {}}
                    >
                        {({ progress: downloadProgress, status }) => {
                            const downloadingProgress =
                                Number(downloadProgress) || 0;

                            return progress === 100 ||
                                status === CollectionProgressStatus.complete ? (
                                <Button
                                    variant="outlined"
                                    endNode={<PublicOutline size="sm" />}
                                    onClick={() =>
                                        navigate(
                                            `${Routes.mapTemplatesCreate}/${params.row.id}`
                                        )
                                    }
                                >
                                    Create Map
                                </Button>
                            ) : progress === 0 ||
                              status === CollectionProgressStatus.downloading ||
                              status === CollectionProgressStatus.inserting ? (
                                <Stack width={"100%"}>
                                    <Stack
                                        justifyContent={"space-between"}
                                        flexDirection={"row"}
                                    >
                                        <Typography
                                            variant={"body4"}
                                            fontWeight={"semibold"}
                                        >
                                            {status ===
                                            CollectionProgressStatus.inserting
                                                ? "Inserting Data"
                                                : "Downloading"}
                                        </Typography>
                                        {status ===
                                        CollectionProgressStatus.inserting ? (
                                            <></>
                                        ) : (
                                            <Typography
                                                variant={"body4"}
                                                fontWeight={"semibold"}
                                            >{`${downloadingProgress}% Done`}</Typography>
                                        )}
                                    </Stack>
                                    {status ===
                                    CollectionProgressStatus.inserting ? (
                                        <LoadingBar color={"success"} />
                                    ) : (
                                        <ProgressBar
                                            value={downloadingProgress}
                                            color={"success"}
                                            sx={{
                                                borderRadius: (theme) =>
                                                    theme.radius.full,
                                                height: (theme) =>
                                                    theme.spacing(1.5),
                                            }}
                                        />
                                    )}
                                </Stack>
                            ) : (
                                <Button
                                    variant="outlined"
                                    endNode={<DownloadOutline />}
                                    onClick={() => {
                                        startCollectionDownload({
                                            variables: { dataSourceId: id },
                                        });
                                    }}
                                    disabled={
                                        params.row.status !==
                                        AccessKeyStatus.Active
                                    }
                                >
                                    Download
                                </Button>
                            );
                        }}
                    </DatasourceDownloadProgressWrapper>
                );
            },
        },
        {
            field: "status",
            headerName: "Dataset status",
            minWidth: 125,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
                <Tag
                    variant="tonal"
                    color="background"
                    density="dense"
                    rounded
                    endNode={
                        params.row.status === AccessKeyStatus.Active ? (
                            <CheckCircle color="success" size="xxs" />
                        ) : (
                            <CancelFilled size="xxs" color={"error"} />
                        )
                    }
                >
                    {params.row.status}
                </Tag>
            ),
        },
        {
            field: "velocity",
            headerName: "Velocity",
            minWidth: 120,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.field)}
                    </Typography>
                    <Tooltip
                        title="Fast geospatial querying engine that processes data up to 100x faster."
                        sx={{
                            maxWidth: 45,
                        }}
                        bodyNode={
                            <Link
                                to={Routes.bigGeoProductsVelocity}
                                target="_blank"
                            >
                                <Button variant="outlined" fullWidth>
                                    Learn More
                                </Button>
                            </Link>
                        }
                    >
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: () => (
                <MemoizedField>
                    <VelocityButton hasVelocity={true} />
                </MemoizedField>
            ),
        },
        {
            field: "provider",
            headerName: "Provider",
            minWidth: 160,
            sortable: false,
            headerAlign: "center",
            type: ColFilterType.string,
            filterable: true,
            renderCell: (params) => (
                <MemoizedField>
                    <InfoCell
                        startNode={
                            <CompanyAvatar
                                size="xxs"
                                src={params.row.provider.img}
                                alt={params.row.provider.name}
                            />
                        }
                        title={params.row.provider.name}
                    />
                </MemoizedField>
            ),
        },
        {
            field: "manage",
            sortable: false,
            headerName: "",
            minWidth: 110,
            renderCell: (params) => {
                return (
                    <Button
                        fullWidth
                        variant="outlined"
                        density="dense"
                        disabled={params.row.progress !== 100}
                        onClick={() =>
                            navigate(`${Routes.dataManage}/${params.row.id}`)
                        }
                    >
                        Manage
                    </Button>
                );
            },
        },
    ];

    return (
        <DataGridContainer
            title="Dataset Access Keys"
            loading={loading}
            rows={activeDatasets}
            filterSearchPaginateProps={filterSearchPaginateProps}
            sx={{
                "&.MuiDataGrid-root": {
                    minHeight: 350,
                },
            }}
            initialState={{
                pinnedColumns: { right: ["manage"] },
            }}
            columns={columns}
            rowCount={rowCount}
        />
    );
};
