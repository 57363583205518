import {
    FetchSnowflakeDataQuery,
    FetchSnowflakeTableSchemaQuery,
    FilterCriteriaDatasetItem,
    FilterObject,
    InputPolygon,
    InputViewBox,
    useFetchSnowflakeDataQuery,
    useFetchSnowflakeTableSchemaQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    LoadingBar,
    LogicOperator,
    useDataGridOptions,
} from "@biggeo/bg-ui/lab";
import { map2, match as matchRD } from "@vividtheory/remotedata";

import { useState } from "react";
import { ErrorPage } from "../../../common/components/ErrorPage";
import { MapContextDataset } from "../../mapbox/context";
import { getDefaultDatasetConfigurationOptions } from "../../utils/utils";
import {
    FILTERED_DATA_TABLE_LIMIT,
    MapFilterCriteriaDataset,
    mapfilterCriteriaToMultifilter,
} from "../utils/utils";
import FilterCriteriaDatasetTableView from "../views/FilterCriteriaDatasetTableView";

interface DatasetTableContainerDefautType {
    readonly channelId: string;
    readonly geospatialSelection:
        | { viewport: InputViewBox }
        | { multipolygon: InputPolygon[] };
    readonly isRunningOnSF: boolean;
    readonly filterId?: string;
    readonly updateFilter?: (filters: FilterCriteriaDatasetItem[]) => void;
}
interface IDefaultDatasetTableContainer
    extends DatasetTableContainerDefautType {
    readonly type: "data";
    readonly data: MapContextDataset;
}

interface IFilteredDatasetTableContainer
    extends DatasetTableContainerDefautType {
    readonly type: "filteredData";
    readonly data: MapFilterCriteriaDataset;
}

type DatasetTableContainerProps =
    | IDefaultDatasetTableContainer
    | IFilteredDatasetTableContainer;

const DatasetTableContainer = ({
    type,
    channelId,
    isRunningOnSF,
    data,
    filterId,
    updateFilter,
    ...props
}: DatasetTableContainerProps) => {
    const filter: FilterObject =
        type === "filteredData"
            ? mapfilterCriteriaToMultifilter(data, isRunningOnSF)
            : {
                  filters: [],
                  databaseId: data.dataSource.id,
                  logicOperator: LogicOperator.and,
                  collection: data.dataSource.collectionName,
                  databaseType: data.dataSource.type,
                  options:
                      data.configuration.options ||
                      getDefaultDatasetConfigurationOptions(isRunningOnSF),
              };

    const [date] = useState(Date.now().toString());
    const {
        dataGridFetchInputProps: filteredDataGridProps,
        filterSearchPaginateProps: filteredDataPaginationProps,
    } = useDataGridOptions(FILTERED_DATA_TABLE_LIMIT);

    const {
        remote: columnsRD,
        queryReturn: { loading: cLoading },
    } = useFetchSnowflakeTableSchemaQuery({
        variables: {
            databaseId: filter.databaseId,
        },
    });

    const {
        remote: dataRD,
        queryReturn: { loading: dLoading },
    } = useFetchSnowflakeDataQuery({
        variables: {
            filters: {
                filters: {
                    filters: filter.filters,
                    databaseId: filter.databaseId,
                    logicOperator: filter.logicOperator,
                    collection: filter.collection,
                    databaseType: filter.databaseType,
                },
                limitOffset: {
                    limit: filteredDataGridProps.pageLimit,
                    offset:
                        filteredDataGridProps.pageLimit *
                        (filteredDataGridProps.pageIndex - 1),
                },
                dateTime: date,
                channelId,
                ...props.geospatialSelection,
            },
        },
    });

    const remote = map2(
        (c: FetchSnowflakeTableSchemaQuery) =>
            (d: FetchSnowflakeDataQuery) => ({
                columns: c.fetchSnowflakeTableSchema.rows,
                data: d.fetchSnowflakeData,
            }),
        columnsRD,
        dataRD
    );

    return matchRD(remote, {
        _: () => <LoadingBar />,
        Success: (d) => {
            return (
                <FilterCriteriaDatasetTableView
                    {...d}
                    title={
                        type === "filteredData"
                            ? data.label || data.collection
                            : data.dataSource.label || data.dataSource.tableName
                    }
                    loading={cLoading || dLoading}
                    filteredDataPaginationProps={filteredDataPaginationProps}
                />
            );
        },
        Failure: (err) => <ErrorPage subtitle={err.message} />,
    });
};

export default DatasetTableContainer;
