import {
    BorderedTabsWrapper,
    Button,
    FeatureComingSoon,
    Stack,
    Tab,
    TabGroup,
    Typography,
} from "@biggeo/bg-ui/lab";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../../navigation/redux/model";
import MarketplaceDatasetStatisticsContainer, {
    IMarketplaceDatasetStatisticsContainer,
} from "../containers/MarketplaceDatasetStatisticsContainer";

export type AreaQualityTabsProps = IMarketplaceDatasetStatisticsContainer & {
    onClickDownload?: () => void;
};

export const AreaQualityTabs = ({
    onClickDownload,
    ...props
}: AreaQualityTabsProps) => {
    const [tab, setTab] = useState("area-quality");
    const isDownloaded = true;
    const downloadDisabled = true;

    return (
        <Stack gap={4}>
            <BorderedTabsWrapper>
                <TabGroup variant="tonal" value={tab} square>
                    <Tab
                        value="area-quality"
                        onClick={() => setTab("area-quality")}
                    >
                        Area Quality
                    </Tab>
                    <Tab
                        value="get-sample"
                        onClick={() => setTab("get-sample")}
                    >
                        Get Sample
                    </Tab>
                </TabGroup>
            </BorderedTabsWrapper>
            {tab === "area-quality" ? (
                isDownloaded ? (
                    <MarketplaceDatasetStatisticsContainer {...props} />
                ) : (
                    <Stack
                        gap={1}
                        alignItems="center"
                        sx={{
                            padding: 8,
                            border: 1,
                            borderColor: (theme) => theme.palette.stroke[100],
                            borderRadius: (theme) => theme.radius.xs3,
                        }}
                    >
                        <Typography
                            variant="title1"
                            fontWeight="bold"
                            align="center"
                        >
                            Download Preview
                        </Typography>
                        <Typography
                            variant="body3"
                            textColor="disabled.onContainer"
                            align="center"
                        >
                            Download preview to view area quality stats
                        </Typography>
                        <Button
                            variant="outlined"
                            disabled={downloadDisabled}
                            onClick={onClickDownload}
                        >
                            Download
                        </Button>
                    </Stack>
                )
            ) : (
                <FeatureComingSoon
                    endNode={
                        <Link
                            to={Routes.featureRequestAndIssues}
                            target="_blank"
                        >
                            <Button variant="outlined">Upvote feature</Button>
                        </Link>
                    }
                />
            )}
        </Stack>
    );
};
