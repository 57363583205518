import {
    CollectionProgressStatus,
    MapTemplateDatasetExtended,
    UpdateMapTemplateDataset,
    useUpdateMapTemplateDatasetMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    DragAndDropContext,
    DroppableColumn,
    Severity,
    Stack,
} from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import some from "lodash/some";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { match as tsMatch } from "ts-pattern";
import { DatasourceDownloadProgressWrapper } from "../../common/components/DatasourceDownloadProgressWrapper";
import { heatmapSwatchOptions } from "../../common/components/HeatMapColorMapper";
import { PreviewDatasetCellItem } from "../../common/components/PreviewDatasetCellItem";
import { ToggleDatasetCellItem } from "../../common/components/ToggleDatasetCellItem";
import { getLinearGradient } from "../../common/utils/gradient";
import { AddDataSetSubMenuLayout } from "../../layouts/AddDatasetSubMenuLayout";
import { Routes } from "../../navigation/redux/model";
import { toasterActions } from "../../toaster/containers/redux/model";
import DatasetConfigurationContainer, {} from "../containers/DatasetConfigurationContainer";
import { MapContextDataset, useMap } from "../mapbox/context";
import { SetDatasetContextType } from "../mapbox/context/context-utils";
import {
    reorderDatasetLayers,
    setDatasetLayers,
} from "../mapbox/utils/data-layers-utils";
import { getDatasetShapeColor } from "../utils/style-utils";
import DatasetMenu from "./DatasetMenu";

interface IMapLayoutLeftContent
    extends Pick<SetDatasetContextType, "updateDataset" | "reorderDatasets"> {
    readonly datasets: MapContextDataset[];
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly isLoaded: boolean;
    readonly isRunningOnSF: boolean;
    readonly mapTemplateId?: number;
    readonly setRemote: (v: MapTemplateDatasetExtended[]) => void;
    readonly isFromMarketplace?: boolean;
    readonly setLocalDataList: (v: string[]) => void;
    readonly localDataList: string[];
    readonly onViewTable: (dataSourceId: string, state: boolean) => void;
    readonly isSavedViewPage: boolean;
}

export const MapLayoutLeftContent = ({
    datasets,
    mapTemplateId,
    map,
    setRemote,
    setLocalDataList,
    localDataList,
    onViewTable,
    isLoaded,
    updateDataset,
    isSavedViewPage,
    isFromMarketplace,
    reorderDatasets,
    isRunningOnSF,
}: IMapLayoutLeftContent) => {
    const { dispatch: mapDispatch } = useMap();
    const toPage = useNavigate();
    const dispatch = useDispatch();
    const { executeMutation: updateMapTemplateDatasetMutation } =
        useUpdateMapTemplateDatasetMutation();

    const dataset = useMemo(
        () =>
            pipe(
                datasets,
                A.findFirst(
                    (f) =>
                        isEqual(f.isGettingStyled, true) ||
                        isEqual(f.configuration.isOpen, true)
                ),
                O.fold(
                    () => undefined,
                    (f) => f
                )
            ),
        [datasets]
    );

    const isGettingStyled = dataset ? dataset.isGettingStyled : false;
    const isConfigurationOpen = dataset ? dataset.configuration.isOpen : false;

    const updateMapTemplateDataset = (input: UpdateMapTemplateDataset) => {
        if (!isSavedViewPage) {
            updateMapTemplateDatasetMutation({
                variables: {
                    input,
                },
                onCompleted: () => {
                    dispatch(
                        toasterActions.openToast({
                            open: true,
                            title: "Dataset Style saved successfully.",
                            autoHideDuration: 5000,
                            severity: Severity.success,
                        })
                    );
                },
                onError: (error) => {
                    dispatch(
                        toasterActions.openToast({
                            open: true,
                            title: `Error saving dataset styles: ${error.message}.`,
                            autoHideDuration: 5000,
                            severity: Severity.error,
                        })
                    );
                },
            });
        }
    };

    const {
        extraHeatmapOptions,
        setExtraHeatmapOptions,
        updateShape,
        updateColor,
        updateCustomMarker,
        updateHeatMapColor,
    } = setDatasetLayers({
        dispatch: mapDispatch,
        currentDataset: dataset,
        isSavedViewPage,
        updateMapTemplateDataset,
    });

    const handleLeftContentClick = (dataset: MapContextDataset) => {
        updateDataset({
            dataSourceId: dataset.dataSource.id,
            dataset: {
                isGettingStyled: !dataset.isGettingStyled,
            },
        });
        const { heatmap } = getDatasetShapeColor(dataset);

        const isDuplicate = some(heatmapSwatchOptions, (h) =>
            isEqual(h.swatch, heatmap.swatch)
        );

        if (!isDuplicate) {
            setExtraHeatmapOptions([heatmap]);
        }
    };

    const manageCompute = () =>
        isFromMarketplace
            ? toPage(`${Routes.data}/available`)
            : toPage(`${Routes.data}/map-template/${mapTemplateId}`);

    return (
        <Stack
            gap={3}
            width="100%"
            sx={{
                padding: 3,
            }}
        >
            {mapTemplateId && !isGettingStyled && !isConfigurationOpen && (
                <AddDataSetSubMenuLayout
                    mapTemplateId={mapTemplateId}
                    existingDatasets={pipe(
                        datasets,
                        A.map((d) => d.dataSource)
                    )}
                    setRemote={setRemote}
                    localDataList={localDataList}
                    setLocalDataList={setLocalDataList}
                />
            )}
            {pipe(
                dataset,
                O.fromNullable,
                O.fold(
                    () => (
                        <DragAndDropContext
                            onDragEnd={(result) => {
                                if (!result.destination) {
                                    return;
                                }
                                const items = Array.from(datasets);
                                const [reorderedItem] = items.splice(
                                    result.source.index,
                                    1
                                );
                                items.splice(
                                    result.destination.index,
                                    0,
                                    reorderedItem
                                );

                                reorderDatasets(
                                    items.map((i) => i.dataSource.id)
                                );

                                const order = items
                                    .map((item) => ({
                                        type: item.dataSource.type,
                                        dataSourceId: item.dataSource.id,
                                    }))
                                    .reverse();

                                reorderDatasetLayers(order, map, isLoaded);
                            }}
                        >
                            <DroppableColumn droppableId="datasets">
                                <Stack
                                    sx={{
                                        "& > *": {
                                            marginY: 1,
                                        },
                                    }}
                                >
                                    {pipe(
                                        datasets,
                                        A.mapWithIndex((idx, d) => {
                                            if (isNil(d.dataSource.progress))
                                                return <></>;
                                            const { heatmap } =
                                                getDatasetShapeColor(d);

                                            const heatmapGradient =
                                                getLinearGradient(heatmap);

                                            return (
                                                <DatasourceDownloadProgressWrapper
                                                    datasourceId={
                                                        d.dataSource.id
                                                    }
                                                    key={d.dataSource.id}
                                                    updateDataset={
                                                        updateDataset
                                                    }
                                                >
                                                    {({
                                                        progress:
                                                            downloadProgress,
                                                        status,
                                                    }) => {
                                                        const progress =
                                                            d.dataSource
                                                                .progress ||
                                                            Number(
                                                                downloadProgress
                                                            );

                                                        return tsMatch(progress)
                                                            .when(
                                                                (p) => p >= 100,
                                                                () => (
                                                                    <ToggleDatasetCellItem
                                                                        key={
                                                                            d
                                                                                .dataSource
                                                                                .id
                                                                        }
                                                                        item={d}
                                                                        gradient={
                                                                            heatmapGradient
                                                                        }
                                                                        manageCompute={
                                                                            manageCompute
                                                                        }
                                                                        onItemClick={() =>
                                                                            handleLeftContentClick(
                                                                                d
                                                                            )
                                                                        }
                                                                        index={
                                                                            idx
                                                                        }
                                                                        updateDataset={
                                                                            updateDataset
                                                                        }
                                                                        onViewTable={
                                                                            onViewTable
                                                                        }
                                                                    />
                                                                )
                                                            )
                                                            .otherwise(() => (
                                                                <PreviewDatasetCellItem
                                                                    status={
                                                                        status ||
                                                                        undefined
                                                                    }
                                                                    title={
                                                                        status ===
                                                                        CollectionProgressStatus.inserting
                                                                            ? "Inserting dataset"
                                                                            : "Processing dataset"
                                                                    }
                                                                    key={
                                                                        d
                                                                            .dataSource
                                                                            .id
                                                                    }
                                                                    disabled={
                                                                        status !==
                                                                        CollectionProgressStatus.complete
                                                                    }
                                                                    index={1}
                                                                    dataset={{
                                                                        id: d
                                                                            .dataSource
                                                                            .id,
                                                                        name:
                                                                            d
                                                                                .dataSource
                                                                                .label ||
                                                                            d
                                                                                .dataSource
                                                                                .tableName,
                                                                        image:
                                                                            d
                                                                                .dataSource
                                                                                .icon ||
                                                                            "",
                                                                        progress:
                                                                            Number(
                                                                                progress
                                                                            ),
                                                                    }}
                                                                />
                                                            ));
                                                    }}
                                                </DatasourceDownloadProgressWrapper>
                                            );
                                        })
                                    )}
                                </Stack>
                            </DroppableColumn>
                        </DragAndDropContext>
                    ),
                    (currentDataset) =>
                        isGettingStyled ? (
                            <DatasetMenu
                                dataset={currentDataset}
                                onBack={(dataSourceId) =>
                                    updateDataset({
                                        dataSourceId,
                                        dataset: {
                                            isGettingStyled: false,
                                        },
                                    })
                                }
                                updateHeatMapColor={(value, dataset) =>
                                    updateHeatMapColor({
                                        value,
                                        map,
                                        isLoaded,
                                        dataset,
                                    })
                                }
                                extraOptions={extraHeatmapOptions}
                                updateColor={(i) =>
                                    updateColor({
                                        ...i,
                                        map,
                                        isLoaded,
                                    })
                                }
                                updateShape={(i) =>
                                    updateShape({ ...i, map, isLoaded })
                                }
                                updateCustomMarker={({ marker, dataset }) =>
                                    updateCustomMarker({
                                        marker,
                                        map,
                                        isLoaded,
                                        dataset,
                                    })
                                }
                            />
                        ) : (
                            <DatasetConfigurationContainer
                                dataset={currentDataset}
                                isRunningOnSF={isRunningOnSF}
                                updateDataset={updateDataset}
                            />
                        )
                )
            )}
        </Stack>
    );
};
