import {
    DataProvider,
    VerifiedAccessKeyResponse,
    VerifiedAccessKeyStatus,
    useCreateDataSourcesByApiKeyMutation,
    useSyncAzEntitlementsMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    BorderedTabsWrapper,
    Button,
    Divider,
    EmptyState,
    Grid,
    Severity,
    Stack,
    SubmittingButton,
    Tab,
    TabGroup,
    TextField,
    ThumbnailAvatar,
    Typography,
    textInputBaseClasses,
    useDataGridOptions,
} from "@biggeo/bg-ui/lab";
import {
    ActionKeyOutline,
    CancelFilled,
    CheckCircle,
    CompareArrowsOutline,
    KeyOutline,
} from "@biggeo/bg-ui/lab/icons";
import { Formik } from "formik";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Zod from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { Routes } from "../../navigation/redux/model";
import { toasterActions } from "../../toaster/containers/redux/model";
import { AccessKeysTable } from "../components/AccessKeysTable";
import { ActiveDatasetsTable } from "../components/ActiveDatasetsTable";
import { AccessKeysProvider } from "../providers/AccessKeysProvider";
import { ActiveDatasetsProvider } from "../providers/ActiveDatasetsProvider";

type CheckAccessKey = {
    apiKey: string;
};

const initialValues = {
    apiKey: "",
};

export const UnlockDatasetAccessKeyPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const openNewTab = (url: string) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const [isRefetch, setIsRefetch] = useState(false);

    const [tab, setTab] = useState<"access-keys" | "active-datasets">(
        "access-keys"
    );

    const [dataProvider, setDataProvider] = useState<DataProvider>();

    const {
        executeMutation: createDataSourcesByApiKey,
        mutationReturn: [_, { loading: checkApiKeyLoading }],
    } = useCreateDataSourcesByApiKeyMutation();

    const [response, setResponse] = useState<VerifiedAccessKeyResponse>();

    const handleCheckKey = (apiKey: string) => {
        createDataSourcesByApiKey({
            variables: { apiKey },
            onCompleted: (data) => {
                setIsRefetch(true);
                setResponse(data.createDataSourcesByApiKey.response);
                if (data.createDataSourcesByApiKey.dataProvider) {
                    setDataProvider(
                        data.createDataSourcesByApiKey.dataProvider
                    );
                }
            },
            onError: () => {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "An error occur while adding dataset. Please try again later.",
                        autoHideDuration: 5000,
                        severity: Severity.error,
                    })
                );
            },
        });
    };
    const { filterSearchPaginateProps, dataGridFetchInputProps } =
        useDataGridOptions();

    const { executeMutation: syncAzEntitlements } =
        useSyncAzEntitlementsMutation();
    const handleSyncAzEntitlements = () => {
        syncAzEntitlements({
            onCompleted: () => {
                setIsRefetch(true);
            },
        });
    };

    // Sync the entitlements between azure and local table
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        handleSyncAzEntitlements();
    }, []);

    return (
        <Formik<CheckAccessKey>
            initialValues={initialValues}
            validateOnMount
            onSubmit={(values) => {
                handleCheckKey(values.apiKey);
            }}
            validationSchema={toFormikValidationSchema(
                Zod.object({
                    // API key must be this format: XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX
                    apiKey: Zod.string().regex(
                        /^(?:[A-Z0-9]{4}-){7}[A-Z0-9]{4}$/i
                    ),
                })
            )}
        >
            {({
                values,
                setFieldValue,
                isValid,
                dirty,
                resetForm,
                handleSubmit,
            }) => {
                return (
                    <Stack
                        width="100%"
                        height="100%"
                        sx={{
                            padding: 4,
                            overflow: "auto",
                        }}
                    >
                        <Stack
                            alignItems="center"
                            gap={4}
                            sx={{
                                paddingY: 8,
                                paddingX: 4,
                                border: 1,
                                borderColor: (theme) =>
                                    theme.palette.stroke[100],
                                borderRadius: 2,
                            }}
                        >
                            <Stack gap={4} width={"100%"} maxWidth={135}>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    gap={1}
                                >
                                    <ThumbnailAvatar
                                        src={dataProvider?.img || undefined}
                                        square
                                        size="md"
                                        sx={{
                                            borderColor: (theme) =>
                                                theme.palette.stroke[200],
                                            borderRadius: (theme) =>
                                                theme.radius.xs3,
                                        }}
                                    />
                                    <CompareArrowsOutline
                                        size="xs"
                                        color="disabled"
                                    />

                                    <ThumbnailAvatar
                                        src={
                                            "https://biggeo.blob.core.windows.net/media/bg-background.png"
                                        }
                                        square
                                        size="md"
                                        sx={{
                                            borderRadius: (theme) =>
                                                theme.radius.xs3,
                                        }}
                                        // TODO! Delete later. Hidden onClick for demo.
                                        onClick={() =>
                                            handleSyncAzEntitlements()
                                        }
                                    />
                                </Grid>

                                <Grid item sx={{ textAlign: "center" }}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        sx={{
                                            display: "inline",
                                        }}
                                    >
                                        Unlock A{" "}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        color="primary"
                                        sx={{
                                            display: "inline",
                                        }}
                                    >
                                        BigGeo Indexed{" "}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        sx={{
                                            display: "inline",
                                        }}
                                    >
                                        Dataset
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    sx={{
                                        textAlign: "center",
                                        lineHeight: "1rem",
                                    }}
                                >
                                    <Typography
                                        variant="body3"
                                        sx={{
                                            display: "inline",
                                        }}
                                    >
                                        A dataset access key unlocks a dataset
                                        access to your account for use within
                                        Datascape. Access keys are provided by
                                        BigGeo{" "}
                                    </Typography>
                                    <Link
                                        to={`${Routes.bigGeoProductsDatalab}`}
                                        target="_blank"
                                    >
                                        <Typography
                                            variant="body3"
                                            color="primary"
                                            sx={{
                                                display: "inline",
                                            }}
                                        >
                                            data provider partners.
                                        </Typography>
                                    </Link>
                                </Grid>

                                <TextField
                                    value={values.apiKey}
                                    placeholder="BG12-CD34-EF56-GH78-IJ90-KL12-MN34-OP56"
                                    helperText={
                                        values.apiKey && response?.message
                                    }
                                    sx={{
                                        [`& .${textInputBaseClasses["input-root"]}`]:
                                            {
                                                paddingX: 4,
                                                paddingY:
                                                    response?.status ===
                                                    VerifiedAccessKeyStatus.Success
                                                        ? 4
                                                        : 2,
                                                alignItems: "center",
                                            },
                                        [`& .${textInputBaseClasses.input}`]: {
                                            minHeight: "23px",
                                            maxHeight: "23px",
                                            textTransform: "uppercase",
                                        },
                                    }}
                                    slots={{
                                        helperText: (props) => (
                                            <Grid
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                                gap={1}
                                            >
                                                {response?.status ===
                                                VerifiedAccessKeyStatus.Failed ? (
                                                    <CancelFilled
                                                        size="xxs"
                                                        color={"error"}
                                                    />
                                                ) : (
                                                    <CheckCircle
                                                        size="xxs"
                                                        color={"success"}
                                                    />
                                                )}

                                                <Typography
                                                    {...props}
                                                    variant="body4"
                                                    sx={{
                                                        color: (theme) =>
                                                            theme.palette
                                                                .disabled
                                                                .onContainer,
                                                    }}
                                                />
                                            </Grid>
                                        ),
                                    }}
                                    fullWidth
                                    onChange={(_, v) => {
                                        setResponse(undefined);
                                        setFieldValue("apiKey", v);
                                    }}
                                    endNode={
                                        <Grid container gap={2} height="100%">
                                            <Divider
                                                orientation="vertical"
                                                color={300}
                                            />
                                            {response?.message ? (
                                                <Button
                                                    variant="ghost"
                                                    color="primary"
                                                    type="reset"
                                                    onClick={() => {
                                                        resetForm();
                                                    }}
                                                >
                                                    Try New Key
                                                </Button>
                                            ) : (
                                                <SubmittingButton
                                                    type="checking"
                                                    _type="button"
                                                    loading={checkApiKeyLoading}
                                                    variant="ghost"
                                                    color="primary"
                                                    onClick={() =>
                                                        handleSubmit()
                                                    }
                                                    disabled={
                                                        !isValid || !dirty
                                                    }
                                                >
                                                    Check Key
                                                </SubmittingButton>
                                            )}
                                        </Grid>
                                    }
                                />
                            </Stack>

                            <Grid
                                container
                                gap={2}
                                alignItems="center"
                                justifyContent="space-between"
                                flexWrap="wrap-reverse"
                            >
                                <BorderedTabsWrapper>
                                    <TabGroup
                                        variant="filled"
                                        square
                                        value={tab}
                                    >
                                        <Tab
                                            value="access-keys"
                                            sx={{ flexShrink: 0 }}
                                            onClick={() => {
                                                setTab("access-keys");
                                                filterSearchPaginateProps.resetAll();
                                            }}
                                        >
                                            Access Keys
                                        </Tab>
                                        <Tab
                                            value="active-datasets"
                                            sx={{ flexShrink: 0 }}
                                            onClick={() => {
                                                setTab("active-datasets");
                                                filterSearchPaginateProps.resetAll();
                                            }}
                                        >
                                            Active Datasets
                                        </Tab>
                                    </TabGroup>
                                </BorderedTabsWrapper>
                                {/* TODO: Explore Marketplace Button - Implement the explore marketplace button functionality */}
                                <Button
                                    startNode={<ActionKeyOutline size="xs" />}
                                    variant="outlined"
                                    density="dense"
                                >
                                    Explore Marketplace
                                </Button>
                            </Grid>
                        </Stack>
                        {tab === "access-keys" && (
                            <AccessKeysProvider
                                onRefetch={{ isRefetch, setIsRefetch }}
                                dataGridFetchInputProps={
                                    dataGridFetchInputProps
                                }
                            >
                                {({ accessKeys, loading, total }) => {
                                    return (accessKeys &&
                                        accessKeys.length > 0) ||
                                        loading ||
                                        dataGridFetchInputProps.searchText ||
                                        !isEmpty(
                                            dataGridFetchInputProps.filterObject
                                                ?.filters
                                        ) ? (
                                        <Stack
                                            flexGrow={1}
                                            minHeight={0}
                                            sx={{
                                                marginTop: -4,
                                                backgroundColor: (theme) =>
                                                    theme.palette.background
                                                        .container,
                                            }}
                                        >
                                            <AccessKeysTable
                                                accessKeys={accessKeys}
                                                loading={loading}
                                                filterSearchPaginateProps={
                                                    filterSearchPaginateProps
                                                }
                                                rowCount={total}
                                            />
                                        </Stack>
                                    ) : (
                                        <Stack
                                            flexGrow={1}
                                            sx={{
                                                border: 1,
                                                borderRadius: (theme) =>
                                                    theme.radius.default,
                                                borderColor: (theme) =>
                                                    theme.palette.stroke[100],
                                                marginTop: -4,
                                                backgroundColor: (theme) =>
                                                    theme.palette.background
                                                        .container,
                                            }}
                                        >
                                            <EmptyState
                                                title="No Access Key Added"
                                                icon={<KeyOutline />}
                                                subtitle="When you download datasets with an access key, they will appear here."
                                                buttonText="Learn More"
                                                border={false}
                                                sx={{
                                                    //Safe Centering
                                                    margin: "auto",
                                                }}
                                                onClick={() =>
                                                    openNewTab(
                                                        "https://datascape.featurebase.app/help/articles/5156192-dataset-access-key"
                                                    )
                                                }
                                            />
                                        </Stack>
                                    );
                                }}
                            </AccessKeysProvider>
                        )}
                        {tab === "active-datasets" && (
                            <ActiveDatasetsProvider
                                dataGridFetchInputProps={
                                    dataGridFetchInputProps
                                }
                                onRefetch={{ isRefetch, setIsRefetch }}
                            >
                                {({ activeDatasets, total, loading }) =>
                                    (activeDatasets &&
                                        activeDatasets.length > 0) ||
                                    loading ||
                                    dataGridFetchInputProps.searchText ||
                                    !isEmpty(
                                        dataGridFetchInputProps.filterObject
                                            ?.filters
                                    ) ? (
                                        <Stack
                                            flexGrow={1}
                                            minHeight={0}
                                            sx={{
                                                marginTop: -4,
                                                backgroundColor: (theme) =>
                                                    theme.palette.background
                                                        .container,
                                            }}
                                        >
                                            <ActiveDatasetsTable
                                                activeDatasets={activeDatasets}
                                                loading={loading}
                                                navigate={navigate}
                                                filterSearchPaginateProps={
                                                    filterSearchPaginateProps
                                                }
                                                rowCount={total}
                                            />
                                        </Stack>
                                    ) : (
                                        <Stack
                                            flexGrow={1}
                                            sx={{
                                                border: 1,
                                                borderRadius: (theme) =>
                                                    theme.radius.default,
                                                borderColor: (theme) =>
                                                    theme.palette.stroke[100],
                                                marginTop: -4,
                                                backgroundColor: (theme) =>
                                                    theme.palette.background
                                                        .container,
                                            }}
                                        >
                                            <EmptyState
                                                title="No Access Key Added"
                                                icon={<KeyOutline />}
                                                subtitle="When you download datasets with an access key, they will appear here."
                                                buttonText="Learn More"
                                                border={false}
                                                sx={{
                                                    //Safe Centering
                                                    margin: "auto",
                                                }}
                                                onClick={() =>
                                                    openNewTab(
                                                        "https://datascape.featurebase.app/help/articles/5156192-dataset-access-key"
                                                    )
                                                }
                                            />
                                        </Stack>
                                    )
                                }
                            </ActiveDatasetsProvider>
                        )}
                    </Stack>
                );
            }}
        </Formik>
    );
};
