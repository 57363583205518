import {
    DatabaseType,
    DatasetStyleType,
    DatasetStyles,
    LevelSet,
    ShapeStyle,
    UpdateMapTemplateDataset,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import flatten from "lodash/flatten";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import mapValues from "lodash/mapValues";
import size from "lodash/size";
import some from "lodash/some";
import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import { useState } from "react";
import { Dispatch } from "redux";
import { match } from "ts-pattern";
import { ColorSwatchOption } from "../../../common/components/ColorSwatchSelector";
import { commonActions } from "../../../common/redux/model";
import { MapColorSetterType } from "../../../common/types/color-picker";
import { getPreviousAndNextElements } from "../../../common/utils/helpers";
import {
    createSquareIcon,
    generateHeatmapFromColor,
    getDatasetShapeColor,
    getHeatmapFormattedValue,
    getLayerHeatmapStyle,
} from "../../utils/style-utils";
import { MapAction, MapContextDataset } from "../context";
import { DEFAULT_SHAPE_COLOR } from "../hooks/style-hooks";

// ████████╗██╗   ██╗██████╗ ███████╗███████╗
// ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝
//    ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗
//    ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║
//    ██║      ██║   ██║     ███████╗███████║
//    ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝

export enum DatasetFillLayer {
    aggregate = "aggregate",
    polygons = "polygons",
    lines = "lines",
    levelSets = "levelSets",
}

export type DatasetFillLayerType<T> = T extends DatasetFillLayer.levelSets
    ? mapboxgl.AnyLayer[]
    : mapboxgl.AnyLayer;

export type DatasetStyleCategory = Exclude<
    DatasetStyleType,
    DatasetStyleType.stroke | DatasetStyleType.dataAggregation
>;

export type DatasetSources = {
    aggregate: string;
    points: string;
    polygons: string;
    levelSets: string[];
};

export type DatasetSourcesData = {
    aggregate: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[];
    points: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[];
    polygons: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[];
    levelSets: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[][];
};

// ██╗  ██╗███████╗██╗     ██████╗ ███████╗██████╗ ███████╗
// ██║  ██║██╔════╝██║     ██╔══██╗██╔════╝██╔══██╗██╔════╝
// ███████║█████╗  ██║     ██████╔╝█████╗  ██████╔╝███████╗
// ██╔══██║██╔══╝  ██║     ██╔═══╝ ██╔══╝  ██╔══██╗╚════██║
// ██║  ██║███████╗███████╗██║     ███████╗██║  ██║███████║
// ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝╚══════╝

export const getDatasetSources = (input: {
    prefix: string;
    levelSets: LevelSet[] | number[];
    map: mapboxgl.Map;
    isLoaded: boolean;
}): DatasetSources => {
    const { prefix, levelSets, map, isLoaded } = input;

    const style = map && isLoaded ? map.getStyle() : undefined;

    const levelSetsSize =
        isEmpty(levelSets) && style
            ? pipe(
                  style.layers,
                  A.filter((l) => l.id.includes(`${prefix}-levelset`)),
                  size
              )
            : levelSets.length;

    return {
        aggregate: `${prefix}-aggregate`,
        points: `${prefix}-points`,
        polygons: `${prefix}-polygons`,
        levelSets: Array.from(
            { length: levelSetsSize },
            (_, index) => `${prefix}-levelset-${index}`
        ),
    };
};

export const isShapeStyleLayer = (
    value:
        | mapboxgl.AnyLayer
        | Record<ShapeStyle, mapboxgl.AnyLayer>
        | { [K in DatasetFillLayer]: DatasetFillLayerType<K> }
): value is Record<ShapeStyle, mapboxgl.AnyLayer> =>
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    value.hasOwnProperty(ShapeStyle.square) ||
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    value.hasOwnProperty(ShapeStyle.oval);

export const isShapeFillLayer = (
    value:
        | mapboxgl.AnyLayer
        | Record<ShapeStyle, mapboxgl.AnyLayer>
        | { [K in DatasetFillLayer]: DatasetFillLayerType<K> }
): value is { [K in DatasetFillLayer]: DatasetFillLayerType<K> } =>
    some(Object.keys(value), (v: DatasetFillLayer) =>
        Object.values(DatasetFillLayer).includes(v)
    );

export const isShapeAnyLayer = (
    value:
        | mapboxgl.AnyLayer
        | Record<ShapeStyle, mapboxgl.AnyLayer>
        | { [K in DatasetFillLayer]: DatasetFillLayerType<K> }
): value is mapboxgl.AnyLayer =>
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    value.hasOwnProperty("id") ||
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    value.hasOwnProperty("source");

export const getDatasetStyles = (dataset: MapContextDataset): DatasetStyles => {
    const styles = dataset.mapTemplateDataset?.styles || undefined;
    const shape = styles?.shape;
    const customMarker = styles?.customMarker;
    const { fill, stroke } = getDatasetShapeColor(dataset);

    return {
        shape: shape ? shape : customMarker ? null : ShapeStyle.oval,
        fill,
        stroke,
        customMarker: styles?.customMarker,
        dataAggregation: {
            heatmap: styles?.dataAggregation?.heatmap,
        },
    };
};

export const isLayerPresent = (i: {
    layerId: string;
    map: mapboxgl.Map;
    isLoaded: boolean;
}): boolean => {
    const { layerId, map, isLoaded } = i;

    if (isLoaded) {
        const layer = map.getLayer(layerId);

        return !!layer;
    }

    return false;
};

// ███╗   ███╗ █████╗ ██╗███╗   ██╗
// ████╗ ████║██╔══██╗██║████╗  ██║
// ██╔████╔██║███████║██║██╔██╗ ██║
// ██║╚██╔╝██║██╔══██║██║██║╚██╗██║
// ██║ ╚═╝ ██║██║  ██║██║██║ ╚████║
// ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝

export const getDatasetLayers = (input: {
    prefix: string;
    sources: DatasetSources;
    styles?: DatasetStyles;
    map?: mapboxgl.Map;
    isLoaded?: boolean;
    data?: Partial<{
        isPreview: boolean;
        isMipmapped: boolean;
        type: DatabaseType;
    }>;
}): Record<
    DatasetStyleCategory,
    | mapboxgl.AnyLayer
    | Record<ShapeStyle, mapboxgl.AnyLayer>
    | { [K in DatasetFillLayer]: DatasetFillLayerType<K> }
> => {
    const { prefix, sources, styles, data, map, isLoaded } = input;

    const color = styles?.fill?.color || DEFAULT_SHAPE_COLOR;
    const opacity = styles?.fill?.opacity || 0.9;
    const customMarker = styles?.customMarker || "airport";
    const shape = styles?.shape;

    const heatmap = getHeatmapFormattedValue(
        styles?.dataAggregation?.heatmap || undefined
    );

    const { heatMapColorArray, colorMap } = getLayerHeatmapStyle(
        heatmap,
        color
    );

    if (shape === ShapeStyle.square && map && isLoaded) {
        const image = map.hasImage(`${prefix}-square-icon`);
        const iconStyle = {
            color: {
                color: styles?.fill?.color || undefined,
                opacity: styles?.fill?.opacity || undefined,
            },
            stroke: {
                color:
                    styles?.stroke?.color || styles?.fill?.color || undefined,
                opacity:
                    styles?.stroke?.opacity ||
                    styles?.fill?.opacity ||
                    undefined,
            },
        };

        if (image) {
            map.updateImage(
                `${prefix}-square-icon`,
                createSquareIcon({
                    ...iconStyle,
                })
            );
        } else {
            map.addImage(
                `${prefix}-square-icon`,
                createSquareIcon({
                    ...iconStyle,
                }),
                { pixelRatio: 4 }
            );
        }
    }

    return {
        [DatasetStyleType.shape]: {
            [ShapeStyle.square]: {
                id: `${prefix}-points-icon-square`,
                type: "symbol",
                source: sources.points,
                minzoom: 11,
                layout: {
                    "icon-image": `${prefix}-square-icon`,
                    "icon-size": 0.7,
                    visibility: isEqual(shape, ShapeStyle.square)
                        ? "visible"
                        : "none",
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true,
                },
                paint: {
                    "icon-opacity": {
                        default: opacity,
                        stops: [
                            [11, 0],
                            [13, opacity],
                        ],
                    },
                },
            },
            [ShapeStyle.oval]: {
                id: `${prefix}-points-icon-oval`,
                type: "circle",
                source: sources.points,
                minzoom: 11,
                layout: {
                    visibility: isEqual(shape, ShapeStyle.oval)
                        ? "visible"
                        : "none",
                },
                paint: {
                    "circle-radius": 4,
                    "circle-color": color,
                    "circle-stroke-color": `${styles?.stroke?.color || color}`,
                    "circle-stroke-width": 2,
                    "circle-stroke-opacity": {
                        default: styles?.stroke?.opacity || opacity,
                        stops: [
                            [11, 0],
                            [13, styles?.stroke?.opacity || opacity],
                        ],
                    },
                    "circle-opacity": {
                        default: opacity,
                        stops: [
                            [11, 0],
                            [13, opacity],
                        ],
                    },
                },
            },
        },
        [DatasetStyleType.fill]: {
            [DatasetFillLayer.aggregate]: {
                id: `${prefix}-aggregate`,
                type: "heatmap",
                source: sources.aggregate,
                paint: {
                    "heatmap-weight": [
                        "interpolate",
                        ["cubic-bezier", 0.1, 0.7, 0.9, 0.3],
                        ["get", "count"],
                        0,
                        0,
                        3000,
                        20,
                    ],
                    "heatmap-intensity": {
                        stops: [
                            [8, 1],
                            [11, 3],
                        ],
                    },
                    "heatmap-radius": 15,
                    "heatmap-color": [
                        "interpolate",
                        ["linear"],
                        ["heatmap-density"],
                        ...heatMapColorArray,
                    ],
                    "heatmap-opacity": {
                        default: opacity,
                        stops: [
                            [11, opacity],
                            [13, 0],
                        ],
                    },
                },
            },
            [DatasetFillLayer.polygons]: {
                id: `${prefix}-polygons`,
                type: "fill",
                source: sources.polygons,
                paint: {
                    "fill-color": [
                        "match",
                        ["get", "idCadence"],
                        ...colorMap,
                        color,
                    ],
                    "fill-opacity": [
                        "match",
                        ["get", "line"],
                        "true",
                        0,
                        opacity,
                    ],
                    "fill-antialias": false,
                },
            },
            [DatasetFillLayer.lines]: {
                id: `${prefix}-lines`,
                type: "line",
                source: sources.polygons,
                paint: {
                    "line-color": [
                        "match",
                        ["get", "line"],
                        "true",
                        color,
                        "black",
                    ],
                    "line-width": 3,
                    "line-opacity": opacity,
                },
            },
            [DatasetFillLayer.levelSets]: pipe(
                sources.levelSets,
                A.mapWithIndex((index, source) => ({
                    id: `${prefix}-levelset-${index}`,
                    type: "fill",
                    source,
                    paint: {
                        "fill-color": [
                            "match",
                            ["get", "idCadence"],
                            ...colorMap,
                            color,
                        ],
                        "fill-opacity": [
                            "match",
                            ["get", "line"],
                            "true",
                            0,
                            data?.isMipmapped
                                ? 0.2
                                : data?.type === DatabaseType.polygon
                                  ? 0.3
                                  : 0.15,
                        ],
                        "fill-antialias": false,
                    },
                }))
            ),
        },
        [DatasetStyleType.customMarker]: {
            id: `${prefix}-custom-marker`,
            type: "symbol",
            source: sources.points,
            layout: {
                "icon-image": customMarker,
                "icon-size": 1,
                visibility: isNil(shape) && customMarker ? "visible" : "none",
            },
            paint: {
                "icon-opacity": {
                    default: opacity,
                    stops: [
                        [11, 0],
                        [13, opacity],
                    ],
                },
            },
        },
    };
};

export const getDatasetLayerIds = (input: {
    prefix: string;
    map: mapboxgl.Map;
    isLoaded: boolean;
}): string[] => {
    const { prefix, map, isLoaded } = input;

    const layers = getDatasetLayers({
        prefix,
        sources: getDatasetSources({
            prefix,
            map,
            isLoaded,
            levelSets: [],
        }),
    });

    return pipe(
        mapValues(layers, (value) => {
            if (isShapeStyleLayer(value)) {
                return [value.oval.id, value.square.id];
            }

            if (isShapeFillLayer(value)) {
                return flatten([
                    value.aggregate.id,
                    value.polygons.id,
                    value.lines.id,
                    value.levelSets.map((l) => l.id),
                ]);
            }

            return [value.id];
        }),
        Object.values,
        flatten
    );
};

export const setDatasetVisibility = (input: {
    map: mapboxgl.Map;
    prefix: string;
    levelSets: LevelSet[];
    visibility: "visible" | "none";
    isLoaded: boolean;
    points: { shape?: ShapeStyle; customMarker?: string };
}) => {
    const { map, prefix, levelSets, visibility, isLoaded, points } = input;

    if (isLoaded) {
        const layers = getDatasetLayers({
            prefix,
            sources: getDatasetSources({
                prefix,
                map,
                isLoaded,
                levelSets,
            }),
        });

        mapValues(layers, (value) => {
            if (isShapeStyleLayer(value)) {
                if (isEqual(visibility, "visible")) {
                    if (points.shape && !points.customMarker) {
                        map.setLayoutProperty(
                            value[points.shape].id,
                            "visibility",
                            visibility
                        );
                    }
                    if (!points.shape && !points.customMarker) {
                        map.setLayoutProperty(
                            value.oval.id,
                            "visibility",
                            visibility
                        );
                    }
                } else {
                    map.setLayoutProperty(
                        value.square.id,
                        "visibility",
                        visibility
                    );
                    map.setLayoutProperty(
                        value.oval.id,
                        "visibility",
                        visibility
                    );
                }
            } else if (isShapeFillLayer(value)) {
                map.setLayoutProperty(
                    value.aggregate.id,
                    "visibility",
                    visibility
                );
                map.setLayoutProperty(
                    value.polygons.id,
                    "visibility",
                    visibility
                );
                map.setLayoutProperty(value.lines.id, "visibility", visibility);

                mapValues(value.levelSets, (v) =>
                    map.setLayoutProperty(v.id, "visibility", visibility)
                );
            } else {
                if (isEqual(visibility, "visible")) {
                    if (
                        points.customMarker &&
                        value.id.includes("custom-marker")
                    ) {
                        map.setLayoutProperty(
                            value.id,
                            "visibility",
                            visibility
                        );
                    }
                } else {
                    map.setLayoutProperty(value.id, "visibility", visibility);
                }
            }
        });
    }
};

const handleLayerRenderingTime = ({
    glContext,
    datasetId,
    map,
    dispatch,
}: {
    glContext: WebGLRenderingContext | WebGL2RenderingContext | null;
    datasetId: string;
    map: mapboxgl.Map;
    dispatch?: Dispatch;
}) => {
    if (glContext && dispatch) {
        computeWebGLRenderingTime({
            context: glContext,
            datasetId,
            map,
            dispatch,
        });
    } else {
        console.warn("WebGL context not available.");
    }
};

export const handleDatasetLayers = (input: {
    action: "add" | "remove";
    map: mapboxgl.Map;
    isLoaded: boolean;
    prefix: string;
    hierarchy?: string[];
    levelSets?: LevelSet[];
    sources?: DatasetSources;
    sourcesData?: DatasetSourcesData;
    styles?: DatasetStyles;
    data?: Partial<{
        isPreview: boolean;
        isMipmapped: boolean;
        type: DatabaseType;
        showLevelSets: boolean;
        showPoints: boolean;
    }>;
    dispatch?: Dispatch;
}) => {
    const {
        action,
        map,
        isLoaded,
        prefix,
        levelSets,
        sources: _sources,
        sourcesData,
        styles,
        data,
        hierarchy,
    } = input;

    if (isLoaded) {
        const sources =
            _sources ??
            getDatasetSources({
                prefix,
                map,
                isLoaded,
                levelSets: levelSets || [],
            });

        const layers = getDatasetLayers({
            prefix,
            sources,
            styles,
            data,
            map,
            isLoaded,
        });

        const glContext: WebGLRenderingContext | WebGL2RenderingContext | null =
            map.getCanvas().getContext("webgl") ||
            map.getCanvas().getContext("webgl2");

        if (isEqual(action, "add")) {
            const shape = styles?.shape;
            const { previousElement } = getPreviousAndNextElements(
                prefix,
                hierarchy
            );
            const showLevelSets = data?.showLevelSets ?? true;
            const showPoints = data?.showPoints ?? true;

            const isDatasetLayerPresent = (layerId: string): boolean =>
                isLayerPresent({
                    layerId,
                    map,
                    isLoaded,
                });

            if (sourcesData) {
                mapValues(
                    sources,
                    (
                        source: string | string[],
                        key: "aggregate" | "polygons" | "points" | "levelSets"
                    ) => {
                        if (isArray(source)) {
                            pipe(
                                source,
                                A.mapWithIndex((index, s) => {
                                    const geojsonSource = map.getSource(
                                        s
                                    ) as GeoJSONSource;

                                    if (geojsonSource) {
                                        geojsonSource.setData({
                                            type: "FeatureCollection",
                                            features:
                                                sourcesData.levelSets[index],
                                        });
                                    } else {
                                        map.addSource(s, {
                                            type: "geojson",
                                            data: {
                                                type: "FeatureCollection",
                                                features:
                                                    sourcesData.levelSets[
                                                        index
                                                    ],
                                            },
                                        });
                                    }
                                })
                            );
                        } else {
                            const geojsonSource = map.getSource(
                                source
                            ) as GeoJSONSource;

                            const features =
                                key !== "levelSets"
                                    ? match<
                                          "aggregate" | "polygons" | "points",
                                          GeoJSON.Feature<
                                              GeoJSON.Geometry,
                                              GeoJSON.GeoJsonProperties
                                          >[]
                                      >(key)
                                          .with(
                                              "aggregate",
                                              () => sourcesData.aggregate
                                          )
                                          .with(
                                              "polygons",
                                              () => sourcesData.polygons
                                          )
                                          .with(
                                              "points",
                                              () => sourcesData.points
                                          )
                                          .exhaustive()
                                    : [];

                            if (geojsonSource) {
                                geojsonSource.setData({
                                    type: "FeatureCollection",
                                    features,
                                });
                            } else {
                                map.addSource(source, {
                                    type: "geojson",
                                    data: {
                                        type: "FeatureCollection",
                                        features,
                                    },
                                });
                            }
                        }
                    }
                );
            }

            mapValues(layers, (value) => {
                if (isShapeStyleLayer(value)) {
                    if (shape === ShapeStyle.square) {
                        if (
                            !isDatasetLayerPresent(value.square.id) &&
                            isEqual(showPoints, true)
                        ) {
                            map.addLayer(value.square);
                        } else {
                            if (
                                isEqual(styles?.shape, ShapeStyle.square) &&
                                !styles?.customMarker
                            ) {
                                map.setLayoutProperty(
                                    value.square.id,
                                    "visibility",
                                    isEqual(showPoints, true)
                                        ? "visible"
                                        : "none"
                                );
                            }
                        }

                        if (previousElement) {
                            map.moveLayer(
                                value.square.id.replace(prefix, previousElement)
                            );
                        }
                    } else {
                        if (
                            !isDatasetLayerPresent(value.oval.id) &&
                            isEqual(showPoints, true)
                        ) {
                            map.addLayer(value.oval);
                            handleLayerRenderingTime({
                                glContext,
                                datasetId: prefix,
                                map,
                                dispatch: input.dispatch,
                            });
                        } else {
                            if (
                                isEqual(styles?.shape, ShapeStyle.oval) &&
                                !styles?.customMarker
                            ) {
                                map.setLayoutProperty(
                                    value.oval.id,
                                    "visibility",
                                    isEqual(showPoints, true)
                                        ? "visible"
                                        : "none"
                                );
                            }
                        }

                        if (previousElement) {
                            map.moveLayer(
                                value.oval.id.replace(prefix, previousElement)
                            );
                        }
                    }
                } else if (isShapeFillLayer(value)) {
                    if (!isDatasetLayerPresent(value.aggregate.id)) {
                        map.addLayer(value.aggregate);
                        handleLayerRenderingTime({
                            glContext,
                            datasetId: prefix,
                            map,
                            dispatch: input.dispatch,
                        });
                    } else {
                        map.setLayoutProperty(
                            value.aggregate.id,
                            "visibility",
                            "visible"
                        );
                    }

                    if (!isDatasetLayerPresent(value.polygons.id)) {
                        map.addLayer(value.polygons);
                    } else {
                        map.setLayoutProperty(
                            value.polygons.id,
                            "visibility",
                            "visible"
                        );
                    }
                    if (!isDatasetLayerPresent(value.lines.id)) {
                        map.addLayer(value.lines);
                    } else {
                        map.setLayoutProperty(
                            value.lines.id,
                            "visibility",
                            "visible"
                        );
                    }

                    if (previousElement) {
                        map.moveLayer(
                            value.polygons.id.replace(prefix, previousElement)
                        );
                        map.moveLayer(
                            value.aggregate.id.replace(prefix, previousElement)
                        );
                        map.moveLayer(
                            value.lines.id.replace(prefix, previousElement)
                        );
                    }

                    mapValues(value.levelSets, (v) => {
                        if (
                            !isDatasetLayerPresent(v.id) &&
                            isEqual(showLevelSets, true)
                        ) {
                            map.addLayer(v);
                            handleLayerRenderingTime({
                                glContext,
                                datasetId: prefix,
                                map,
                                dispatch: input.dispatch,
                            });
                        } else {
                            map.setLayoutProperty(
                                v.id,
                                "visibility",
                                isEqual(showLevelSets, true)
                                    ? "visible"
                                    : "none"
                            );
                        }

                        if (previousElement) {
                            map.moveLayer(
                                v.id.replace(prefix, previousElement)
                            );
                        }
                    });
                } else {
                    if (!isDatasetLayerPresent(value.id)) {
                        map.addLayer(value);
                        handleLayerRenderingTime({
                            glContext,
                            datasetId: prefix,
                            map,
                            dispatch: input.dispatch,
                        });
                    } else {
                        if (
                            styles?.customMarker &&
                            value.id.includes("custom-marker")
                        ) {
                            map.setLayoutProperty(
                                value.id,
                                "visibility",
                                isEqual(showPoints, true) ? "visible" : "none"
                            );
                        }
                    }

                    if (previousElement) {
                        map.moveLayer(
                            value.id.replace(prefix, previousElement)
                        );
                    }
                }
            });

            // Show the following layers on top of the dataset's layers
            if (isDatasetLayerPresent("gl-map-shapes-fill.cold")) {
                map.moveLayer("gl-map-shapes-fill.cold");
            }

            if (isDatasetLayerPresent("gl-map-shapes-line.cold")) {
                map.moveLayer("gl-map-shapes-line.cold");
            }

            if (isDatasetLayerPresent("gl-map-shapes-fill.hot")) {
                map.moveLayer("gl-map-shapes-fill.hot");
            }

            if (isDatasetLayerPresent("gl-map-shapes-line.hot")) {
                map.moveLayer("gl-map-shapes-line.hot");
            }

            if (isDatasetLayerPresent("settlement-minor-label")) {
                map.moveLayer("settlement-minor-label");
            }

            if (isDatasetLayerPresent("settlement-major-label")) {
                map.moveLayer("settlement-major-label");
            }

            if (isDatasetLayerPresent("state-label")) {
                map.moveLayer("state-label");
            }

            if (isDatasetLayerPresent("country-label")) {
                map.moveLayer("country-label");
            }
        }

        if (isEqual(action, "remove")) {
            mapValues(sources, (source) => {
                if (isArray(source)) {
                    pipe(
                        source,
                        A.map((s) => {
                            const geojsonSource = map.getSource(
                                s
                            ) as GeoJSONSource;

                            if (geojsonSource) {
                                geojsonSource.setData({
                                    type: "FeatureCollection",
                                    features: [],
                                });
                            }
                        })
                    );
                } else {
                    const geojsonSource = map.getSource(
                        source
                    ) as GeoJSONSource;

                    if (geojsonSource) {
                        geojsonSource.setData({
                            type: "FeatureCollection",
                            features: [],
                        });
                    }
                }
            });

            mapValues(layers, (value) => {
                if (isShapeStyleLayer(value)) {
                    const squareLayer = map.getLayer(value.square.id);
                    const ovalLayer = map.getLayer(value.oval.id);

                    if (squareLayer) {
                        map.removeLayer(value.square.id);
                    }

                    if (ovalLayer) {
                        map.removeLayer(value.oval.id);
                    }
                } else if (isShapeFillLayer(value)) {
                    const aggregateLayer = map.getLayer(value.aggregate.id);
                    const polygonsLayer = map.getLayer(value.polygons.id);
                    const linesLayer = map.getLayer(value.lines.id);

                    if (aggregateLayer) {
                        map.removeLayer(value.aggregate.id);
                    }

                    if (polygonsLayer) {
                        map.removeLayer(value.polygons.id);
                    }

                    if (linesLayer) {
                        map.removeLayer(value.lines.id);
                    }

                    mapValues(value.levelSets, (v) => {
                        const levelSetLayer = map.getLayer(v.id);

                        if (levelSetLayer) {
                            map.removeLayer(v.id);
                        }
                    });
                } else {
                    const otherLayer = map.getLayer(value.id);

                    if (otherLayer) {
                        map.removeLayer(value.id);
                    }
                }
            });
        }
    }
};

export const setDatasetLayersByCategory = (input: {
    category: DatasetStyleType;
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    prefix: string;
    styles: DatasetStyles;
    levelSets?: LevelSet[];
}) => {
    const { category, map, isLoaded, prefix, styles, levelSets } = input;
    const shape = styles.shape;

    if (map.current && isLoaded) {
        const layers = getDatasetLayers({
            prefix,
            sources: getDatasetSources({
                prefix,
                map: map.current,
                isLoaded,
                levelSets: levelSets || [],
            }),
            styles,
        });

        const shapeLayers = layers[DatasetStyleType.shape];
        const customMarkerLayer = layers[DatasetStyleType.customMarker];
        const aggregateLayers = layers[DatasetStyleType.fill];

        const iconStyle = {
            color: {
                color: styles.fill?.color || undefined,
                opacity: styles.fill?.opacity || undefined,
            },
            stroke: {
                color: styles.stroke?.color || styles.fill?.color || undefined,
                opacity:
                    styles.stroke?.opacity || styles.fill?.opacity || undefined,
            },
        };

        const isDatasetLayerPresent = (layerId: string): boolean => {
            return map.current
                ? isLayerPresent({
                      layerId,
                      map: map.current,
                      isLoaded,
                  })
                : false;
        };

        match(category)
            .with(DatasetStyleType.shape, () => {
                if (isEqual(shape, ShapeStyle.square)) {
                    if (isShapeStyleLayer(shapeLayers)) {
                        map.current?.setLayoutProperty(
                            shapeLayers.square.id,
                            "visibility",
                            "visible"
                        );

                        if (isDatasetLayerPresent(shapeLayers.oval.id)) {
                            map.current?.setLayoutProperty(
                                shapeLayers.oval.id,
                                "visibility",
                                "none"
                            );
                        }
                    }

                    if (
                        isShapeAnyLayer(customMarkerLayer) &&
                        isDatasetLayerPresent(customMarkerLayer.id)
                    ) {
                        map.current?.setLayoutProperty(
                            customMarkerLayer.id,
                            "visibility",
                            "none"
                        );
                    }

                    const image = map.current?.hasImage(
                        `${prefix}-square-icon`
                    );

                    if (image) {
                        map.current?.updateImage(
                            `${prefix}-square-icon`,
                            createSquareIcon({
                                ...iconStyle,
                            })
                        );
                    } else {
                        map.current?.addImage(
                            `${prefix}-square-icon`,
                            createSquareIcon({
                                ...iconStyle,
                            }),
                            { pixelRatio: 4 }
                        );
                    }
                }

                if (isEqual(shape, ShapeStyle.oval)) {
                    const stroke = styles.stroke;

                    if (
                        isShapeAnyLayer(customMarkerLayer) &&
                        isDatasetLayerPresent(customMarkerLayer.id)
                    ) {
                        map.current?.setLayoutProperty(
                            customMarkerLayer.id,
                            "visibility",
                            "none"
                        );
                    }

                    if (isShapeStyleLayer(shapeLayers)) {
                        map.current?.setLayoutProperty(
                            shapeLayers.oval.id,
                            "visibility",
                            "visible"
                        );

                        if (isDatasetLayerPresent(shapeLayers.square.id)) {
                            map.current?.setLayoutProperty(
                                shapeLayers.square.id,
                                "visibility",
                                "none"
                            );
                        }

                        map.current?.setPaintProperty(
                            shapeLayers.oval.id,
                            "circle-color",
                            `${styles.fill?.color}`
                        );

                        if (stroke) {
                            map.current?.setPaintProperty(
                                shapeLayers.oval.id,
                                "circle-stroke-color",
                                `${stroke.color}`
                            );
                            map.current?.setPaintProperty(
                                shapeLayers.oval.id,
                                "circle-stroke-opacity",
                                {
                                    default: stroke.opacity || 0.9,
                                    stops: [
                                        [11, 0],
                                        [13, stroke.opacity || 0.9],
                                    ],
                                }
                            );
                            map.current?.setPaintProperty(
                                shapeLayers.oval.id,
                                "circle-opacity",
                                {
                                    default: stroke.opacity || 0.9,
                                    stops: [
                                        [11, 0],
                                        [13, stroke.opacity || 0.9],
                                    ],
                                }
                            );
                        }
                    }
                }
            })
            .with(DatasetStyleType.fill, () => {
                if (styles.fill) {
                    map.current?.updateImage(
                        `${prefix}-square-icon`,
                        createSquareIcon({
                            ...iconStyle,
                        })
                    );

                    if (
                        isShapeStyleLayer(shapeLayers) &&
                        isDatasetLayerPresent(shapeLayers.oval.id)
                    ) {
                        map.current?.setPaintProperty(
                            shapeLayers.oval.id,
                            "circle-color",
                            styles.fill.color
                        );

                        map.current?.setPaintProperty(
                            shapeLayers.oval.id,
                            "circle-opacity",
                            styles.fill.opacity
                        );
                    }

                    if (
                        isShapeAnyLayer(customMarkerLayer) &&
                        isDatasetLayerPresent(customMarkerLayer.id)
                    ) {
                        map.current?.setPaintProperty(
                            customMarkerLayer.id,
                            "icon-color",
                            styles.fill.color
                        );
                    }

                    if (
                        isShapeFillLayer(aggregateLayers) &&
                        styles.fill.color
                    ) {
                        const { heatMapColorArray, colorMap } =
                            getLayerHeatmapStyle(undefined, styles.fill.color);

                        map.current?.setLayoutProperty(
                            aggregateLayers.aggregate.id,
                            "visibility",
                            "visible"
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.aggregate.id,
                            "heatmap-color",
                            [
                                "interpolate",
                                ["linear"],
                                ["heatmap-density"],
                                ...heatMapColorArray,
                            ]
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.aggregate.id,
                            "heatmap-opacity",
                            {
                                default: styles.fill.opacity,
                                stops: [
                                    [11, styles.fill.opacity],
                                    [13, 0],
                                ],
                            }
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.polygons.id,
                            "fill-color",
                            [
                                "match",
                                ["get", "idCadence"],
                                ...colorMap,
                                styles.fill.color,
                            ]
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.polygons.id,
                            "fill-opacity",
                            [
                                "match",
                                ["get", "line"],
                                "true",
                                0,
                                styles.fill.opacity,
                            ]
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.lines.id,
                            "line-opacity",
                            styles.fill.opacity
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.lines.id,
                            "line-color",
                            [
                                "match",
                                ["get", "line"],
                                "true",
                                styles.fill.color,
                                "black",
                            ]
                        );

                        mapValues(aggregateLayers.levelSets, (levelSetLayer) =>
                            map.current?.setPaintProperty(
                                levelSetLayer.id,
                                "fill-color",
                                [
                                    "match",
                                    ["get", "idCadence"],
                                    ...colorMap,
                                    styles.fill?.color,
                                ]
                            )
                        );
                    }
                }
            })
            .with(DatasetStyleType.stroke, () => {
                if (styles.stroke) {
                    map.current?.updateImage(
                        `${prefix}-square-icon`,
                        createSquareIcon({
                            ...iconStyle,
                        })
                    );

                    if (
                        isShapeStyleLayer(shapeLayers) &&
                        isDatasetLayerPresent(shapeLayers.oval.id)
                    ) {
                        map.current?.setPaintProperty(
                            shapeLayers.oval.id,
                            "circle-stroke-color",
                            styles.stroke.color
                        );
                    }
                }
            })
            .with(DatasetStyleType.customMarker, () => {
                if (styles.customMarker) {
                    if (isShapeAnyLayer(customMarkerLayer)) {
                        map.current?.setLayoutProperty(
                            customMarkerLayer.id,
                            "visibility",
                            "visible"
                        );

                        map.current?.setLayoutProperty(
                            customMarkerLayer.id,
                            "icon-image",
                            styles.customMarker
                        );
                    }

                    if (isShapeStyleLayer(shapeLayers)) {
                        if (isDatasetLayerPresent(shapeLayers.oval.id)) {
                            map.current?.setLayoutProperty(
                                shapeLayers.oval.id,
                                "visibility",
                                "none"
                            );
                        }

                        if (isDatasetLayerPresent(shapeLayers.square.id)) {
                            map.current?.setLayoutProperty(
                                shapeLayers.square.id,
                                "visibility",
                                "none"
                            );
                        }
                    }
                }
            })
            .with(DatasetStyleType.dataAggregation, () => {
                if (styles.dataAggregation) {
                    const heatmap = getHeatmapFormattedValue(
                        styles?.dataAggregation?.heatmap || undefined
                    );

                    const { heatMapColorArray, colorMap } =
                        getLayerHeatmapStyle(heatmap);

                    if (isShapeFillLayer(aggregateLayers)) {
                        map.current?.setLayoutProperty(
                            aggregateLayers.aggregate.id,
                            "visibility",
                            "visible"
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.aggregate.id,
                            "heatmap-color",
                            [
                                "interpolate",
                                ["linear"],
                                ["heatmap-density"],
                                ...heatMapColorArray,
                            ]
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.polygons.id,
                            "fill-color",
                            [
                                "match",
                                ["get", "idCadence"],
                                ...colorMap,
                                styles.fill?.color,
                            ]
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.lines.id,
                            "line-color",
                            [
                                "match",
                                ["get", "line"],
                                "true",
                                styles.fill?.color,
                                "black",
                            ]
                        );

                        mapValues(aggregateLayers.levelSets, (levelSetLayer) =>
                            map.current?.setPaintProperty(
                                levelSetLayer.id,
                                "fill-color",
                                [
                                    "match",
                                    ["get", "idCadence"],
                                    ...colorMap,
                                    styles.fill?.color,
                                ]
                            )
                        );
                    }
                }
            });
    }
};

export const setDatasetLayers = (input: {
    currentDataset?: MapContextDataset;
    dispatch: React.Dispatch<MapAction> | undefined;
    isSavedViewPage: boolean;
    updateMapTemplateDataset: (input: UpdateMapTemplateDataset) => void;
}) => {
    const {
        currentDataset,
        dispatch,
        isSavedViewPage,
        updateMapTemplateDataset,
    } = input;

    const [extraHeatmapOptions, setExtraHeatmapOptions] = useState<
        ColorSwatchOption[]
    >(
        pipe(
            [
                generateHeatmapFromColor(
                    currentDataset?.mapTemplateDataset?.color ||
                        currentDataset?.dataSource.color ||
                        "#ffffff"
                ),
            ],
            compact
        )
    );

    const updateShape = (input: {
        shape: ShapeStyle;
        map: React.MutableRefObject<mapboxgl.Map | null>;
        isLoaded: boolean;
        dataset: MapContextDataset;
    }) => {
        const { shape, map, isLoaded, dataset } = input;
        const dataSourceId = dataset.dataSource.id;
        const mapTemplateDatasetId = dataset.mapTemplateDataset?.id;

        dispatch?.({
            type: "UPDATE_DATASET",
            values: {
                dataSourceId,
                dataset: {
                    mapTemplateDataset: {
                        styles: { shape, customMarker: null },
                    },
                    dataSource: {
                        icon: undefined,
                    },
                },
            },
        });

        setDatasetLayersByCategory({
            category: DatasetStyleType.shape,
            prefix: dataSourceId,
            map,
            isLoaded,
            styles: { ...getDatasetStyles(currentDataset ?? dataset), shape },
        });

        if (mapTemplateDatasetId && !isSavedViewPage) {
            updateMapTemplateDataset({
                id: mapTemplateDatasetId,
                styles: { shape, customMarker: null },
            });
        }
    };

    const updateColor = ({
        color,
        opacity,
        type,
        map,
        isLoaded,
        dataset,
    }: MapColorSetterType & {
        type: "fill" | "stroke";
        map: React.MutableRefObject<mapboxgl.Map | null>;
        isLoaded: boolean;
        dataset: MapContextDataset;
    }) => {
        const dataSourceId = dataset.dataSource.id;
        const mapTemplateDatasetId = dataset.mapTemplateDataset?.id;

        dispatch?.({
            type: "UPDATE_DATASET",
            values: {
                dataSourceId,
                dataset: {
                    mapTemplateDataset: {
                        ...(type === "stroke" && {
                            styles: {
                                stroke: {
                                    color,
                                    opacity,
                                },
                            },
                        }),
                        ...(type === "fill" && {
                            color,
                            styles: {
                                fill: {
                                    color,
                                    opacity,
                                },
                            },
                        }),
                    },
                },
            },
        });

        setDatasetLayersByCategory({
            category:
                type === "fill"
                    ? DatasetStyleType.fill
                    : DatasetStyleType.stroke,
            prefix: dataSourceId,
            map,
            isLoaded,
            styles: {
                ...getDatasetStyles(currentDataset ?? dataset),
                ...(type === "stroke" && {
                    stroke: {
                        color,
                        opacity,
                    },
                }),
                ...(type === "fill" && {
                    fill: { color, opacity },
                }),
            },
        });

        if (type === "stroke" && mapTemplateDatasetId && !isSavedViewPage) {
            updateMapTemplateDataset({
                id: mapTemplateDatasetId,
                styles: {
                    stroke: { color, opacity },
                },
            });
        }

        if (type === "fill" && color) {
            const heatmap = generateHeatmapFromColor(color);

            setExtraHeatmapOptions([heatmap]);

            dispatch?.({
                type: "UPDATE_DATASET",
                values: {
                    dataSourceId,
                    dataset: {
                        mapTemplateDataset: {
                            styles: {
                                dataAggregation: {
                                    heatmap: {
                                        ...heatmap,
                                        id: heatmap.id.toString(),
                                    },
                                },
                            },
                        },
                    },
                },
            });

            if (mapTemplateDatasetId && !isSavedViewPage) {
                updateMapTemplateDataset({
                    id: mapTemplateDatasetId,
                    color,
                    styles: {
                        fill: { color, opacity },
                        dataAggregation: {
                            heatmap: {
                                ...heatmap,
                                id: heatmap.id.toString(),
                            },
                        },
                    },
                });
            }
        }
    };

    const updateCustomMarker = (input: {
        marker: string | null;
        map: React.MutableRefObject<mapboxgl.Map | null>;
        isLoaded: boolean;
        dataset: MapContextDataset;
    }) => {
        const { map, isLoaded, marker, dataset } = input;
        const dataSourceId = dataset.dataSource.id;
        const mapTemplateDatasetId = dataset.mapTemplateDataset?.id;

        dispatch?.({
            type: "UPDATE_DATASET",
            values: {
                dataSourceId,
                dataset: {
                    mapTemplateDataset: {
                        styles: {
                            shape: isNil(marker) ? ShapeStyle.oval : null,
                            customMarker: marker,
                        },
                    },
                },
            },
        });

        setDatasetLayersByCategory({
            category: marker
                ? DatasetStyleType.customMarker
                : DatasetStyleType.shape,
            prefix: dataSourceId,
            map,
            isLoaded,
            styles: {
                ...getDatasetStyles(currentDataset ?? dataset),
                shape: isNil(marker) ? ShapeStyle.oval : null,
                customMarker: marker,
            },
        });

        if (mapTemplateDatasetId && !isSavedViewPage) {
            updateMapTemplateDataset({
                id: mapTemplateDatasetId,
                styles: {
                    shape: isNil(marker) ? ShapeStyle.oval : null,
                    customMarker: marker,
                },
            });
        }
    };

    const updateHeatMapColor = (input: {
        value: ColorSwatchOption;
        map: React.MutableRefObject<mapboxgl.Map | null>;
        isLoaded: boolean;
        dataset: MapContextDataset;
    }) => {
        const { value, dataset, map, isLoaded } = input;

        const dataSourceId = dataset.dataSource.id;
        const mapTemplateDatasetId = dataset.mapTemplateDataset?.id;

        dispatch?.({
            type: "UPDATE_DATASET",
            values: {
                dataSourceId,
                dataset: {
                    mapTemplateDataset: {
                        styles: {
                            dataAggregation: {
                                heatmap: {
                                    ...value,
                                    id: value.id.toString(),
                                },
                            },
                        },
                    },
                },
            },
        });

        setDatasetLayersByCategory({
            category: DatasetStyleType.dataAggregation,
            prefix: dataSourceId,
            map,
            isLoaded,
            styles: {
                ...getDatasetStyles(dataset),
                dataAggregation: {
                    heatmap: {
                        ...value,
                        id: value.id.toString(),
                    },
                },
            },
        });

        if (mapTemplateDatasetId && !isSavedViewPage) {
            updateMapTemplateDataset({
                id: mapTemplateDatasetId,
                styles: {
                    dataAggregation: {
                        heatmap: {
                            ...value,
                            id: value.id.toString(),
                        },
                    },
                },
            });
        }
    };

    return {
        extraHeatmapOptions,
        setExtraHeatmapOptions,
        updateShape,
        updateColor,
        updateCustomMarker,
        updateHeatMapColor,
    };
};

export const reorderDatasetLayers = (
    order: {
        readonly type?: DatabaseType;
        readonly dataSourceId: string;
    }[],
    map: React.MutableRefObject<mapboxgl.Map | null>,
    isLoaded: boolean
) => {
    const current = map.current;

    order.map((layer) => {
        if (current && isLoaded) {
            const layers = getDatasetLayerIds({
                prefix: layer.dataSourceId,
                map: current,
                isLoaded,
            });

            layers.map((layerId) => {
                if (isLayerPresent({ layerId, map: current, isLoaded })) {
                    current.moveLayer(layerId);
                }
            });
        }
    });
};

export const computeWebGLRenderingTime = ({
    context,
    datasetId,
    map,
    dispatch,
}: {
    context: WebGLRenderingContext | WebGL2RenderingContext;
    datasetId: string;
    map: mapboxgl.Map;
    dispatch: Dispatch;
}) => {
    const start = performance.now();
    // Enable timer queries if available (WebGL2)
    context.getExtension("EXT_disjoint_timer_query_webgl2");

    map.once("render", () => {
        // Capture the WebGL render time for the current frame
        const end = performance.now();
        const layerRenderingTime = end - start;
        if (!isNil(datasetId)) {
            dispatch(
                commonActions.setRenderingTime({
                    datasetId,
                    layerRenderingTime,
                })
            );
        }
    });
};
