import {
    DatasetStyleType,
    ShapeStyle,
} from "@biggeo/bg-server-lib/datascape-ai";
import { ColorPicker, Stack, colorToHexString } from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import mapValues from "lodash/mapValues";
import startCase from "lodash/startCase";
import { useEffect, useState } from "react";
import { match } from "ts-pattern";
import { AccordionWithDivider } from "../../../common/components/AccordionWithDivider";
import { ColorSwatchOption } from "../../../common/components/ColorSwatchSelector";
import { HeatMapClusterColorMapper } from "../../../common/components/HeatMapClusterColorMapper";
import DatasetCustomMarker from "../../mapbox/views/DatasetCustomMarker";
import { MapFilterCriteriaStyle } from "../utils/utils";
import FilterCriteriaStylesShape from "./FilterCriteriaStylesShape";

interface IFilterCriteriaStyles {
    readonly styles: Partial<MapFilterCriteriaStyle>;
    readonly onChange: (styles: Partial<MapFilterCriteriaStyle>) => void;
    readonly styleFilteredData: (
        styles: Partial<MapFilterCriteriaStyle>
    ) => void;
    readonly isOpen?: boolean;
}

const FilterCriteriaStyles = ({
    styles,
    onChange,
    styleFilteredData,
    isOpen = false,
}: IFilterCriteriaStyles) => {
    const [open, setOpen] = useState<Record<DatasetStyleType, boolean>>(
        mapValues(DatasetStyleType, () => isOpen)
    );

    useEffect(() => {
        setOpen(mapValues(DatasetStyleType, () => isOpen));
    }, [isOpen]);
    return (
        <Stack>
            {pipe(
                Object.values(DatasetStyleType),
                A.map((category) => {
                    return (
                        <AccordionWithDivider
                            key={category}
                            label={startCase(category)}
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpen((prev) => ({
                                    ...prev,
                                    [category]: !prev[category],
                                }));
                            }}
                        >
                            {open[category] &&
                                match(category)
                                    .with(DatasetStyleType.shape, () => (
                                        <FilterCriteriaStylesShape
                                            currentShape={
                                                styles.shape || ShapeStyle.oval
                                            }
                                            currentCustomMarker={
                                                styles.customMarker || undefined
                                            }
                                            fill={styles.fill || undefined}
                                            stroke={styles.stroke || undefined}
                                            onChange={(shape) => {
                                                onChange({
                                                    ...styles,
                                                    shape,
                                                    customMarker: undefined,
                                                });
                                                styleFilteredData({
                                                    shape,
                                                });
                                            }}
                                        />
                                    ))
                                    .with(DatasetStyleType.fill, () => (
                                        <ColorPicker
                                            initialColor={
                                                styles.fill?.color || undefined
                                            }
                                            color={
                                                styles.fill?.color || undefined
                                            }
                                            onChange={(color) => {
                                                onChange({
                                                    ...styles,
                                                    [category]: {
                                                        color: colorToHexString(
                                                            color.hex
                                                        ),
                                                        opacity: color.rgb.a,
                                                    },
                                                });
                                                styleFilteredData({
                                                    [category]: {
                                                        color: colorToHexString(
                                                            color.hex
                                                        ),
                                                        opacity: color.rgb.a,
                                                    },
                                                });
                                            }}
                                        />
                                    ))
                                    .with(DatasetStyleType.stroke, () => (
                                        <ColorPicker
                                            initialColor={
                                                styles.stroke?.color ||
                                                undefined
                                            }
                                            color={
                                                styles.stroke?.color ||
                                                undefined
                                            }
                                            onChange={(color) => {
                                                onChange({
                                                    ...styles,
                                                    [category]: {
                                                        color: colorToHexString(
                                                            color.hex
                                                        ),
                                                        opacity: color.rgb.a,
                                                    },
                                                });
                                                styleFilteredData({
                                                    [category]: {
                                                        color: colorToHexString(
                                                            color.hex
                                                        ),
                                                        opacity: color.rgb.a,
                                                    },
                                                });
                                            }}
                                        />
                                    ))
                                    .with(DatasetStyleType.customMarker, () => (
                                        <DatasetCustomMarker
                                            value={
                                                styles.customMarker || undefined
                                            }
                                            color={
                                                styles.fill?.color || undefined
                                            }
                                            onChange={(v) => {
                                                onChange({
                                                    ...styles,
                                                    [category]: v,
                                                });
                                                styleFilteredData({
                                                    [category]: v,
                                                });
                                            }}
                                        />
                                    ))
                                    .with(
                                        DatasetStyleType.dataAggregation,
                                        () => (
                                            <HeatMapClusterColorMapper
                                                heatMapValue={
                                                    styles.dataAggregation
                                                        ?.heatmap
                                                }
                                                onClickHeatMapValue={(
                                                    value: ColorSwatchOption
                                                ) => {
                                                    onChange({
                                                        ...styles,
                                                        [category]: {
                                                            heatmap: value,
                                                        },
                                                    });
                                                    styleFilteredData({
                                                        [category]: {
                                                            heatmap: value,
                                                        },
                                                    });
                                                }}
                                            />
                                        )
                                    )
                                    .exhaustive()}
                        </AccordionWithDivider>
                    );
                })
            )}
        </Stack>
    );
};

export default FilterCriteriaStyles;
