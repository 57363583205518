import {
    type FilterObject,
    MapTemplateDatasetExtended,
    type SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    bgLongToBigInt,
    bgLongToString,
    bigIntToBgLong,
} from "@biggeo/bg-utils";
import { PartialBy, toNonReadonlyArray } from "@biggeo/bg-utils";
import { lineString, point, polygon } from "@turf/turf";
import { withDefault } from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import gt from "lodash/gt";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import type mapboxgl from "mapbox-gl";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useRenderingTimes } from "../../../common/redux/hooks";
import { commonActions } from "../../../common/redux/model";
import { usePreviewDatasets } from "../../../database-meta-data/redux/hooks";
import { closePolygon } from "../../../utils/utils";
import { updateDatasetsVisibility } from "../../filter-criteria/utils/utils";
import { handleAntiMeridian } from "../../utils/utils";
import { MapContextFilter, useMap } from "../context";
import {
    getDatasetSources,
    getDatasetStyles,
    handleDatasetLayers,
} from "../utils/data-layers-utils";
import { DEFAULT_SHAPE_COLOR } from "./style-hooks";

export type DataHookProps = {
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly multiFilters: FilterObject[];
    readonly recentResponse: SubscriptionResponse | undefined;
    readonly style?: mapboxgl.Style;
    readonly isLoaded: boolean;
    readonly filters: MapContextFilter[];
};

export const useData = ({
    map,
    multiFilters,
    recentResponse,
    style,
    isLoaded,
    filters,
}: DataHookProps) => {
    const dispatch = useDispatch();
    const renderingTimes = useRenderingTimes();

    const { datasets: mapTemplateDatasets } = useMap();

    const _preview = withDefault(
        { dataSources: [], total: 0, snowflakeData: [] },
        usePreviewDatasets()
    );

    const datasets: PartialBy<
        MapTemplateDatasetExtended,
        "mapTemplateDataset"
    >[] = [
        ...mapTemplateDatasets,
        ..._preview.dataSources.map((c) => ({ dataSource: c })),
    ];

    const hasVisibleFilters = !isEmpty(
        pipe(
            filters,
            A.filter(
                (f) => isEqual(f.visible, true) && !isEmpty(f.filterCriteria)
            )
        )
    );
    const hasSelectedFilters = !isEmpty(
        pipe(
            filters,
            A.filter((f) => isEqual(f.selected, true))
        )
    );

    const onStyleLoad = (map: mapboxgl.Map) => {
        map.addSource("polygons-from-ai", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
        });

        map.addLayer({
            type: "circle",
            source: "polygons-from-ai",
            paint: {
                "circle-radius": 3,
                "circle-color": DEFAULT_SHAPE_COLOR,
            },
            id: "polygons-from-ai-layer",
        });
    };

    const isRenderingTimePositive = (datasetId: string) =>
        pipe(
            renderingTimes,
            toNonReadonlyArray,
            A.some(
                (item) =>
                    isEqual(item.datasetId, datasetId) &&
                    gt(item.layerRenderingTime, 0)
            )
        );

    const setDatasetsFromResponse = (input: {
        response: SubscriptionResponse;
        map: mapboxgl.Map;
        isLoaded: boolean;
    }) => {
        const { response, map, isLoaded } = input;

        const dataSourceId = response.databaseId;
        const data = response.geometry || undefined;

        const isFiltered = multiFilters.find(
            (m) => m.databaseId === dataSourceId
        );

        const isNotPreviewed = pipe(
            filters,
            O.fromPredicate((x) => !isEmpty(x)),
            O.fold(
                () => true,
                (f) =>
                    pipe(
                        f,
                        A.findFirst((f) => isEqual(f.preview, true)),
                        O.fold(
                            () => true,
                            (previewedFilter) =>
                                pipe(
                                    previewedFilter.filterCriteria,
                                    A.findFirst(
                                        (i) => i.dataSourceId !== dataSourceId
                                    ),
                                    O.fold(
                                        () => true,
                                        () => false
                                    )
                                )
                        )
                    )
            )
        );

        const dataset = mapTemplateDatasets.find(
            (d) =>
                d.dataSource.id === dataSourceId &&
                isEqual(d.isVisible, true) &&
                isEqual(d.isSelected, true) &&
                isFiltered &&
                isNotPreviewed
        );

        const unselectedDatasets = pipe(
            mapTemplateDatasets,
            A.filter(
                (d) =>
                    isEqual(d.isVisible, false) &&
                    isEqual(d.isSelected, false) &&
                    !multiFilters.find((m) => m.databaseId === d.dataSource.id)
            )
        );

        if (data && !isEmpty(unselectedDatasets)) {
            pipe(
                unselectedDatasets,
                A.map((d) =>
                    handleDatasetLayers({
                        action: "remove",
                        map,
                        isLoaded,
                        prefix: d.dataSource.id,
                        dispatch,
                    })
                )
            );
        }

        if (data && dataset) {
            const sources = getDatasetSources({
                prefix: dataSourceId,
                map,
                isLoaded,
                levelSets: data.levelSets,
            });

            const aggregates: GeoJSON.Feature<
                GeoJSON.Geometry,
                GeoJSON.GeoJsonProperties
            >[] = pipe(
                data.aggregation || [],
                A.map((d) => {
                    const pointCoord = [
                        d.meanPoint.longitude,
                        d.meanPoint.latitude,
                    ];
                    return d.count
                        ? data.pointCount.low === 0
                            ? undefined
                            : point(pointCoord, {
                                  label: `Aggregate ${d.cellID}`,
                                  count: d.count,
                                  databaseId: response.databaseId,
                              })
                        : undefined;
                }),
                compact
            );

            const points: GeoJSON.Feature<
                GeoJSON.Geometry,
                GeoJSON.GeoJsonProperties
            >[] = pipe(
                data.points || [],
                A.map((p) => {
                    const pointCoord = p.point
                        ? [p.point.longitude, p.point.latitude]
                        : [0, 0];

                    return point(pointCoord, {
                        label: `Point ${p.id.uint64 ? bgLongToString(p.id.uint64) : p.id.string}`,
                        count: 1,
                        id: JSON.stringify(p.id),
                        databaseId: response.databaseId,
                    });
                })
            );

            const polygons: GeoJSON.Feature<
                GeoJSON.Geometry,
                GeoJSON.GeoJsonProperties
            >[] = pipe(
                data.nonPoints || [],
                A.map((p) => {
                    const polypoints = p?.polygon?.outer?.points || [];
                    const points = polypoints.map((p) => {
                        return [p.longitude, p.latitude];
                    });
                    if (points.length < 3) {
                        return undefined;
                    }

                    return polygon([closePolygon(points)], {
                        count: 1,
                        label: `Polygon ${p.id.uint64 ? bgLongToString(p.id.uint64) : p.id.string}`,
                        id: JSON.stringify(p.id),
                        databaseId: response.databaseId,
                        idCadence: p.id.uint64
                            ? bigIntToBgLong(bgLongToBigInt(p.id.uint64) % 20n)
                                  .low
                            : p.id.string,
                    });
                }),
                compact
            );

            const multiPolygons: GeoJSON.Feature<
                GeoJSON.Geometry,
                GeoJSON.GeoJsonProperties
            >[] = pipe(
                data.nonPoints || [],
                A.flatMap((geometry) =>
                    pipe(
                        geometry.multipolygon?.polygons || [],
                        A.map((p) => {
                            const polypoints = p?.outer?.points || [];
                            const points = polypoints.map((p) => {
                                return [p.longitude, p.latitude];
                            });

                            if (points.length < 3) {
                                return undefined;
                            }
                            return polygon([closePolygon(points)], {
                                count: 1,
                                label: `Multi-Polygon ${geometry.id}`,
                                id: JSON.stringify(geometry.id),
                                databaseId: response.databaseId,
                                idCadence: geometry.id.uint64
                                    ? bigIntToBgLong(
                                          bgLongToBigInt(geometry.id.uint64) %
                                              20n
                                      ).low
                                    : geometry.id.string,
                            });
                        })
                    )
                ),
                compact
            );

            const lines: GeoJSON.Feature<
                GeoJSON.Geometry,
                GeoJSON.GeoJsonProperties
            >[] = pipe(
                data.nonPoints || [],
                A.map((l) => {
                    const linePoints = l?.lineStrip?.points || [];

                    const points = handleAntiMeridian(linePoints);

                    return points.length < 3
                        ? undefined
                        : lineString(points, {
                              count: 1,
                              label: `Line ${l.id}`,
                              id: l.id,
                              databaseId: response.databaseId,
                              line: "true",
                          });
                }),
                compact
            );

            const levelSets = pipe(
                data.levelSets || [],
                A.map((ls) => {
                    return ls.rings.map((r) => {
                        const ringPoints = r?.points || [];

                        const points = handleAntiMeridian(ringPoints);

                        return polygon([closePolygon(points)], {
                            count: 1,
                            label: "Levelset triangle",
                            id: 12,
                            databaseId: response.databaseId,
                            idCadence: 12 % 20,
                        });
                    });
                })
            );

            handleDatasetLayers({
                action: "add",
                map,
                isLoaded,
                prefix: dataSourceId,
                levelSets: data.levelSets,
                sources,
                hierarchy: pipe(
                    mapTemplateDatasets,
                    A.filter(
                        (d) =>
                            isEqual(d.isVisible, true) &&
                            isEqual(d.isSelected, true)
                    ),
                    A.map((d) => d.dataSource.id)
                ),
                data: {
                    isPreview: Boolean(
                        _preview.dataSources.find((d) => d.id === dataSourceId)
                    ),
                    isMipmapped: dataset.dataSource.isMipmapped || undefined,
                    type: dataset.dataSource.type,
                    showLevelSets: dataset.configuration.showLevelSets,
                    showPoints: dataset.configuration.showPoints,
                },
                sourcesData: {
                    aggregate: pipe(aggregates, A.concat(points)),
                    points,
                    polygons: pipe(
                        polygons,
                        A.concat(multiPolygons),
                        A.concat(lines)
                    ),
                    levelSets,
                },
                styles: getDatasetStyles(dataset),
                dispatch,
            });
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (recentResponse && map.current) {
            if (recentResponse.geometry?._tag !== "error") {
                setDatasetsFromResponse({
                    response: recentResponse,
                    map: map.current,
                    isLoaded,
                });

                if (hasVisibleFilters || hasSelectedFilters) {
                    const selectedDatasets = pipe(
                        mapTemplateDatasets,
                        A.filter(
                            (d) =>
                                isEqual(d.isSelected, true) &&
                                isEqual(d.isVisible, true)
                        )
                    );

                    updateDatasetsVisibility({
                        visibility: "none",
                        map,
                        isLoaded,
                        datasets: selectedDatasets,
                    });
                }
                const datasetId = recentResponse.databaseId;
                if (!isRenderingTimePositive(datasetId)) {
                    const start = performance.now();
                    map.current.once("render", () => {
                        const end = performance.now();
                        dispatch(
                            commonActions.setRenderingTime({
                                datasetId,
                                layerRenderingTime: end - start,
                                action: "add",
                            })
                        );
                    });
                }
            }
        }
    }, [
        recentResponse?.uniqueId,
        style,
        hasVisibleFilters,
        hasSelectedFilters,
        datasets,
        mapTemplateDatasets,
        multiFilters,
        filters,
    ]);

    return {
        onStyleLoad,
    };
};
