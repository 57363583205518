import { InputDatasetStatisticsFilters } from "@biggeo/bg-server-lib/datascape-ai";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import MarketplaceDatasetPreviewByDataSourceId from "./MarketplaceDatasetPreviewByDataSourceId";
import MarketplaceDatasetPreviewById from "./MarketplaceDatasetPreviewById";

export interface IMarketplaceDatasetPreviewContainer {
    readonly previewDataset: E.Either<
        {
            marketplaceDatasetId: string;
        },
        {
            dataSourceId: string;
        }
    >;
    readonly onClickPreviewDataset: (marketplaceDatasetId: string) => void;
    readonly onClickPreviewInfo: (
        input?: E.Either<
            {
                marketplaceDatasetId: string;
            },
            {
                dataSourceId: string;
            }
        >
    ) => void;
    readonly isPreviewBtnDisabled: boolean;
    readonly filters: InputDatasetStatisticsFilters;
}

const MarketplaceDatasetPreviewContainer = ({
    previewDataset,
    ...props
}: IMarketplaceDatasetPreviewContainer) => {
    return pipe(
        previewDataset,
        E.fold(
            ({ marketplaceDatasetId }) => {
                return (
                    <MarketplaceDatasetPreviewById
                        {...props}
                        marketplaceDatasetId={marketplaceDatasetId}
                    />
                );
            },
            ({ dataSourceId }) => {
                return (
                    <MarketplaceDatasetPreviewByDataSourceId
                        {...props}
                        dataSourceId={dataSourceId}
                    />
                );
            }
        )
    );
};

export default MarketplaceDatasetPreviewContainer;
